import React, { useContext, useEffect, useState, createContext } from "react";
import * as Constants from "./Constants";
import axios from "axios";
import { useSiteContext } from "./Context";
import { CountPDFPages } from "./Utilities";
import { BlockBlobClient } from "@azure/storage-blob";
import Cookies from "universal-cookie";

const CurrentContext = createContext();

function IQContext({ children }) {
  const [pastMessages, SetPastMessages] = useState([]);
  const [isThinking, SetIsThinking] = useState(false);
  const [knowledgeBaseOptions, SetKnowledgeBaseOptions] = useState([]);
  const [selectedKnowledgeBases, SetSelectedKnowledgeBases] = useState([]);
  const [
    currentItemSelectedKnowledgeBases,
    SetCurrentItemSelectedKnowledgeBases,
  ] = useState([]);
  const [previousKnowledgeBases, SetPreviousKnowledgeBases] = useState([]);
  const { loggedInUser, AddAlert } = useSiteContext();
  const [question, SetQuestion] = useState("");
  const [chatModelOption, SetChatModelOption] = useState("3.5");
  const [isPDFViewerOpen, SetIsPDFViewerOpen] = useState(false);
  const [PDFViewerFile, SetPDFViewerFile] = useState(null);
  const [PDFPageNumber, SetPDFPageNumber] = useState(1);
  const [expandedSideBar, SetExpandedSideBar] = useState("");
  const [knowledgeBaseSearchText, SetKnowledgeBaseSearchText] = useState("");
  const [uploading, SetUploading] = useState(false);
  const [filePreview, SetFilePreview] = useState(null);
  const [isClicked, SetIsClicked] = useState(false);
  const [isDraggingOver, SetIsDraggingOver] = useState(false);
  const [files, SetFiles] = useState([]);
  const [currentKnowledgeBase, SetCurrentKnowledgeBase] = useState(null);
  const [idToReplace, SetIdToReplace] = useState(null);
  const [resetKey, SetResetKey] = useState(0);
  const [documents, SetDocuments] = useState([]);
  const [knowledgeBases, SetKnowledgeBases] = useState([]);
  const [knowledgeBaseBeingDeleted, SetKnowledgeBaseBeingDeleted] =
    useState(null);
  const [documentBeingDeleted, SetDocumentBeingDeleted] = useState(null);
  const [showKnowledgeBases, SetShowKnowledgeBases] = useState(false);
  const [showKnowledgeBaseModal, SetShowKnowledgeBaseModal] = useState(false);
  const [kbToEdit, SetKbToEdit] = useState(null);
  const [PDFFileToView, SetPDFFileToView] = useState(null);
  const [PDFViewerModalOpen, SetPDFViewerModalOpen] = useState(false);
  const [currentItem, SetCurrentItem] = useState(null);
  const [hideSidebar, SetHideSidebar] = useState(false);
  const baseUrl = Constants.API_URL + "Ask" + Constants.API_KEY;
  const standardErrorMessage = {
    role: "assistant",
    content:
      "I apologize. We are experiencing some errors. Please try again later.",
    error: true,
  };
  const cookies = new Cookies();

  function GetDocuments() {
    axios({
      method: "get",
      url: Constants.API_URL + "Get" + Constants.API_KEY,
      params: {
        customerId: loggedInUser.customer.id,
        customer: loggedInUser.customer.id,
      },
      headers: {
        GetType: "IQDoc",
      },
    })
      .then((response) => {
        let documents = [...response.data];
        if (!loggedInUser.isHammr) {
          documents = documents.filter(
            (d) =>
              !(
                d.knowledgeBases.length === 1 &&
                d.knowledgeBases[0].general === true
              )
          );
        }
        let sortedDocuments = documents.sort((a, b) => {
          return a.fileName.localeCompare(b.fileName);
        });
        SetDocuments([...sortedDocuments]);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function GetKnowledgeBases() {
    axios({
      method: "get",
      url: Constants.API_URL + "Get" + Constants.API_KEY,
      params: {
        customerId: loggedInUser.customer.id,
        customer: loggedInUser.customer.id,
      },
      headers: {
        GetType: "IQKnowledgeBase",
      },
    })
      .then((response) => {
        let knowledgeBases = [...response.data];
        SetKnowledgeBaseOptions(knowledgeBases);
        if (!loggedInUser.isHammr) {
          knowledgeBases = knowledgeBases.filter((kb) => !kb.general);
        }
        let sortedKnowledgeBases = knowledgeBases.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
        SetKnowledgeBases([...sortedKnowledgeBases]);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function GetKnowledgeBaseOptions() {
    axios({
      method: "get",
      url: Constants.API_URL + "Get" + Constants.API_KEY,
      params: {
        customerId: loggedInUser.customer.id,
        customer: loggedInUser.customer.id,
      },
      headers: {
        GetType: "IQKnowledgeBase",
      },
    })
      .then((response) => {
        let options = response.data.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        SetKnowledgeBaseOptions(options);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function ChangeFilePreview(file, changeClicked = false) {
    if (!isClicked || changeClicked) {
      SetFilePreview(file);
    }
  }

  function ClearPastMessages() {
    if (
      !window.confirm(
        "Are you sure you want to clear all the messages in the chat?"
      )
    ) {
      return;
    }
    SetPastMessages([]);
  }

  function SummarizeKnowledgeBases(knowledgeBasesNames, knowledgeBaseIds) {
    let newQuestion = `What is in the following knowledge bases: `;
    if (knowledgeBasesNames == null) {
      if (selectedKnowledgeBases.length === 0) {
        alert("Please select at least one knowledge base.");
        return;
      }
      knowledgeBasesNames = selectedKnowledgeBases.map((kb) => kb.name);
      let knowledgeBasesString = knowledgeBasesNames.join(", ");
      newQuestion += `${knowledgeBasesString}?`;
      SetQuestion(newQuestion);
      AskQuestion(newQuestion, true);
      return;
    }
    if (!(knowledgeBasesNames instanceof Array)) {
      knowledgeBasesNames = [knowledgeBasesNames];
    }
    if (knowledgeBasesNames == null) {
      return;
    }
    let knowledgeBasesString = knowledgeBasesNames.join(", ");
    newQuestion += `${knowledgeBasesString}?`;
    SetQuestion(newQuestion);
    AskQuestion(newQuestion, true, knowledgeBaseIds);
  }

  function HandleKnowledgeBaseSelection(knowledgeBase, current = false) {
    let pullFromList = current
      ? currentItemSelectedKnowledgeBases
      : selectedKnowledgeBases;
    let newSelectedKnowledgeBases = null;
    if (pullFromList.some((kb) => kb.id === knowledgeBase.id)) {
      newSelectedKnowledgeBases = pullFromList.filter(
        (kb) => kb.id !== knowledgeBase.id
      );
    } else {
      newSelectedKnowledgeBases = [...pullFromList, knowledgeBase];
    }
    if (current) {
      SetCurrentItemSelectedKnowledgeBases(newSelectedKnowledgeBases);
      return;
    }
    SetSelectedKnowledgeBases(newSelectedKnowledgeBases);
  }

  function ClearInput() {
    document.getElementById("questionBox").value = "";
    SetQuestion("");
  }

  function ShowExtendedSideBar(value = "", item = null, dontClose = false) {
    HideExpandedSidebar(false);
    if (value.includes("Edit") || value.includes("Delete")) {
      if (item?.id !== currentItem?.id) {
        SetCurrentItem(item);
        SetCurrentItemSelectedKnowledgeBases(item?.knowledgeBases ?? []);
        SetFiles([]);
        SetExpandedSideBar(value);
        return;
      }
      SetExpandedSideBar(value);
      return;
    }
    if (expandedSideBar === value && !dontClose) {
      SetExpandedSideBar("");
      return;
    }
    ResetFileChange();
    SetExpandedSideBar(value);
    SetCurrentItem(null);
  }

  function AskQuestion(
    newQuestion,
    isSummary = false,
    knowledgeBaseIds = null
  ) {
    let clearMessages = false;
    let askingKnowledgeBases =
      knowledgeBaseIds ?? selectedKnowledgeBases.map((kb) => kb.id);
    if (
      previousKnowledgeBases.length > 0 &&
      pastMessages.length > 0 &&
      !previousKnowledgeBases.every((item) =>
        askingKnowledgeBases.includes(item)
      )
    ) {
      let alertText =
        "You have removed one or more of the previous knowledge bases from your selection. This will clear all past messages. Do you want to proceed?";
      if (knowledgeBaseIds != null) {
        alertText =
          "Asking for a summary will clear all past messages. Do you want to proceed?";
      }
      if (!window.confirm(alertText)) {
        SetSelectedKnowledgeBases(previousKnowledgeBases);
        return;
      }
      clearMessages = true;
    }

    SetPreviousKnowledgeBases(askingKnowledgeBases);

    let url = baseUrl;
    url +=
      `&customerId=${loggedInUser.customer.id}` +
      `&userId=${loggedInUser.id}` +
      `&knowledgeBases=${askingKnowledgeBases.join(",")}` +
      `&isSummary=${isSummary}` +
      `&chatModelOption=${chatModelOption}`;

    let newUserQuestion = { role: "user", content: newQuestion };

    if (clearMessages) {
      SetPastMessages([newUserQuestion]);
    } else {
      SetPastMessages([...pastMessages, newUserQuestion]);
    }
    let cleanPastMessages;

    if (clearMessages) {
      cleanPastMessages = [];
    } else {
      cleanPastMessages = pastMessages.filter(
        (pastMessages) => !pastMessages.error
      );
    }

    let data = { messages: [...cleanPastMessages, newUserQuestion] };

    SetIsThinking(true);
    ClearInput();

    axios({
      method: "post",
      url: url,
      data: data,
      headers: { "content-type": "application/json" },
    })
      .then((response) => {
        SetIsThinking(false);
        if (clearMessages) {
          HandleResponse(response, []);
          return;
        }
        HandleResponse(response, pastMessages);
      })
      .catch((error) => {
        SetIsThinking(false);
        SetPastMessages([
          ...pastMessages,
          { role: "user", content: newQuestion },
          standardErrorMessage,
        ]);
      });
  }

  function HandleResponse(response, messageHistory) {
    let newMessages = response.data.messages;

    newMessages = newMessages.filter((message) => {
      return (
        (message.role === "assistant" || message.role === "user") &&
        message.content !== null
      );
    });

    if (messageHistory.length > 0) {
      newMessages = [...messageHistory, ...newMessages.slice(-2)];
    }

    SetPastMessages([...newMessages]);
  }

  function ToggleChatModel() {
    if (chatModelOption === "3.5") {
      SetChatModelOption("4");
      cookies.set("chatModelOption", "4", {
        path: "/",
        expires: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 365),
      });
    } else {
      SetChatModelOption("3.5");
      cookies.set("chatModelOption", "3.5", {
        path: "/",
        expires: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 365),
      });
    }
  }

  function GetDefaultChatModelOption() {
    let cookieValue = cookies.get("chatModelOption");
    if (cookieValue == null) {
      cookieValue = "3.5";
    }
    SetChatModelOption(cookieValue);
  }

  function ResetDocumentManagement() {
    ShowExtendedSideBar("DocumentManagement");
    SetCurrentItem(null);
    GetKnowledgeBaseOptions();
    GetKnowledgeBases();
    GetDocuments();
    ResetFileChange();
  }

  function AddKnowledgeBase({
    name = "",
    isGeneral = false,
    description = "",
    customerId = "",
  }) {
    let formData = new FormData();
    formData.append("Name", name);
    formData.append("customerId", customerId ?? loggedInUser.customer.id);
    formData.append("isGeneral", isGeneral);
    formData.append("description", description);
    axios({
      method: "post",
      url: Constants.API_URL + "AddKnowledgeBase" + Constants.API_KEY,
      data: formData,
    })
      .then((response) => {
        AddAlert("notification", "Knowledge base added successfully.");
        ResetDocumentManagement();
      })
      .catch((error) => {
        console.log(error);
        AddAlert("error", "Error adding knowledge base.");
      });
  }

  function EditKnowledgeBase(
    knowledgeBaseId,
    name,
    description = "",
    newCustomerId = ""
  ) {
    console.log(knowledgeBaseId, name, description, newCustomerId);
    let formData = new FormData();
    formData.append("knowledgeBaseId", knowledgeBaseId);
    formData.append("name", name);
    formData.append("description", description);
    formData.append("customerId", loggedInUser.customer.id);
    formData.append("newCustomerId", newCustomerId);

    axios({
      method: "patch",
      url: Constants.API_URL + "EditKnowledgeBase" + Constants.API_KEY,
      data: formData,
    })
      .then((response) => {
        AddAlert("notification", "Knowledge base edited successfully.");
        ResetDocumentManagement();
      })
      .catch((error) => {
        console.log(error);
        AddAlert("error", "Error editing knowledge base.");
      });
  }

  function ChangeFiles(newFiles) {
    newFiles = Array.from(newFiles);
    let filteredFiles = newFiles.filter(
      (file) => file.type === "application/pdf"
    );
    if (filteredFiles.length < newFiles.length) {
      AddAlert("error", "Files must be PDFs.");
      SetFilePreview(null);
      SetResetKey((prevKey) => prevKey + 1);
    }
    filteredFiles = filteredFiles.filter((file) =>
      files.every((f) => f.name !== file.name)
    );

    if (
      (filteredFiles.length > 1 || files.length > 0) &&
      expandedSideBar.includes("Edit")
    ) {
      AddAlert("error", "Only taking first file for replacement.");
      SetFiles([filteredFiles[0]]);
      SetFilePreview(filteredFiles[0]);
      return;
    }

    SetFiles([...files, ...newFiles]);
    SetFilePreview(newFiles[0]);
  }

  function HandleChooseFile(e) {
    if (e.target.files == null || e.target.files.length === 0) {
      return;
    }

    SetIdToReplace(null);

    ChangeFiles(e.target.files);
  }

  function HandleRemoveChosenFile(file) {
    SetFiles((prevFiles) => {
      return prevFiles.filter((f) => f.name !== file.name);
    });
    if (filePreview === file.uri) {
      SetFilePreview(null);
    }
  }

  async function HandleReplaceFile() {
    if (files.length === 0) {
      return;
    }
    let newFile = files[0];
    if (newFile.type !== "application/pdf") {
      AddAlert("error", "File must be a PDF.");
      SetFilePreview(null);
      SetResetKey((prevKey) => prevKey + 1);
      return;
    }
    if (
      !window.confirm(
        "Are you sure you want to replace " +
          currentItem.fileName +
          " with " +
          newFile.name +
          " in the knowledge base? This cannot be undone."
      )
    ) {
      ResetFileChange();
      return;
    }
    let isFileLarge = newFile.size > 100000000;
    if (
      isFileLarge &&
      !window.confirm(
        "Uploading files greater than 100MB will take significant time and bandwith. Continue?"
      )
    ) {
      AddAlert("error", "File upload cancelled.");
      ResetFileChange();
      return;
    }
    let result = await UploadFiles(newFile, currentItem, isFileLarge);

    if (result === "error") {
      AddAlert("error", "Error replacing file: " + currentItem.fileName);
      ResetFileChange();
      return;
    }

    AddAlert(
      "notification",
      `${currentItem.fileName} replaced successfully. New file: ${newFile.name}`
    );
    ResetFileChange();
  }

  function HandleDragOver(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  function HandleDragEnter(e) {
    e.preventDefault();
    e.stopPropagation();

    if (uploading) {
      return;
    }

    SetIsDraggingOver(true);
  }

  function HandleDragLeave(e) {
    e.preventDefault();
    e.stopPropagation();
    if (!e.currentTarget.contains(e.relatedTarget)) {
      SetIsDraggingOver(false);
    }
  }

  function HandleDrop(e) {
    e.preventDefault();
    e.stopPropagation();
    SetIsDraggingOver(false);

    if (!e.dataTransfer.files[0] || uploading) {
      return;
    }

    let files = e.dataTransfer.files;
    ChangeFiles(files);
  }

  function HandleChangeDropdown(id) {
    SetCurrentKnowledgeBase(id);
  }

  async function HandleUploadButton() {
    if (files.some((file) => file.size > 100000000)) {
      if (
        !window.confirm(
          "Uploading files greater than 100MB will take significant time and bandwith. Continue?"
        )
      ) {
        AddAlert("error", "File upload cancelled.");
        return;
      }
    }

    if (files.length === 1) {
      let isFileLarge = files[0].size > 100000000;
      let result = await UploadFiles(files[0], null, isFileLarge);
      if (result === "error") {
        AddAlert("error", "Error uploading file: " + files[0].name);
      } else {
        AddAlert("notification", files[0].name + " uploaded successfully.");
      }
      ResetFileChange();
      return;
    }

    let totalFiles = files.length;
    let filesUploaded = 0;
    let filesErrored = [];

    for (let i = 0; i < totalFiles; i++) {
      let isFileLarge = files[i].size > 100000000;
      let result = await UploadFiles(files[i], null, isFileLarge);

      if (result === "error") {
        filesErrored.push(files[i].name);
      } else {
        filesUploaded++;
      }
      SetFiles((prevFiles) => {
        return prevFiles.filter((file) => file.name !== files[i].name);
      });
    }
    if (filesErrored.length > 0) {
      AddAlert(
        "error",
        `Error uploading ${filesErrored.length} files: ${filesErrored.join(
          ", "
        )}`
      );
    }
    if (filesUploaded > 0) {
      AddAlert(
        "notification",
        `${filesUploaded} file(s) uploaded successfully. You will receive an email when they are ready to be used.`
      );
    }
    GetDocuments();
    ResetFileChange();
  }

  function ChangeIsClicked(e, file) {
    if (!isClicked || file.uri === filePreview) {
      SetIsClicked(!isClicked);
    } else if (isClicked && file.uri !== filePreview) {
      ChangeFilePreview(file.uri, true);
    }
  }

  function IsClicked(file) {
    if (isClicked) {
      if (file.uri === filePreview) {
        return true;
      }
    }
    return false;
  }

  function OpenInPDFViewer(file) {
    SetPDFFileToView(file);
    SetPDFViewerModalOpen(file != null);
  }

  async function DeleteFile(file = null, skipConfirm = false) {
    if (file == null) {
      file = currentItem;
    }
    if (
      !skipConfirm &&
      !window.confirm("Are you sure you want to delete " + file.fileName + "?")
    ) {
      return;
    }
    SetDocumentBeingDeleted(file.id);
    let formData = new FormData();
    formData.append("userId", loggedInUser.id);
    formData.append("id", file.id);
    let error = "";
    await axios({
      method: "post",
      url: Constants.API_URL + "DeleteDocument" + Constants.API_KEY,
      headers: {
        userid: loggedInUser.id,
        customerid: loggedInUser.customer.id,
      },
      data: formData,
    }).catch((e) => {
      AddAlert("error", "Error deleting " + file.fileName);
      SetDocumentBeingDeleted(null);
      error = e;
      console.log(e);
    });
    if (error === "") {
      AddAlert("notification", file.fileName + " deleted successfully.");
      ResetDocumentManagement();
      GetDocuments();
      SetDocumentBeingDeleted(null);
      SetResetKey((prevKey) => prevKey + 1);
    }
  }

  async function ChangeDocumentKnowledgeBases(
    document = null,
    removeKnowledgeBaseId = null,
    skipConfirm = false
  ) {
    if (document == null) {
      document = currentItem;
    }
    let newKnowledgeBases = document.knowledgeBases;
    let message = "updated";
    let errorMessage = "Could not update knowledge bases.";
    if (removeKnowledgeBaseId == null) {
      newKnowledgeBases = currentItemSelectedKnowledgeBases;
    } else {
      if (newKnowledgeBases.length === 1 && !skipConfirm) {
        if (
          window.confirm(
            "Document must be in at least one knowledge base. Proceeding will delete the document. This operation cannot be undone. Continue?"
          )
        ) {
          await DeleteFile(document);
        }
        return;
      }
      message = "removed from";
      newKnowledgeBases = newKnowledgeBases.filter(
        (kb) => kb.id !== removeKnowledgeBaseId
      );
    }
    let knowledgeBasesString = newKnowledgeBases.map((kb) => kb.id).join(",");

    let formData = new FormData();
    formData.append("userId", loggedInUser.id);
    formData.append("customerId", loggedInUser.customer.id);
    formData.append("docId", document.id);
    formData.append("knowledgeBases", knowledgeBasesString);

    let error = "";

    await axios({
      method: "post",
      url: Constants.API_URL + "ChangeKnowledgeBases" + Constants.API_KEY,
      data: formData,
    }).catch((e) => {
      AddAlert("error", errorMessage);
      error = e;
      console.log(error);
    });
    if (error === "") {
      AddAlert(
        "notification",
        `${document.fileName} ${message} knowledge bases successfully.`
      );
      ResetDocumentManagement();
    }
  }

  async function DeleteKnowledgeBase() {
    if (
      !window.confirm(
        "Are you sure you want to delete " +
          currentItem.name +
          "? Documents that are not attached to any other knowledge base will also be deleted. This operation cannot be undone. Continue?"
      )
    ) {
      return;
    }
    SetKnowledgeBaseBeingDeleted(currentItem.id);

    await DeleteOrRemoveKnowledgeBaseDocuments(currentItem.id);

    let formData = new FormData();
    formData.append("knowledgeBaseId", currentItem.id);
    formData.append("userId", loggedInUser.id);
    let error = "";

    await axios({
      method: "post",
      url: Constants.API_URL + "DeleteKnowledgeBase" + Constants.API_KEY,
      data: formData,
    }).catch((e) => {
      AddAlert("error", "Error deleting " + currentItem.name);
      SetKnowledgeBaseBeingDeleted(null);
      error = e;
      console.log(error);
    });
    if (error === "") {
      AddAlert("notification", currentItem.name + " deleted successfully.");
      SetKnowledgeBaseBeingDeleted(null);
      ResetDocumentManagement();
    }
  }

  async function DeleteOrRemoveKnowledgeBaseDocuments(knowledgeBaseId) {
    let promises = documents.map((doc) => {
      if (
        doc.knowledgeBases.some((kb) => kb.id === knowledgeBaseId) &&
        doc.knowledgeBases.length === 1
      ) {
        return DeleteFile(doc, true);
      } else if (doc.knowledgeBases.some((kb) => kb.id === knowledgeBaseId)) {
        return ChangeDocumentKnowledgeBases(doc, knowledgeBaseId, true);
      }
      return Promise.resolve();
    });
    await Promise.all(promises);
  }

  async function UploadFiles(
    fileToUpload,
    oldFile = null,
    isFileLarge = false
  ) {
    let formData = new FormData();
    let isGeneral = selectedKnowledgeBases.some((kb) => kb.general === true);
    let currentKnowledgeBases = selectedKnowledgeBases
      .filter((kb) => kb.general === isGeneral)
      .map((kb) => kb.id);
    let currentKnowledgeBasesString = currentKnowledgeBases.join(",");
    if (isFileLarge) {
      SetUploading(true);
      let { sasUri, sasToken } = await GetSASUrl();
      let blobUrl = `${sasUri}/${loggedInUser.customer.id}/${fileToUpload.name}`;

      const response = await UploadFileToBlobStorage(
        fileToUpload,
        blobUrl + sasToken
      );
      if (response === null) {
        return "error";
      }
      formData.append("FileUrl", blobUrl);
      formData.append("FileName", fileToUpload.name);
    } else {
      formData.append("File", fileToUpload);
    }
    let pageCount = await CountPDFPages(fileToUpload);

    formData.append("PageCount", pageCount);
    formData.append("Permissions", [1, 2].join(","));

    if (oldFile !== null) {
      formData.append("docId", oldFile.id);
      currentKnowledgeBasesString = oldFile.knowledgeBases
        .map((kb) => kb.id)
        .join(",");
      isGeneral = oldFile.knowledgeBases.some((kb) => kb.general === true);
    }

    formData.append("KnowledgeBases", currentKnowledgeBasesString);

    formData.append("type", isGeneral ? "g" : "customer");

    SetUploading(true);
    return axios({
      method: "post",
      url: Constants.API_URL + "FileUploader" + Constants.API_KEY,
      headers: {
        userid: loggedInUser.id,
        customerid: loggedInUser.customer.id,
      },
      data: formData,
    })
      .then((response) => {
        ResetDocumentManagement();
        return "success";
      })
      .catch((error) => {
        console.log(error);
        return "error";
      });
  }

  function ToggleShowKnowledgeBases() {
    if (knowledgeBases === null) {
      return;
    }
    if (
      !showKnowledgeBases &&
      currentKnowledgeBase === null &&
      knowledgeBases.length > 0
    ) {
      SetCurrentKnowledgeBase(knowledgeBases[0].id);
    }
    SetShowKnowledgeBases(!showKnowledgeBases);
  }

  function ToggleShowAddEditKnowledgeBaseModal() {
    if (showKnowledgeBaseModal && kbToEdit !== null) {
      SetKbToEdit(null);
    }
    SetShowKnowledgeBaseModal(!showKnowledgeBaseModal);
  }

  function ResetFileChange() {
    SetFiles([]);
    SetFilePreview(null);
    SetUploading(false);
    SetIdToReplace(null);
    SetResetKey((prevKey) => prevKey + 1);
    if (!showKnowledgeBases) {
      SetCurrentKnowledgeBase(null);
    }
  }

  async function GetSASUrl() {
    return await axios({
      method: "get",
      url: Constants.API_URL + "GetSASToken" + Constants.API_KEY,
      headers: {
        userid: loggedInUser.id,
        customerid: loggedInUser.customer.id,
      },
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        return null;
      });
  }

  async function UploadFileToBlobStorage(fileToUpload, sasUrl) {
    let blockBlobClient = new BlockBlobClient(sasUrl);
    let uploadResponse = await blockBlobClient
      .uploadData(fileToUpload, {
        concurrency: 20,
        blockSize: 4 * 1024 * 1024,
      })
      .catch((error) => {
        console.log(error);
        return null;
      });
    return uploadResponse;
  }

  function ChangeCurrentItem(item) {
    SetCurrentItem(item);
  }

  function HideExpandedSidebar(value = true) {
    if (!expandedSideBar.includes("Management")) {
      SetExpandedSideBar("");
    }
    SetHideSidebar(value);
  }

  useEffect(() => {
    GetKnowledgeBaseOptions();
    GetDefaultChatModelOption();
    // eslint-disable-next-line
  }, []);

  return (
    <CurrentContext.Provider
      value={{
        pastMessages,
        isThinking,
        knowledgeBaseOptions,
        selectedKnowledgeBases,
        previousKnowledgeBases,
        question,
        chatModelOption,
        isPDFViewerOpen,
        PDFViewerFile,
        PDFPageNumber,
        expandedSideBar,
        knowledgeBaseSearchText,
        uploading,
        filePreview,
        isClicked,
        isDraggingOver,
        files,
        currentKnowledgeBase,
        idToReplace,
        resetKey,
        documents,
        knowledgeBases,
        knowledgeBaseBeingDeleted,
        documentBeingDeleted,
        showKnowledgeBases,
        showKnowledgeBaseModal,
        kbToEdit,
        PDFFileToView,
        PDFViewerModalOpen,
        currentItem,
        currentItemSelectedKnowledgeBases,
        hideSidebar,
        GetDocuments,
        GetKnowledgeBases,
        AddKnowledgeBase,
        EditKnowledgeBase,
        GetKnowledgeBaseOptions,
        ClearPastMessages,
        SummarizeKnowledgeBases,
        HandleKnowledgeBaseSelection,
        ClearInput,
        ShowExtendedSideBar,
        AskQuestion,
        ToggleChatModel,
        GetDefaultChatModelOption,
        SetIsPDFViewerOpen,
        SetPDFViewerFile,
        SetPDFPageNumber,
        SetExpandedSideBar,
        SetIsThinking,
        SetSelectedKnowledgeBases,
        SetPreviousKnowledgeBases,
        SetQuestion,
        SetKnowledgeBaseSearchText,
        HandleChooseFile,
        HandleRemoveChosenFile,
        HandleReplaceFile,
        HandleDragOver,
        HandleDragEnter,
        HandleDragLeave,
        HandleDrop,
        HandleChangeDropdown,
        HandleUploadButton,
        ChangeIsClicked,
        IsClicked,
        ChangeFilePreview,
        OpenInPDFViewer,
        DeleteFile,
        ChangeDocumentKnowledgeBases,
        DeleteKnowledgeBase,
        DeleteOrRemoveKnowledgeBaseDocuments,
        ToggleShowKnowledgeBases,
        ToggleShowAddEditKnowledgeBaseModal,
        ResetFileChange,
        ChangeCurrentItem,
        HideExpandedSidebar,
      }}
    >
      {children}
    </CurrentContext.Provider>
  );
}

export function useIQContext() {
  const context = useContext(CurrentContext);
  if (!context) {
    throw new Error("useIQContext must be used within a Context");
  }
  return context;
}

export default IQContext;
