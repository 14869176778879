import LoginScreen from "./Components/LoginScreen";
import ReactTooltip from "react-tooltip";
import { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Context from "./Components/Context";

function App() {
  useEffect(() => {
    let tooltipRefreshInterval = setInterval(() => {
      ReactTooltip.rebuild();
    }, 2000);

    return () => {
      clearInterval(tooltipRefreshInterval);
    };
  }, []);

  return (
    <div className="App">
      <Context>
        <Router>
          <LoginScreen />
        </Router>
        <ReactTooltip />
      </Context>
    </div>
  );
}

export default App;
