import React from "react";

function Day(props) {
  function GetDayClass() {
    let dayClass = "dayContainer range" + props.rangeNumber;
    if (props.trueDate?.getDay() === 0 || props.trueDate?.getDay() === 6) {
      dayClass += " dayWeekend";
    }
    if (props.isHeader) {
      if (props.isToday) {
        return dayClass + " dayTodayHeader";
      }
      return dayClass + " dayHeader";
    }
    if (props.isToday) {
      return dayClass + " dayToday";
    }
    return dayClass;
  }

  return (
    <div
      className={GetDayClass()}
      data-date={props.trueDate?.toLocaleDateString()}
    >
      {props.isHeader ? <div>{props.date}</div> : null}
    </div>
  );
}

export default Day;
