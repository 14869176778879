import React, { useEffect, useState } from "react";
import ForagerButton from "./ForagerButton";
import AutosizedTextArea from "./AutosizedTextArea";

function ForagerInputModal(props) {
  const defaultValues = {
    text: "",
    textarea: "",
    number: 0,
    boolean: false,
  };

  const [value, SetValue] = useState(defaultValues[props.type]);
  // const inputRef = useRef(null);
  const [triggeredSubmit, SetTriggeredSubmit] = useState(false);

  function GenerateDefaultValue() {
    if (props.value != null) {
      SetValue(props.value);
      return;
    }
    SetValue(defaultValues[props.type]);
  }

  function onChange(event) {
    switch (props.type) {
      case "text":
      case "textarea":
        SetValue(event.target.value);
        if (props.SetCanClose !== "undefined") {
          props.SetCanClose(defaultValues[props.type] === event.target.value);
        }
        break;
      case "number":
        SetValue(parseInt(event.target.value));
        break;
      case "boolean":
        SetValue(event.target.checked);
        break;
      default:
        SetValue(null);
        break;
    }
  }

  function Submit() {
    props.onSubmit(value);
  }

  function EnterListener(event) {
    // console.log(event.shiftKey);
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      SetTriggeredSubmit(true);
    }
  }

  useEffect(() => {
    if (triggeredSubmit) {
      Submit();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggeredSubmit]);

  useEffect(() => {
    GenerateDefaultValue();

    window.addEventListener("keydown", EnterListener);

    return () => {
      window.removeEventListener("keydown", EnterListener);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="foragerInputModalContainer">
      <div className="foragerInputModalTitle">{props.title}</div>
      <div className="foragerInputModalMain">
        {props.type === "text" ? (
          <input
            className="foragerInputModalInput text"
            type="text"
            placeholder={props.placeholder}
            value={value}
            onChange={onChange}
            spellCheck={props.spellCheck}
          />
        ) : props.type === "textarea" ? (
          <AutosizedTextArea
            className="foragerInputModalInput textarea"
            placeholder={props.placeholder}
            value={value}
            onChange={onChange}
            spellCheck={props.spellCheck}
            minHeight={props.minHeight}
            autoFocus={true}
            enterPressed={Submit}
          />
        ) : props.type === "number" ? (
          <input
            className="foragerInputModalInput number"
            type="text"
            placeholder={props.placeholder}
            value={value}
            onChange={onChange}
          />
        ) : props.type === "boolean" ? (
          <input
            className="foragerInputModalInput boolean"
            type="checkbox"
            checked={value}
            onChange={onChange}
          />
        ) : null}
      </div>
      <div className="foragerInputModalButtons">
        <ForagerButton fullWidth={true} exit={true} onClick={props.onCancel}>
          Cancel
        </ForagerButton>
        <ForagerButton
          disabled={props.saving}
          onClick={props.saving ? null : Submit}
          fullWidth={true}
        >
          {props.saving ? "Saving..." : "Submit"}
        </ForagerButton>
      </div>
    </div>
  );
}

export default ForagerInputModal;
