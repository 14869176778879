import React, { useEffect, useState } from "react";
import { useSiteContext } from "../Context";
import ForagerButton from "../Generic/ForagerButton";

function TimeClockActions(props) {
  const {
    currentTimeEntry,
    GetTimeSinceDateTime,
    fieldCustomizations,
    PermissionCheck,
  } = useSiteContext();
  const [currentClockInDisplay, SetCurrentClockInDisplay] = useState("");
  const [currentTimerDisplay, SetCurrentTimerDisplay] = useState("0:00:00");
  const [lastUpdate, SetLastUpdate] = useState(new Date());
  const [buttonText, SetButtonText] = useState("");
  const [ButtonFunction, SetButtonFunction] = useState(null);

  function GenerateButtonInfo() {
    if (currentTimeEntry == null && props.ClockInFunction != null) {
      SetButtonText("Clock In");
      SetButtonFunction(() => props.ClockInFunction);
      return;
    }
    if (currentTimeEntry != null && props.ClockOutFunction != null) {
      if (props.SwitchFunction != null) {
        SetButtonText("Switch");
        SetButtonFunction(() => props.SwitchFunction);
        return;
      }
      SetButtonText("Clock Out");
      SetButtonFunction(() => props.ClockOutFunction);
      return;
    }
    SetButtonText("");
    SetButtonFunction(null);
  }

  useEffect(() => {
    if (currentTimeEntry == null) {
      if (currentTimerDisplay !== "0:00:00") {
        SetCurrentTimerDisplay("0:00:00");
      }
      return;
    }
    let timeSince = GetTimeSinceDateTime(new Date(currentTimeEntry.startTime));
    SetCurrentTimerDisplay(timeSince);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastUpdate]);

  useEffect(() => {
    let newDisplay = "Not Clocked In";
    if (currentTimeEntry != null) {
      newDisplay = "Clocked In";
      if (
        currentTimeEntry.scheduleItem != null &&
        currentTimeEntry.scheduleItemId !== 0
      ) {
        let scheduleItemName =
          currentTimeEntry.scheduleItem.scheduleItemTypeId === 1
            ? fieldCustomizations.general.job.name
            : fieldCustomizations.general.task.name;
        newDisplay +=
          " To " + scheduleItemName + ": " + currentTimeEntry.scheduleItem.name;
      } else if (currentTimeEntry.clientId !== 0) {
        newDisplay += " To " + currentTimeEntry.client.name + " (Ticket)";
      } else {
        newDisplay += " Error Retrieving Target";
        console.log("Error with currentTimeEntry", currentTimeEntry);
      }
    }
    SetCurrentClockInDisplay(newDisplay);

    if (currentTimeEntry != null && props.showTimer) {
      let refreshTime = setInterval(() => {
        SetLastUpdate(new Date());
      }, 1000);

      return () => clearInterval(refreshTime);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTimeEntry]);

  useEffect(() => {
    GenerateButtonInfo();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentTimeEntry,
    props.ClockInFunction,
    props.ClockOutFunction,
    props.SwitchFunction,
  ]);

  return PermissionCheck(14, 1) ? (
    <div className="mainScreenContentHeaderClockContainer">
      {currentClockInDisplay !== "" ? (
        <>
          <div className="mainScreenContentHeaderClockedInText">
            {props.totalForCurrentMonth != null ? (
              <>
                <div className="mainScreenContentHeaderClockedInText hideOnMobile">
                  This Month: {props.totalForCurrentMonth}
                </div>
                <div className="mainScreenContentHeaderClockedInText hideOnMobile">
                  Last Month: {props.totalForLastMonth}
                </div>
              </>
            ) : null}
          </div>
          <div
            className="mainScreenContentHeaderClockedInText boxShader"
            onClick={props.ToggleTimeClockModal}
          >
            {props.showText ? (
              <>
                <div>{currentClockInDisplay}</div>
                {currentTimeEntry != null && props.showTimer ? (
                  <div>
                    <div>{currentTimerDisplay}</div>
                  </div>
                ) : null}
              </>
            ) : null}
          </div>
          {ButtonFunction != null ? (
            <ForagerButton
              reversedColors={true}
              onClick={ButtonFunction}
              lessVerticalPadding={true}
              disabled={props.buttonDisabled}
            >
              {buttonText}
            </ForagerButton>
          ) : (
            <div></div>
          )}
        </>
      ) : null}
    </div>
  ) : (
    <div></div>
  );
}

export default TimeClockActions;
