import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import Modal from "../../../../../../../Generic/Modal";
import ClientItem from "./ClientItem";
import AddEditClient from "./ClientActions/AddEditClient";
import { useSiteContext } from "../../../../../../../Context";

function ClientManagement(props) {
  const [addClients, SetAddClients] = useState(false);
  const { clients, RefreshClients, fieldCustomizations } = useSiteContext();

  useEffect(() => {
    RefreshClients();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="adminModal tableOnly">
        <div className="adminModalBodyContainer tableOnly">
          <div className="adminModalTitleBar">
            <div className="adminModalTitle">
              {fieldCustomizations.general.client.name + " Management"}
            </div>
          </div>
          <div className="adminModalBody">
            <div className="adminModalBodyItemContainer clientItem header">
              <div className="adminModalBodyHeaderItem">Name</div>
              <div className="adminModalBodyHeaderItem">Edit</div>
            </div>
            {clients
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((c) => (
                <ClientItem
                  client={c}
                  key={c.id}
                  RefreshClients={RefreshClients}
                />
              ))}
          </div>
          <div className="adminModalTitleBar">
            <Icon
              icon="fluent:add-circle-16-filled"
              className="managementAddIcon"
              onClick={() => SetAddClients(true)}
            />
          </div>
        </div>
      </div>
      {addClients ? (
        <Modal
          CloseModal={() => SetAddClients(false)}
          modificationClass="noPaddingAlternate"
        >
          <AddEditClient
            editMode={false}
            CloseModal={() => SetAddClients(false)}
            // customer={props.loggedInUser.customer.id}
            RefreshClients={RefreshClients}
          />
        </Modal>
      ) : null}
    </>
  );
}

export default ClientManagement;
