import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import DropDownMenu from "../../../Generic/DropDownMenu";
import HQLogoSVG from "../../../Generic/HQLogoSVG";
import ForagerButton from "../../../Generic/ForagerButton";
import { useIQContext } from "../../../IQContext";
import { useSiteContext } from "../../../Context";
import ChooseKnowledgeBase from "./ChooseKnowledgeBase";

function IQAdd(props) {
  const {
    uploading,
    HandleDragOver,
    HandleDrop,
    HandleDragEnter,
    HandleDragLeave,
    HandleUploadButton,
    currentItem,
    files,
    resetKey,
    isDraggingOver,
    SetAddType,
    HandleChooseFile,
    HandleRemoveChosenFile,
    ChangeDocumentKnowledgeBases,
    HandleReplaceFile,
    ShowExtendedSideBar,
    AddKnowledgeBase,
    EditKnowledgeBase,
    DeleteKnowledgeBase,
    DeleteFile,
  } = useIQContext();

  const { loggedInUser, GetData, AddAlert } = useSiteContext();
  const [customers, SetCustomers] = useState([]);
  const [name, SetName] = useState("");
  const [description, SetDescription] = useState("");
  const [selectedCustomer, SetSelectedCustomer] = useState();
  const [selectedType, SetSelectedType] = useState(1);

  function ChangeName(event) {
    SetName(event.target.value);
  }

  function ChangeDescription(event) {
    SetDescription(event.target.value);
  }

  function ChangeSelectedCustomer(customerId) {
    SetSelectedCustomer(customerId);
  }

  function ChangeSelectedType(type) {
    SetSelectedType(type);
  }

  async function GetCustomers() {
    let newCustomer = await GetData("Customer", {
      orderBy: "name",
    });
    if (newCustomer instanceof Array) {
      SetCustomers(newCustomer);
    }
  }

  async function Create() {
    if (props.type === "Document") {
      await HandleUploadButton();
      return;
    }
    if (name === "") {
      AddAlert("error", "Name is required.");
      return;
    }
    await AddKnowledgeBase({
      name: name,
      description: description,
      isGeneral: selectedType === 2,
      customerId: selectedCustomer,
    });
  }

  async function Update() {
    if (props.type === "Document") {
      await ChangeDocumentKnowledgeBases();
      setTimeout(() => {
        HandleReplaceFile();
      }, 1000);
      return;
    }
    if (name === "") {
      AddAlert("error", "Name cannot be blank.");
      return;
    }
    await EditKnowledgeBase(
      currentItem.id,
      name,
      description,
      selectedCustomer
    );
  }

  async function Delete() {
    if (props.type === "Document") {
      await DeleteFile();
      return;
    }
    await DeleteKnowledgeBase();
  }

  async function HandleBottomButtonClick() {
    if (uploading) {
      return;
    }
    switch (props.mode) {
      case "edit":
        await Update();
        break;
      case "delete":
        await Delete();
        break;
      default:
        await Create();
        break;
    }
  }

  function GetBottomButtonText() {
    let text = "";
    switch (props.mode) {
      case "edit":
        text = uploading ? "Updating..." : "Update ";
        break;
      case "delete":
        text = uploading ? "Deleting..." : "Delete ";
        break;
      default:
        text = uploading
          ? "Creating..."
          : props.type === "Document"
          ? "Add "
          : "Create ";
        break;
    }
    if (text.includes("...")) {
      return text;
    }
    return text + (props.type === "Document" ? "Document" : "Knowledge Base");
  }

  useEffect(() => {
    if (props.mode !== "add" && currentItem) {
      SetName(currentItem.name);
      SetDescription(currentItem.description);
      SetSelectedType(currentItem.general ? 2 : 1);
      SetSelectedCustomer(currentItem.customerId);
    } else {
      SetName("");
      SetDescription("");
      SetSelectedType(1);
      SetSelectedCustomer(loggedInUser.customerId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentItem]);

  useEffect(() => {
    if (loggedInUser?.isHammr) {
      GetCustomers();
      SetSelectedCustomer(loggedInUser.customerId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedInUser]);

  return (
    <div
      className="documentManagementAddBar"
      onDragOver={HandleDragOver}
      onDrop={HandleDrop}
      onDragEnter={HandleDragEnter}
      onDragLeave={HandleDragLeave}
    >
      {props.mode !== "add" ? (
        <div className={"documentManagementTabs single"}>
          <div className="tab active">
            {(props.mode === "edit" ? "Edit " : "Delete ") +
              (props.type === "Document" ? "Document" : "Knowledge Base")}
          </div>
        </div>
      ) : (
        <div className="documentManagementTabs">
          <div
            className={"tab" + (props.type === "Document" ? " active" : "")}
            onClick={() => {
              ShowExtendedSideBar("DocumentManagement");
            }}
          >
            Add Document
          </div>
          <div
            className={
              "tab" + (props.type === "KnowledgeBase" ? " active" : "")
            }
            onClick={() => {
              ShowExtendedSideBar("KnowledgeBaseManagement");
            }}
          >
            New Knowledge Base
          </div>
        </div>
      )}
      {props.type === "Document" && props.mode !== "delete" ? (
        <>
          <div
            className={
              "DocumentDragOverOverlay" +
              (isDraggingOver && !uploading ? " active" : "")
            }
          >
            Drop File to Upload
          </div>
          <div className="DocumentFilesActions">
            {uploading && (
              <div className="DocumentUploadLogoOverlay">
                <div
                  className="logo spinning DocumentUploadLogo"
                  alt="Loading Animation"
                >
                  <HQLogoSVG full={false} />
                </div>
              </div>
            )}
            <label className="JobFilesChooseFileLabel topLevel">
              <ForagerButton
                lessVerticalPadding={true}
                onClick={() => {}}
                disabled={uploading}
                lessRadius={true}
                fullWidth={true}
              >
                {props.mode === "edit" ? "Replace File" : "Select Files"}
              </ForagerButton>
              <input
                className="JobFilesChooseFileInput"
                type="file"
                id="file"
                onChange={HandleChooseFile}
                key={resetKey}
                multiple
              />
            </label>

            {files == null || files.length === 0 ? (
              <div className="selectedDocumentsContainer noDocuments">
                {props.mode !== "add" && currentItem != null
                  ? "Current File: " + currentItem?.fileName
                  : "Selected Files Will Show Here"}
              </div>
            ) : (
              <div className="selectedDocumentsContainer">
                {files.map((file, index) => (
                  <div className="selectedDocument" key={index}>
                    <div
                      className="removeDocumentIcon"
                      onClick={() => HandleRemoveChosenFile(file)}
                    >
                      <Icon
                        icon="akar-icons:trash-can"
                        onClick={() => {
                          SetAddType("");
                        }}
                      />
                    </div>
                    <div className="selectedDocumentName">{file.name}</div>
                  </div>
                ))}
              </div>
            )}
            <div className="ChooseKnowledgeBaseContainer">
              <ChooseKnowledgeBase
                headerText={"Select Knowledge Bases"}
                disableAsking={true}
                BottomButtonFunction={
                  (files.length === 0 && props.mode === "add") || uploading
                    ? null
                    : HandleBottomButtonClick
                }
                editMode={props.mode === "edit"}
                bottomButtonText={GetBottomButtonText()}
              />
            </div>
          </div>
        </>
      ) : (
        <div className="AddKnowledgeBaseContainer">
          {props.type === "KnowledgeBase" ? (
            <>
              <div className="inputContainer">
                <div className="inputLabel required">
                  <span className="requiredIndicator">*</span>Name
                </div>
                {props.mode !== "delete" ? (
                  <input
                    type="text"
                    className="inputEntry"
                    value={name}
                    onChange={ChangeName}
                  />
                ) : (
                  <div className="inputDisplay">{name}</div>
                )}
              </div>
              <div className="inputContainer">
                <div className="inputLabel">Description</div>
                {props.mode !== "delete" ? (
                  <textarea
                    className="inputEntry area"
                    value={description}
                    onChange={ChangeDescription}
                  />
                ) : (
                  <div className="inputDisplay">{description}</div>
                )}
              </div>
              {loggedInUser.isHammr && (
                <>
                  <div className="inputContainer">
                    <div className="inputLabel">Type</div>
                    {props.mode !== "delete" ? (
                      <DropDownMenu
                        options={[
                          { id: 1, name: "Private" },
                          { id: 2, name: "General" },
                        ]}
                        showBlank={false}
                        value={selectedType}
                        dontSort={true}
                        onChange={ChangeSelectedType}
                      />
                    ) : (
                      <div className="inputDisplay">
                        {selectedType === 1 ? "Private" : "General"}
                      </div>
                    )}
                  </div>
                  <div className="inputContainer">
                    <div className="inputLabel">Customer</div>
                    {props.mode !== "delete" ? (
                      <DropDownMenu
                        options={customers}
                        showBlank={false}
                        value={selectedCustomer}
                        onChange={ChangeSelectedCustomer}
                      />
                    ) : (
                      <div className="inputDisplay">
                        {customers.find((c) => c.id === selectedCustomer)?.name}
                      </div>
                    )}
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              <div className="inputContainer">
                <div className="inputLabel">File</div>
                <div className="inputDisplay">{currentItem?.fileName}</div>
              </div>
              <div className="inputContainer">
                <div className="inputLabel">Knowledge Bases</div>
                <div className="inputDisplay list">
                  {currentItem?.knowledgeBases
                    ?.sort((kb) => kb.name)
                    .reverse()
                    .map((kb, index) => (
                      <div key={index} className="inputInnerListItem">
                        {kb.name}
                      </div>
                    ))}
                </div>
              </div>
            </>
          )}
          <div
            className="knowledgeBaseListFooter"
            onClick={HandleBottomButtonClick}
          >
            <div className="knowledgeBaseListFooterTitle">
              {GetBottomButtonText()}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default IQAdd;
