import React, { useEffect, useState } from "react";
import Modal from "../../../../Generic/Modal";
import Customize from "./SidebarModals/Admin/Customize/Customize";
import UserManagement from "./SidebarModals/Admin/UserManagement/UserManagement";
import CustomerManagement from "./SidebarModals/Admin/CustomerManagement/CustomerManagement";
import ClientManagement from "./SidebarModals/Admin/ClientManagement/ClientManagement";
import CustomerSettings from "./SidebarModals/Admin/CustomerSettings/CustomerSettings";
import ReportsModal from "./SidebarModals/Reports/ReportsModal";
import ImpersonateModal from "../Impersonate/ImpersonateModal";
import ChangePasswordModal from "./SidebarModals/ChangePassword";
import { useSiteContext } from "../../../../Context";
import { useIQContext } from "../../../../IQContext";
import ForagerButton from "../../../../Generic/ForagerButton";

function SidebarButtons(props) {
  const [reportsModal, SetReportsModal] = useState(false);
  const [customize, SetCustomize] = useState(false);
  const [userManagement, SetUserManagement] = useState(false);
  const [customerManagement, SetCustomerManagement] = useState(false);
  const [customerSettings, SetCustomerSettings] = useState(false);
  const [clientManagement, SetClientManagement] = useState(false);
  const [editsPending, SetEditsPending] = useState(false);
  const [impersonateModalOpen, SetImpersonateModalOpen] = useState(false);
  const [changePassword, SetChangePassword] = useState(false);
  const { loggedInUser, fieldCustomizations, PermissionCheck, FeatureCheck } =
    useSiteContext();
  const { ShowExtendedSideBar } = useIQContext();

  function PresentModal(modalName, value, override = false) {
    let canContinue = true;
    if (!override && !value && editsPending) {
      canContinue = window.confirm(
        "You have unsaved changes that will be lost. Are you sure you want to close this window?"
      );
    }
    if (!canContinue) {
      return;
    }
    SetEditsPending(false);
    switch (modalName) {
      case "Impersonate":
        SetImpersonateModalOpen(value);
        break;
      case "Reports":
        SetReportsModal(value);
        break;
      case "TimeClock":
        props.ToggleTimeClockModal();
        props.CloseModal();
        break;
      case "DocumentManagement":
        props.ToggleIQOpen(true);
        ShowExtendedSideBar("DocumentManagement", null, true);
        break;
      case "UserManagement":
        SetUserManagement(value);
        break;
      case "Customize":
        SetCustomize(value);
        break;
      case "CustomerManagement":
        SetCustomerManagement(value);
        break;
      case "Settings":
        SetCustomerSettings(value);
        break;
      case "ClientManagement":
        SetClientManagement(value);
        break;
      case "ChangePassword":
        SetChangePassword(value);
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    if (reportsModal || customize || customerSettings || clientManagement) {
      props.ToggleIQOpen(false, true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportsModal, customize, customerSettings, clientManagement]);

  return (
    <>
      <div className="sidebarButtonsContainer">
        {FeatureCheck("HQ") ? (
          <div className="sidebarSection">
            <div
              className="sidebarSectionTitle"
              onClick={() => props.ToggleIQOpen(false)}
            >
              HQ
            </div>
            <ForagerButton
              fullWidth={true}
              smallText={true}
              lessRadius={true}
              noVerticalPadding={true}
              onClick={() => PresentModal("Reports", true)}
            >
              Reports
            </ForagerButton>
            <ForagerButton
              fullWidth={true}
              smallText={true}
              lessRadius={true}
              noVerticalPadding={true}
              onClick={() => PresentModal("TimeClock", true)}
            >
              Time Clock
            </ForagerButton>
            <ForagerButton
              fullWidth={true}
              smallText={true}
              lessRadius={true}
              noVerticalPadding={true}
              onClick={() => PresentModal("ClientManagement", true)}
            >
              {fieldCustomizations.general.client.name + " Management"}
            </ForagerButton>
            <ForagerButton
              fullWidth={true}
              smallText={true}
              lessRadius={true}
              noVerticalPadding={true}
              onClick={() => PresentModal("Customize", true)}
            >
              Customize
            </ForagerButton>
            <ForagerButton
              fullWidth={true}
              smallText={true}
              lessRadius={true}
              noVerticalPadding={true}
              onClick={() => PresentModal("Settings", true)}
            >
              Settings
            </ForagerButton>
          </div>
        ) : null}
        {FeatureCheck("IQ") ? (
          <div className="sidebarSection">
            <div
              className="sidebarSectionTitle"
              onClick={() => props.ToggleIQOpen(true)}
            >
              IQ
            </div>
            {PermissionCheck(17, 3) ? (
              <ForagerButton
                fullWidth={true}
                smallText={true}
                lessRadius={true}
                noVerticalPadding={true}
                onClick={() => PresentModal("DocumentManagement", true)}
              >
                Document Management
              </ForagerButton>
            ) : null}
          </div>
        ) : null}
        <div className="sidebarSection">
          <div className="sidebarSectionTitle">Options</div>
          {loggedInUser.isHammr || props.impersonating ? (
            <ForagerButton
              fullWidth={true}
              smallText={true}
              lessRadius={true}
              noVerticalPadding={true}
              onClick={() =>
                props.impersonating
                  ? props.ImpersonateUser(null)
                  : PresentModal("Impersonate", true)
              }
            >
              {props.impersonating
                ? "End Impersonate"
                : "Impersonate " + fieldCustomizations.general.user.name}
            </ForagerButton>
          ) : null}
          {loggedInUser.isHammr ? (
            <ForagerButton
              fullWidth={true}
              smallText={true}
              lessRadius={true}
              noVerticalPadding={true}
              onClick={() => PresentModal("CustomerManagement", true)}
            >
              Customer Management
            </ForagerButton>
          ) : null}
          {PermissionCheck(2, 1) ? (
            <ForagerButton
              fullWidth={true}
              smallText={true}
              lessRadius={true}
              noVerticalPadding={true}
              onClick={() => PresentModal("UserManagement", true)}
            >
              {fieldCustomizations.general.user.name + " Management"}
            </ForagerButton>
          ) : null}

          <ForagerButton
            fullWidth={true}
            smallText={true}
            lessRadius={true}
            noVerticalPadding={true}
            onClick={() => PresentModal("ChangePassword", true)}
          >
            Change Password
          </ForagerButton>
        </div>
      </div>
      {reportsModal ? (
        <Modal
          CloseModal={() => PresentModal("Reports", false)}
          modificationClass="noPadding"
        >
          <ReportsModal
            CloseModal={() => PresentModal("Reports", false)}
            jobsForFilter={props.jobsForFilter}
            clientsForFilter={props.clientsForFilter}
            usersForFilter={props.usersForFilter}
            tasksForFilter={props.tasksForFilter}
          />
        </Modal>
      ) : null}
      {impersonateModalOpen ? (
        <Modal
          CloseModal={() => PresentModal("Impersonate", false)}
          modificationClass="noPaddingAlternate"
        >
          <ImpersonateModal
            ImpersonateUser={props.ImpersonateUser}
            CloseModal={() => PresentModal("Impersonate", false)}
          />
        </Modal>
      ) : null}
      {userManagement ? (
        <Modal
          CloseModal={() => PresentModal("UserManagement", false)}
          modificationClass="noPaddingAlternate"
        >
          <UserManagement
            CloseModal={(override = false) =>
              PresentModal("UserManagement", false, override)
            }
            SetEditsPending={SetEditsPending}
          />
        </Modal>
      ) : null}
      {customize ? (
        <Modal
          CloseModal={() => PresentModal("Customize", false)}
          modificationClass="noPaddingAlternate"
        >
          <Customize
            CloseModal={(override = false) =>
              PresentModal("Customize", false, override)
            }
            SetEditsPending={SetEditsPending}
          />
        </Modal>
      ) : null}
      {customerManagement ? (
        <Modal
          CloseModal={() => PresentModal("CustomerManagement", false)}
          modificationClass="noPaddingAlternate"
        >
          <CustomerManagement
            CloseModal={(override = false) =>
              PresentModal("CustomerManagement", false, override)
            }
            SetEditsPending={SetEditsPending}
          />
        </Modal>
      ) : null}
      {customerSettings ? (
        <Modal
          CloseModal={() => PresentModal("Settings", false)}
          modificationClass="noPaddingAlternate"
        >
          <CustomerSettings
            CloseModal={(override = false) =>
              PresentModal("Settings", false, override)
            }
            SetEditsPending={SetEditsPending}
          />
        </Modal>
      ) : null}
      {clientManagement ? (
        <Modal
          CloseModal={() => PresentModal("ClientManagement", false)}
          modificationClass="noPaddingAlternate"
        >
          <ClientManagement
            CloseModal={(override = false) =>
              PresentModal("ClientManagement", false, override)
            }
            SetEditsPending={SetEditsPending}
          />
        </Modal>
      ) : null}
      {changePassword ? (
        <Modal
          CloseModal={() => PresentModal("ChangePassword", false)}
          modificationClass="noPaddingAlternate"
        >
          <ChangePasswordModal
            CloseModal={() => PresentModal("ChangePassword", false)}
          />
        </Modal>
      ) : null}
    </>
  );
}

export default SidebarButtons;
