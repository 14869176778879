import React, { useEffect, useState } from "react";
import { useIQContext } from "../../../IQContext";
import { Icon } from "@iconify/react";
import Modal from "../../../Generic/Modal";
import PDFViewer from "../../../Generic/PDFViewer";
import ForagerTable from "../../../Generic/ForagerTable";
import ForagerTableColumn from "../../../Classes/ForagerTableColumn";

function DocumentManagement(props) {
  const {
    OpenInPDFViewer,
    ChangeFilePreview,
    GetDocuments,
    GetKnowledgeBases,
    documents,
    knowledgeBases,
    filePreview,
    PDFFileToView,
    PDFViewerModalOpen,
    ShowExtendedSideBar,
  } = useIQContext();
  const [knowledgeBasesWithDocs, SetKnowledgeBasesWithDocs] = useState(null);
  const [searchText, SetSearchText] = useState("");
  const [triggerAllExpanded, SetTriggerAllExpanded] = useState(false);

  function PutDocsInKnowledgeBases() {
    if (knowledgeBases === null || documents === null) {
      return;
    }
    let knowledgeBasesWithDocs = knowledgeBases.map((kb) => {
      let docs = documents.filter(
        (d) =>
          d.knowledgeBases.some((k) => k.id === kb.id) &&
          d.fileName.toLowerCase().includes(searchText.toLowerCase())
      );
      if (docs.length === 0) {
        return null;
      }
      return { ...kb, docs: docs };
    });
    knowledgeBasesWithDocs = knowledgeBasesWithDocs.filter((kb) => kb !== null);
    SetKnowledgeBasesWithDocs([...knowledgeBasesWithDocs]);
  }

  function ChangeSearchText(e) {
    SetSearchText(e.target.value);
    SetTriggerAllExpanded(true);
    let timeout = setTimeout(() => {
      SetTriggerAllExpanded(false);
      clearTimeout(timeout);
    }, 100);
  }

  useEffect(() => {
    PutDocsInKnowledgeBases();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documents, knowledgeBases, searchText]);

  useEffect(() => {
    GetDocuments();
    GetKnowledgeBases();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const knowledgeBaseColumns = [
    new ForagerTableColumn({
      header: "Name",
      property: "name",
      sortable: true,
      stretch: true,
    }),
    new ForagerTableColumn({
      header: "",
      ValueFunction: (item) => {
        return (
          <div
            className="KnowledgeBaseListItemPart action"
            onClick={() => {
              ShowExtendedSideBar("KnowledgeBaseManagementEdit", item);
            }}
          >
            <Icon icon="akar-icons:pencil" />
            <span className="tooltip">Edit Knowledge Base</span>
          </div>
        );
      },
      width: 3,
    }),
    new ForagerTableColumn({
      header: "",
      ValueFunction: (item) => {
        return (
          <div
            className="KnowledgeBaseListItemPart action"
            onClick={() => {
              ShowExtendedSideBar("KnowledgeBaseManagementDelete", item);
            }}
          >
            <Icon icon="akar-icons:trash-can" />
            <span className="tooltip">Delete Knowledge Base</span>
          </div>
        );
      },
      width: 3,
    }),
  ];

  const documentColumns = [
    new ForagerTableColumn({
      header: "File Name",
      property: "fileName",
      sortable: true,
      stretch: true,
      isDefaultSort: true,
    }),
    new ForagerTableColumn({
      ValueFunction: (item) => {
        return (
          <div
            className="DocumentsItemPart action"
            onClick={() => {
              OpenInPDFViewer(item.uri);
            }}
          >
            <Icon icon="material-symbols:open-in-browser" />
            <span className="tooltip">Open in the Browser</span>
          </div>
        );
      },
      width: 3,
    }),
    new ForagerTableColumn({
      ValueFunction: (item) => {
        return (
          <div
            className="DocumentsItemPart action"
            onClick={() => {
              window.open(item.uri, "_blank");
            }}
          >
            <a
              href={item.uri}
              download={item.fileName}
              target="_blank"
              rel="noreferrer"
              aria-hidden="true"
              className="hiddenElement"
            >
              Download Document
            </a>
            <Icon icon="material-symbols:download" />
            <span className="tooltip">Download Document</span>
          </div>
        );
      },
      width: 3,
    }),
    new ForagerTableColumn({
      ValueFunction: (item) => {
        return (
          <div
            className="DocumentsItemPart action"
            onClick={() => {
              ShowExtendedSideBar("DocumentManagementEdit", item);
            }}
          >
            <Icon icon="akar-icons:pencil" />
            <span className="tooltip">Edit Document</span>
          </div>
        );
      },
      width: 3,
    }),
    new ForagerTableColumn({
      ValueFunction: (item) => {
        return (
          <div
            className="DocumentsItemPart action"
            onClick={() => {
              ShowExtendedSideBar("DocumentManagementDelete", item);
            }}
          >
            <Icon icon="akar-icons:trash-can" />
            <span className="tooltip">Delete Document</span>
          </div>
        );
      },
      width: 3,
    }),
  ];

  function HandleDocClick(doc) {
    ChangeFilePreview(doc.uri);
  }

  useEffect(() => {
    PutDocsInKnowledgeBases();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documents, knowledgeBases]);

  return (
    <>
      <div className="documentManagement">
        <div className="DocumentBodySection">
          <div className="DocumentItemSection">
            <div className="searchbar">
              Search Documents :
              <input
                type="text"
                placeholder="Search"
                value={searchText}
                onChange={ChangeSearchText}
              />
            </div>
            <ForagerTable
              columns={knowledgeBaseColumns}
              items={knowledgeBasesWithDocs}
              subTableColumns={documentColumns}
              subTableItemsProperty={"docs"}
              onClickSubRow={HandleDocClick}
              triggerAllExpanded={triggerAllExpanded}
            />
          </div>
          <div className="DocumentPreviewSection">
            {filePreview !== null ? (
              <div className="DocumentPreviewWrapper">
                <PDFViewer file={filePreview} pageNumber={1} isPreview={true} />
              </div>
            ) : (
              <Icon
                icon="mdi:file-find"
                className="JobFileImagePreviewNotFoundIcon"
              />
            )}
          </div>
        </div>
      </div>
      {PDFViewerModalOpen ? (
        <Modal
          CloseModal={() => OpenInPDFViewer(null)}
          modificationClass="pdfViewer"
        >
          <PDFViewer file={PDFFileToView} startPage={1} />
        </Modal>
      ) : null}
    </>
  );
}

export default DocumentManagement;
