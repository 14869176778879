import { React, useState, useEffect, useRef } from "react";
import ForagerButton from "./ForagerButton";
import * as Utilities from "../Utilities";

function MultiSelectorModal(props) {
  const [filteredAssignedItems, SetFilteredAssignedItems] = useState([]);
  const [filteredUnassignedItems, SetFilteredUnassignedItems] = useState([]);
  const [finalFilteredAssignedItems, SetFinalFilteredAssignedItems] = useState(
    []
  );
  const [finalFilteredUnassignedItems, SetFinalFilteredUnassignedItems] =
    useState([]);
  const [search, SetSearch] = useState("");
  const [showSelectionBox, SetShowSelectionBox] = useState(false);
  const [isInLowerHalf, SetIsInLowerHalf] = useState(false);
  const mainRef = useRef(null);
  const searchRef = useRef(null);

  function ShowSelectionBox() {
    CheckIfLowerHalf();
    SetShowSelectionBox(true);
  }

  function HideSelectionBox(event) {
    if (mainRef.current != null && !mainRef.current.contains(event.target)) {
      SetShowSelectionBox(false);
    }
  }

  function ChangeSearch(event) {
    SetSearch(event.target.value);
  }

  function ChangeItemAssignment(e, id) {
    e.preventDefault();
    searchRef.current.focus();
    if (props.currentlyAssigned.includes(id)) {
      props.SetAssignmentFunction(
        props.currentlyAssigned.filter((item) => item !== id)
      );
      return;
    }
    props.SetAssignmentFunction([...props.currentlyAssigned, id]);
  }

  function CheckIfAssigned(id) {
    var processedId = id;
    if (!isNaN(id)) {
      processedId = parseInt(id);
    }
    return props.currentlyAssigned.includes(processedId);
  }

  function SortItems(items, orderBy) {
    switch (orderBy) {
      case "listOrder":
        items.sort((a, b) =>
          Utilities.CustomSort(a.listOrder, b.listOrder, false, true)
        );
        break;
      default:
        items.sort((a, b) => Utilities.CustomSort(a.name, b.name, true, true));
        break;
    }
    return items;
  }

  function FilterAssignedItems() {
    let assigned = props.assignableItems.filter((item) =>
      CheckIfAssigned(item.id)
    );
    assigned = SortItems(assigned, props.orderBy);
    SetFilteredAssignedItems(assigned);
  }

  function FilterUnassignedItems() {
    let unassigned = props.assignableItems.filter(
      (item) =>
        !CheckIfAssigned(item.id) &&
        item.name.toLowerCase().includes(search.toLowerCase())
    );
    unassigned = SortItems(unassigned, props.orderBy);
    SetFilteredUnassignedItems(unassigned);
  }

  function SetInversion() {
    if (props.inverted) {
      SetFinalFilteredAssignedItems(filteredUnassignedItems);
      SetFinalFilteredUnassignedItems(filteredAssignedItems);
      return;
    }
    SetFinalFilteredAssignedItems(filteredAssignedItems);
    SetFinalFilteredUnassignedItems(filteredUnassignedItems);
  }

  function CheckIfLowerHalf() {
    SetIsInLowerHalf(Utilities.CheckIfLowerHalf(mainRef.current));
  }

  useEffect(() => {
    let activeMainRef = mainRef.current;
    let clickListener = null;
    if (activeMainRef != null) {
      clickListener = document.addEventListener("click", HideSelectionBox);
    }

    return () => {
      document.removeEventListener("click", clickListener);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.alwaysShow, mainRef.current]);

  useEffect(() => {
    SetInversion();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredAssignedItems, filteredUnassignedItems]);

  useEffect(() => {
    FilterAssignedItems();
    FilterUnassignedItems();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, props.assignableItems, props.currentlyAssigned]);

  useEffect(() => {
    if (searchRef.current != null) {
      searchRef.current.focus();
    }
  }, [showSelectionBox]);

  return (
    <div
      className={
        "foragerMultiSelect" +
        (isInLowerHalf ? " lowerHalf" : "") +
        (props.alwaysShow ? " alwaysShow" : "") +
        (showSelectionBox ? " shown" : "")
      }
      ref={mainRef}
    >
      <div
        className={
          "foragerMultiSelectSearch" +
          (showSelectionBox ? " shown" : "") +
          (props.alwaysShow ? " alwaysShow" : "")
        }
      >
        <input
          className={
            "foragerMultiSelectSearchDisplay" +
            (showSelectionBox || props.alwaysShow ? "" : " hidden")
          }
          value={
            showSelectionBox || props.alwaysShow
              ? search
              : finalFilteredAssignedItems.map((item) => item.name).join(", ")
          }
          onChange={ChangeSearch}
          placeholder="Search..."
          tabIndex={0}
          ref={searchRef}
        />
        <div
          className={
            "foragerMultiSelectSearchDisplay current" +
            (showSelectionBox || props.alwaysShow ? " invisible" : "") +
            (props.padLeft ? " padLeft" : "") +
            (props.padVertical ? " padVertical" : "")
          }
          onClick={ShowSelectionBox}
        >
          {finalFilteredAssignedItems.length > 0 ? (
            finalFilteredAssignedItems.map((item, index) => (
              <div key={index}>
                {item.name +
                  (index === finalFilteredAssignedItems.length - 1 ? "" : ",")}
              </div>
            ))
          ) : (
            <div>{props.noneSelectedLabel ?? "None Selected"}</div>
          )}
        </div>
      </div>
      <div
        className={
          "foragerMultiSelectAnchor" + (props.alwaysShow ? " alwaysShow" : "")
        }
      >
        {showSelectionBox || props.alwaysShow ? (
          <div className="foragerMultiSelectList">
            {finalFilteredAssignedItems.length !== 0 ? (
              <div className="foragerMultiSelectItem headerWrapper">
                <div className="foragerMultiSelectItem header">Selected</div>
              </div>
            ) : null}
            {finalFilteredAssignedItems.map((item, index) => (
              <div className="foragerMultiSelectItem active" key={index}>
                <ForagerButton
                  noVerticalPadding={!props.padVertical ?? true}
                  fullWidth={true}
                  tinyText={props.tinyText ?? true}
                  hoverColor="red"
                  onClick={(e) => ChangeItemAssignment(e, item.id)}
                >
                  {item.name}
                </ForagerButton>
              </div>
            ))}
            <div className="foragerMultiSelectItem headerWrapper">
              <div className="foragerMultiSelectItem header">Not Selected</div>
            </div>
            {finalFilteredUnassignedItems.map((item, index) => (
              <div className="foragerMultiSelectItem" key={index}>
                <ForagerButton
                  noVerticalPadding={!props.padVertical ?? true}
                  fullWidth={true}
                  tinyText={props.tinyText ?? true}
                  onClick={(e) => ChangeItemAssignment(e, item.id)}
                >
                  {item.name}
                </ForagerButton>
              </div>
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default MultiSelectorModal;
