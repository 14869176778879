import React from "react";
import { useIQContext } from "../../../IQContext";

function SearchBar(props) {
  const { knowledgeBaseSearchText, SetKnowledgeBaseSearchText } =
    useIQContext();

  function HandleSearch(event) {
    SetKnowledgeBaseSearchText(event.target.value);
  }

  return (
    <div className="iqSearchBar">
      <input
        type="text"
        placeholder="Search"
        className="searchInput"
        value={knowledgeBaseSearchText}
        onChange={HandleSearch}
      />
    </div>
  );
}

export default SearchBar;
