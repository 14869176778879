import React, { useState } from "react";
import SearchBar from "./SearchBar";
import { Icon } from "@iconify/react";
import { useIQContext } from "../../../IQContext";

function ChooseKnowledgeBase(props) {
  const {
    SummarizeKnowledgeBases,
    selectedKnowledgeBases,
    currentItemSelectedKnowledgeBases,
    knowledgeBaseOptions,
    HandleKnowledgeBaseSelection,
    knowledgeBaseSearchText,
    ShowExtendedSideBar,
  } = useIQContext();

  const currentSelectedKnowledgeBases = props.editMode
    ? currentItemSelectedKnowledgeBases
    : selectedKnowledgeBases;

  const [sortDirection, SetSortDirection] = useState("desc");

  function ToggleSortDirection() {
    SetSortDirection(sortDirection === "desc" ? "asc" : "desc");
  }

  return (
    <div className="chooseKnowledgeBase">
      <SearchBar />
      <div className="knowledgeBaseListHeader" onClick={ToggleSortDirection}>
        <div className="knowledgeBaseListHeaderIcon">
          <Icon
            icon={
              sortDirection === "desc"
                ? "akar-icons:arrow-down"
                : "akar-icons:arrow-up"
            }
          />
        </div>
        <div className="knowledgeBaseListHeaderTitle">
          {props.headerText ?? "Name"}
        </div>
        <div
          className="infoIcon"
          data-tip={
            currentSelectedKnowledgeBases.length === 0
              ? "No knowledge bases selected"
              : "Knowledge Bases Selected:<br>" +
                currentSelectedKnowledgeBases.map((kb) => kb.name).join("<br>")
          }
          data-multiline={true}
        >
          <Icon
            icon="mdi:information-outline"
            className="knowledgeBaseListItemIcon"
          />
        </div>
      </div>
      <div className="knowledgeBaseList">
        {knowledgeBaseOptions
          .filter((knowledgeBase) => {
            if (knowledgeBaseSearchText === "") {
              return true;
            }
            return knowledgeBase.name
              .toLowerCase()
              .includes(knowledgeBaseSearchText.toLowerCase());
          })
          .sort(
            sortDirection === "desc"
              ? (a, b) => a.name.localeCompare(b.name)
              : (a, b) => b.name.localeCompare(a.name)
          )
          .map((knowledgeBase, index) => (
            <div
              key={index}
              className={
                "knowledgeBaseListItem" +
                (currentSelectedKnowledgeBases.find(
                  (kb) => knowledgeBase.id === kb.id
                ) != null
                  ? " selected"
                  : "")
              }
              onClick={() =>
                HandleKnowledgeBaseSelection(knowledgeBase, props.editMode)
              }
            >
              <div className="knowledgeBaseListItemTitle">
                {knowledgeBase.name}
              </div>
              <div
                className="infoIcon"
                data-tip={
                  !props.disableAsking
                    ? "Ask what is in " +
                      knowledgeBase.name +
                      (knowledgeBase.description != null &&
                      knowledgeBase.description !== ""
                        ? "<br>Description: " + knowledgeBase.description
                        : "")
                    : knowledgeBase.description
                }
                data-multiline={true}
                onClick={
                  !props.disableAsking
                    ? (e) => {
                        e.stopPropagation();
                        SummarizeKnowledgeBases(
                          [knowledgeBase.name],
                          [knowledgeBase.id]
                        );
                      }
                    : (e) => {
                        e.stopPropagation();
                      }
                }
              >
                <Icon
                  icon="mdi:information-outline"
                  className="knowledgeBaseListItemIcon"
                />
              </div>
            </div>
          ))}
      </div>
      <div
        className="knowledgeBaseListFooter"
        onClick={
          props.BottomButtonFunction != null
            ? props.BottomButtonFunction
            : () => ShowExtendedSideBar("DocumentManagement")
        }
      >
        <div className="knowledgeBaseListFooterTitle">
          {props.bottomButtonText ?? "Manage Documents"}
        </div>
      </div>
    </div>
  );
}

export default ChooseKnowledgeBase;
