import React, { useState } from "react";
import { useSiteContext } from "../../../Context";

function IQHelp(props) {
  const [openSections, SetOpenSections] = useState({});
  const { PermissionCheck } = useSiteContext();

  let content = [
    {
      title: "Asking Questions",
      body: "To ask a question, first click on the 'Select Knowledge Bases' button on the side bar and select the knowledge base(s) you'd like to query. Then, type your question into the text box at the bottom of the screen and press enter. Forager IQ will then search through the selected knowledge bases and return the most relevant information.",
    },
    {
      title: "Select Chat Model",
      body: "Click the 'Switch To GPT 3.5 / 4o' button on the side bar to toggle between the different chat models. Model 3.5 provides quicker answers, while Model 4T provides more accurate, in-depth answers.",
    },
  ];

  let showCreateAndUpload = PermissionCheck(17, 3);

  if (showCreateAndUpload) {
    content = [
      ...content,
      {
        title: "Creating New Knowledge Bases",
        body: "To create a knowledge base, click on the 'Document Management' button on the side bar. Select the 'New Knowledge Base' tab and enter the name and description of the knowledge base. Forager IQ will then create a new knowledge base for you to add documents to.",
      },
      {
        title: "Uploading Documents to Knowledge Bases",
        body: "To add a document to a knowledge base, click on the 'Document Management' button on the side bar. Select the 'Add Document'. Use the 'Select Files' button to choose the files you'd like to upload or drag and drop them to this area. Your selected files will appear in the list. If you want to remove any files from the list, click on the trash can icon next to the document's name. Select the knowledge base(s) you'd like to add the document(s) to and click the 'Add Document' button. Note: Forager IQ currently supports only PDF files.",
      },
    ];
  }

  function ToggleOpen(index) {
    SetOpenSections({ ...openSections, [index]: !openSections[index] });
  }

  return (
    <div className="helpBar">
      {content.map((item, index) => {
        return (
          <div
            className={"helpBarTopic" + (openSections[index] ? " open" : "")}
            key={index}
            onClick={() => ToggleOpen(index)}
          >
            <div className="helpBarTitle">{item.title}</div>
            <div className="helpBarBody">{item.body}</div>
          </div>
        );
      })}
    </div>
  );
}

export default IQHelp;
