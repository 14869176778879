import ForagerTableColumn from "./ForagerTableColumn";

export default class ReportColumns {
  constructor(fieldCustomizations) {
    this.fieldCustomizations = fieldCustomizations;
  }

  GetUserColumns() {
    return [
      new ForagerTableColumn({
        header: this.fieldCustomizations?.general.user.name,
        propertyPathArray: ["name"],
        sortable: true,
        stretch: true,
        isDefaultSort: true,
        defaultSortDescending: false,
      }),
    ];
  }

  GetScheduleItemColumns(type, includeStartEndDates = true) {
    let columns = [
      new ForagerTableColumn({
        header: this.fieldCustomizations.general.job.name,
        ValueFunction: (item) => {
          return item.job?.name ?? item.name;
        },
        sortable: true,
        stretch: true,
      }),
    ];
    if (type === "task") {
      columns.push(
        new ForagerTableColumn({
          header: this.fieldCustomizations.general.task.name,
          ValueFunction: (item) => {
            return item.name;
          },
          sortable: true,
          stretch: true,
        })
      );
    }
    if (includeStartEndDates) {
      columns.push(
        new ForagerTableColumn({
          header: "Start Date",
          ValueFunction: (item) => {
            return new Date(item.startDate).toLocaleDateString();
          },
          sortable: true,
          sortType: "date",
        })
      );
      columns.push(
        new ForagerTableColumn({
          header: "End Date",
          ValueFunction: (item) => {
            return new Date(item.endDate).toLocaleDateString();
          },
          sortable: true,
          sortType: "date",
        })
      );
    }
    return columns;
  }

  GetTimeEntryColumns() {
    return [
      new ForagerTableColumn({
        header: this.fieldCustomizations.general.job.name,
        ValueFunction: (item) => {
          if (item.scheduleItem != null) {
            if (item.scheduleItem.scheduleItemTypeId === 1) {
              return item.scheduleItem.name;
            }
            if (item.scheduleItem.job.name != null) {
              return item.scheduleItem.job.name;
            }
          }
          if (item.client != null) {
            return "Ticket: " + item.client.name;
          }
          return "(none)";
        },
        sortable: true,
        stretch: true,
      }),
      new ForagerTableColumn({
        header: this.fieldCustomizations.general.task.name,
        ValueFunction: (item) => {
          if (
            item.scheduleItem != null &&
            item.scheduleItem.scheduleItemTypeId === 2
          ) {
            return item.scheduleItem.name;
          }
          if (item.client != null) {
            return item.notes;
          }
          return "(none)";
        },
        sortable: true,
        stretch: true,
      }),
      new ForagerTableColumn({
        header: "Start Time",
        ValueFunction: (item) => {
          return new Date(item.startTime).toLocaleString();
        },
        sortable: true,
        sortType: "date",
      }),
      new ForagerTableColumn({
        header: "End Time",
        ValueFunction: (item) => {
          return new Date(item.endTime).toLocaleString();
        },
        sortable: true,
        sortType: "date",
      }),
    ];
  }
}
