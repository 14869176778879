import React, { useEffect, useRef } from "react";

function AutosizedTextArea(props) {
  const textAreaRef = useRef(null);

  useEffect(() => {
    if (textAreaRef && textAreaRef.current) {
      textAreaRef.current.style.height = "0px";
      let scrollHeight = textAreaRef.current.scrollHeight;
      if (scrollHeight < props.minHeight) {
        scrollHeight = props.minHeight;
      }
      textAreaRef.current.style.height = scrollHeight + "px";
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textAreaRef.current, props.value]);

  useEffect(() => {
    if (props.autoFocus && textAreaRef.current != null) {
      textAreaRef.current.focus();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textAreaRef.current, props.autoFocus]);

  return (
    <textarea
      ref={textAreaRef}
      className={props.className}
      value={props.value}
      onChange={props.onChange}
      placeholder={props.placeholder}
      disabled={props.disabled}
      spellCheck={props.spellCheck}
      id={props.id}
    />
  );
}

export default AutosizedTextArea;
