import React, { useState, useRef } from "react";
import HQLogoSVG from "../../Generic/HQLogoSVG";
import Menu from "./Menu/Menu";

function Sidebar(props) {
  const [menuOpen, SetMenuOpen] = useState(false);
  const currentMenuContainer = useRef(null);
  const currentMenuContainerInner = useRef(null);

  function CloseMenuOnClickBackground(e) {
    if (
      e.target === currentMenuContainer.current ||
      e.target === currentMenuContainerInner.current
    ) {
      SetMenuOpen(false);
    }
  }

  function ToggleMenu() {
    SetMenuOpen(!menuOpen);
  }

  function ToggleIQOpen(value, bypassClosingMenu = false) {
    props.ToggleIQOpen(value);
    if (bypassClosingMenu) return;
    SetMenuOpen(false);
  }

  return (
    <div className="mainScreenSideContainer">
      {menuOpen ? (
        <div
          className="menuDimmerContainer"
          onClick={CloseMenuOnClickBackground}
          ref={currentMenuContainer}
        >
          <div className="menuDimmer" ref={currentMenuContainerInner}>
            <Menu
              ImpersonateUser={props.ImpersonateUser}
              impersonating={props.impersonating}
              Logout={props.Logout}
              jobsForFilter={props.jobsForFilter}
              clientsForFilter={props.clientsForFilter}
              usersForFilter={props.usersForFilter}
              tasksForFilter={props.tasksForFilter}
              ToggleTimeClockModal={props.ToggleTimeClockModal}
              ToggleIQOpen={ToggleIQOpen}
              CloseModal={ToggleMenu}
            />
          </div>
        </div>
      ) : null}
      <div className="mainScreenSidebarContainer" onClick={ToggleMenu}>
        <div className={"mainScreenIcon App-logo"}>
          {/* {props.iqOpen ? (
            <IQLogoSVG full={false} />
          ) : ( */}
          <HQLogoSVG full={false} />
          {/* )} */}
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
