class ForagerTableColumn {
  constructor({
    header = "",
    property = "",
    propertyPathArray = [],
    ValueFunction = null,
    CellOnClick = null,
    ValueOnClick = null,
    sortable = false,
    SortFunction = null,
    sortPath = null,
    sortValueFunction = null,
    sortType = "string",
    isDefaultSort = false,
    defaultSortDescending = true,
    width = -1,
    autoWidth = 10,
    stretch = false,
    textAlignment = "center",
    applyAlignmentToHeader = false,
    fontSize = "1rem",
    truncateLongTextLength = 0,
    tooltipProperty = null,
    tooltipPathArray = [],
    TooltipFunction = null,
    hideOnMobile = false,
    cellClass = null,
    isTotaled = false,
    totalRowHeader = false,
    HeaderOnClick = null,
    maxWidth = null,
  } = {}) {
    this.header = header;
    this.property = property;
    this.propertyPathArray = propertyPathArray;
    this.ValueFunction = ValueFunction;
    this.CellOnClick = CellOnClick;
    this.ValueOnClick = ValueOnClick;
    this.sortable = sortable;
    this.SortFunction = SortFunction ?? ((input) => {});
    this.sortPath = sortPath;
    this.sortValueFunction = sortValueFunction;
    this.sortType = sortType;
    this.isDefaultSort = isDefaultSort;
    this.defaultSortDescending = defaultSortDescending;
    this.width = width;
    this.autoWidth = autoWidth;
    this.stretch = stretch;
    this.textAlignment = textAlignment;
    this.applyAlignmentToHeader = applyAlignmentToHeader;
    this.fontSize = fontSize;
    this.truncateLongTextLength = truncateLongTextLength;
    this.tooltipProperty = tooltipProperty;
    this.tooltipPathArray = tooltipPathArray;
    this.TooltipFunction = TooltipFunction;
    this.hideOnMobile = hideOnMobile;
    this.cellClass = cellClass;
    this.isTotaled = isTotaled;
    this.totalRowHeader = totalRowHeader;
    this.HeaderOnClick = HeaderOnClick;
    this.maxWidth = maxWidth;
  }

  GetValue(item, forSort = false, truncatedTo = 0) {
    if (forSort && (this.sortPath != null || this.sortValueFunction != null)) {
      let value = item;
      if (this.sortPath != null) {
        for (let i = 0; i < this.sortPath.length; i++) {
          value = value[this.sortPath[i]];
        }
      }
      if (this.sortValueFunction != null) {
        return this.sortValueFunction(value);
      }
      return value;
    }
    if (this.ValueFunction != null) {
      return this.ValueFunction(item);
    }
    let value = "";
    if (this.propertyPathArray.length > 0) {
      let currentValue = item;
      for (let i = 0; i < this.propertyPathArray.length; i++) {
        currentValue = currentValue[this.propertyPathArray[i]];
      }
      value = currentValue;
    } else {
      value = item[this.property];
    }
    if (truncatedTo > 0 && value.length > this.truncateLongTextLength) {
      return value.substring(0, this.truncateLongTextLength) + "...";
    }
    return value;
  }

  GetToolTip(item) {
    if (item == null) {
      return null;
    }
    if (this.TooltipFunction != null) {
      return this.TooltipFunction(item);
    }
    if (this.tooltipPathArray.length > 0) {
      let value = item;
      for (let i = 0; i < this.tooltipPathArray.length; i++) {
        value = value[this.tooltipPathArray[i]];
      }
      return value;
    }
    if (this.tooltipProperty == null) {
      return null;
    }
    return item[this.tooltipProperty];
  }

  SetAutoWidth(items) {
    let maxWidth = this.header.length + 1;
    for (let i = 0; i < items.length; i++) {
      let value = this.GetValue(items[i]);
      if (value != null) {
        let valueWidth = value.toString().length;
        if (valueWidth > maxWidth) {
          maxWidth = valueWidth;
        }
      }
    }
    this.autoWidth = maxWidth;
  }

  GetWidth() {
    if (this.stretch) {
      if (this.maxWidth != null) {
        return "minmax(1fr, " + this.maxWidth + ")";
      }
      return "1fr";
    }
    if (this.width > 0) {
      return this.width * 0.8 + "rem";
    }
    return this.autoWidth * 0.8 + "rem";
  }

  GetAlignment(isHeader = false) {
    if (isHeader && !this.applyAlignmentToHeader) {
      return "center";
    }
    return this.textAlignment;
  }
}

export default ForagerTableColumn;
