import React, { useEffect, useState } from "react";
import ForagerTableItem from "./ForagerTableItem";

function ForagerTable(props) {
  const [sortedItems, SetSortedItems] = useState([]);
  const [sortColumn, SetSortColumn] = useState(null);
  const [sortDescending, SetSortDescending] = useState(true);
  const [columns, SetColumns] = useState([]);
  const [columnWidths, SetColumnWidths] = useState([]);
  const [forceExpand, SetForceExpand] = useState(0);

  function GetColumnWidths() {
    SetColumnWidths(
      props.columns
        .map((column) => {
          return column.GetWidth();
        })
        .join(" ")
    );
  }

  function ChangeSort(column) {
    if (column.header === sortColumn?.header) {
      SetSortDescending(!sortDescending);
    } else {
      SetSortColumn(column);
      SetSortDescending(column.sortType !== "string");
    }
  }

  function SetAutoWidths() {
    let columnsCopy = [...props.columns];
    for (let i = 0; i < columnsCopy.length; i++) {
      columnsCopy[i].SetAutoWidth(props.items);
    }
    SetColumns(columnsCopy);
  }

  function SortItems() {
    if (sortColumn == null) {
      for (let i = 0; i < props.columns.length; i++) {
        if (props.columns[i].sortable && props.columns[i].isDefaultSort) {
          if (props.columns[i].defaultSortDescending !== sortDescending) {
            SetSortDescending(props.columns[i].defaultSortDescending);
            return;
          }
          SetSortColumn(props.columns[i]);
          return;
        }
      }
      SetSortedItems(props.items);
      return;
    }
    let sorted = [...props.items];
    sorted.sort((a, b) => {
      let aValue = sortColumn.GetValue(a, true);
      let bValue = sortColumn.GetValue(b, true);
      switch (sortColumn.sortType) {
        case "number":
          aValue = parseFloat(aValue);
          bValue = parseFloat(bValue);
          break;
        case "date":
          aValue = new Date(aValue);
          bValue = new Date(bValue);
          break;
        default:
          break;
      }
      if (aValue < bValue) {
        return sortDescending ? 1 : -1;
      }
      if (aValue > bValue) {
        return sortDescending ? -1 : 1;
      }
      return 0;
    });
    SetSortedItems(sorted);
  }

  function GetSubtableItems(item) {
    if (
      props.subTableColumns == null ||
      (props.SubTableItemsFunction == null &&
        props.subTableItemsPathArray == null &&
        props.subTableItemsProperty == null)
    ) {
      return [];
    }
    if (props.SubTableItemsFunction) {
      return props.SubTableItemsFunction(item);
    }
    if (props.subTableItemsPathArray != null) {
      let value = item;
      for (let i = 0; i < props.subTableItemsPathArray.length; i++) {
        value = value[props.subTableItemsPathArray[i]];
      }
      return value;
    }
    return item[props.subTableItemsProperty];
  }

  function GetTotalRowData() {
    let totalRow = {};
    for (let i = 0; i < columns.length; i++) {
      totalRow[columns[i].property] = "";
      if (columns[i].totalRowHeader) {
        totalRow[columns[i].property] = "Total";
        continue;
      }
      if (columns[i].isTotaled) {
        let total = 0;
        for (let j = 0; j < props.items.length; j++) {
          total += columns[i].GetValue(props.items[j]);
        }
        totalRow[columns[i].property] = total;
      }
    }
    return totalRow;
  }
  function ExpandAll(value, headerOnly = false) {
    SetForceExpand((value ? 1 : -1) * (headerOnly ? 2 : 1));
    let timeout = setTimeout(() => {
      SetForceExpand(0);
      clearTimeout(timeout);
    }, 100);
  }

  useEffect(() => {
    if (Array.isArray(props.columns)) {
      SetColumns(props.columns);
    }

    // eslint-disable-next-line
  }, [props.columns]);

  useEffect(() => {
    if (Array.isArray(props.items)) {
      SortItems();
    }

    // eslint-disable-next-line
  }, [props.items, sortColumn, sortDescending]);

  useEffect(() => {
    if (Array.isArray(props.items) && props.columns.length > 0) {
      SetAutoWidths();
    }

    // eslint-disable-next-line
  }, [props.items, props.columns]);

  useEffect(() => {
    if (props.triggerAllExpanded) {
      ExpandAll(true);
    }
    // eslint-disable-next-line
  }, [props.triggerAllExpanded]);

  useEffect(() => {
    GetColumnWidths();

    // eslint-disable-next-line
  }, [columns]);

  useEffect(() => {
    let defaultSortColumn = props.columns.find(
      (column) => column.isDefaultSort
    );
    if (defaultSortColumn?.sortType === "string") {
      SetSortDescending(false);
    }

    // eslint-disable-next-line
  }, [props.columns]);

  return (
    <div
      className={
        "foragerTable" +
        (props.isSubTable ? " foragerSubTable" : "") +
        (props.className ? " " + props.className : "")
      }
    >
      {!props.hideHeader ? (
        <ForagerTableItem
          columns={columns}
          isHeader={true}
          columnWidths={columnWidths}
          ChangeSort={ChangeSort}
          currentSortColumn={sortColumn}
          currentSortDescending={sortDescending}
          subTableColumns={props.subTableColumns}
          ExpandAll={ExpandAll}
          forceExpand={forceExpand}
        />
      ) : null}
      {sortedItems.length === 0 ? (
        <div className="foragerTableItem noItems">
          <div className="foragerTableItemCell noItems">
            No items to display
          </div>
        </div>
      ) : null}
      {sortedItems.map((item, index) => {
        return (
          <ForagerTableItem
            columns={columns}
            item={item}
            key={index}
            columnWidths={columnWidths}
            subTableColumns={props.subTableColumns}
            subTableItems={GetSubtableItems(item)}
            highlighted={
              props.highlightedId != null
                ? props.highlightedId === item.id
                : false
            }
            onClickRow={props.onClickRow}
            onClickSubRow={props.onClickSubRow}
            ExpandAll={ExpandAll}
            forceExpand={forceExpand}
          />
        );
      })}
      {props.hasTotalRow ? (
        <ForagerTableItem
          columns={columns}
          item={GetTotalRowData()}
          columnWidths={columnWidths}
          isTotalRow={true}
          subTableColumns={props.subTableColumns}
        />
      ) : null}
    </div>
  );
}

export default ForagerTable;
