import React from "react";

function HQLogoSVG(props) {
  return (
    <>
      {props.full ? (
        <svg
          id="FullLogo"
          className="logoSVG"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1556.74 286.3"
        >
          <g>
            <path
              className="logoMainColor"
              d="m0,213.39V2.96h130.41v39.12H42.97v48.9h77.06v37.94H42.97v84.47H0Z"
            />
            <path
              className="logoMainColor"
              d="m143.73,135.74c0-46.53,34.97-80.62,83.58-80.62s83.88,34.08,83.88,80.62-34.97,80.62-83.88,80.62-83.58-33.49-83.58-80.62Zm126.85,0c0-24.9-18.38-42.68-43.27-42.68s-42.68,17.78-42.68,42.68,18.08,42.68,42.68,42.68,43.27-18.08,43.27-42.68Z"
            />
            <path
              className="logoMainColor"
              d="m376.97,78.24c8-14.23,21.93-23.12,46.53-20.15l-.3,36.16c-29.04-3.56-45.35,6.22-45.35,30.53v88.62h-42.09V58.39h34.38l6.82,19.86Z"
            />
            <path
              className="logoMainColor"
              d="m569.61,58.39h35.57v155.01h-42.68v-26.67c-7.41,18.37-27.86,29.64-53.64,29.64-47.12,0-74.98-36.75-74.98-80.91s29.34-80.32,77.06-80.32c19.86,0,40.01,8.89,51.57,26.08v.89l7.11-23.71Zm-7.11,77.36c0-24.6-18.08-42.38-42.68-42.38s-43.27,17.49-43.27,42.38,17.78,42.97,43.27,42.97,42.68-18.08,42.68-42.97Z"
            />
            <path
              className="logoMainColor"
              d="m765.8,58.39h34.38v139c0,53.94-28.75,88.91-84.17,88.91-24.01,0-53.35-7.11-68.17-18.37v-36.16c12.74,7.11,32.9,18.08,59.57,18.08,32.9,0,50.68-16,50.68-52.46v-10.67c-7.41,18.37-27.86,29.64-53.64,29.64-47.12,0-74.98-36.75-74.98-80.91s29.34-80.32,77.06-80.32c19.86,0,40.01,8.89,51.57,26.08v.89l7.7-23.71Zm-7.7,77.36c0-24.6-18.08-42.38-42.68-42.38s-43.27,17.49-43.27,42.38,17.78,42.97,43.27,42.97,42.68-18.08,42.68-42.97Z"
            />
            <path
              className="logoMainColor"
              d="m984.82,149.08h-117.66c2.08,18.97,18.97,32.6,40.6,32.6,18.08,0,31.12-8.6,37.35-20.75l36.75,3.85c-8.3,29.34-38.23,51.57-74.39,51.57-48.02,0-82.69-32.01-82.69-80.02s33.19-81.21,80.02-81.21,81.8,31.71,80.02,93.95Zm-117.96-29.34h75.28c-1.19-18.08-16.01-30.82-37.35-30.82s-36.45,12.75-37.94,30.82Z"
            />
            <path
              className="logoMainColor"
              d="m1050.3,78.24c8-14.23,21.93-23.12,46.53-20.15l-.3,36.16c-29.04-3.56-45.35,6.22-45.35,30.53v88.62h-42.08V58.39h34.38l6.82,19.86Z"
            />
            <path
              className="logoMainColor"
              d="m1258.06,2.96h42.98v210.43h-42.98v-85.36h-90.69v85.36h-42.97V2.96h42.97v87.14h90.69V2.96Z"
            />
          </g>
          <path
            className="logoMainColor"
            d="m1541.24,107.61c0,2.97-.11,5.9-.32,8.78-2.05,28.07-16.32,53.84-38.15,71.6l-10.37,1.27c-14.17,18.89-37.45,25.2-54.1,25.87-1.55.06-3.11.09-4.67.09-29.56,0-54.89-10.35-75.98-31.04-20.89-20.89-31.34-46.42-31.34-76.57s10.44-55.58,31.34-76.28c20.89-20.89,46.22-31.34,75.98-31.34s55.09,10.45,75.98,31.34c21.09,20.69,31.63,46.12,31.63,76.28Zm-111.77-71.41c-34.04,2.35-61.32,30.4-62.8,64.49-.91,21.09,7.83,40.16,22.18,53.16l33.98,33.97c6.32,6.32,16.58,6.32,22.9,0l33.98-33.97c13.66-12.38,22.24-30.26,22.24-50.15,0-38.96-32.92-70.23-72.47-67.5Zm35.08,71.47c-1.71,13.61-12.7,24.6-26.31,26.31-19.97,2.52-36.77-14.28-34.25-34.25,1.72-13.61,12.7-24.6,26.31-26.31,19.97-2.52,36.77,14.28,34.25,34.25Z"
          />
          <path
            className="logoMainColor"
            d="m1498.88,146.72c-10.74,10.73-16.42,16.41-27.15,27.15,33.92,8.15,18.88,50.96,52.8,59.11,10.74-10.74,21.47-21.47,32.21-32.21-33.92-8.15-23.94-45.9-57.86-54.05Z"
          />
        </svg>
      ) : (
        <svg
          version="1.1"
          id="CircleLogo"
          className="logoSVG icon"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 487.62 487.62"
        >
          <g>
            <g>
              <circle
                className="outerCircle"
                cx="243.81"
                cy="243.81"
                r="243.81"
              />
              <circle
                className="innerCircle"
                cx="243.81"
                cy="243.81"
                r="147.95"
              />
              <path
                className="outerWedge"
                d="M243.81,95.86c26.95,0,52.22,7.21,73.99,19.8l47.93-83.02C329.86,11.88,288.23,0,243.81,0
			c-44.42,0-86.06,11.88-121.92,32.64l47.93,83.03C191.59,103.07,216.86,95.86,243.81,95.86z"
              />
              <path
                className="innerWedge"
                d="M317.78,115.69c-21.77-12.59-47.01-19.84-73.97-19.84s-52.2,7.24-73.97,19.84l73.97,128.12L317.78,115.69z"
              />
            </g>
          </g>
        </svg>
      )}
    </>
  );
}

export default HQLogoSVG;
