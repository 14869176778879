import React, { useEffect, useState } from "react";
import DropDownMenu from "../../../../../../Generic/DropDownMenu";
import * as Utilities from "../../../../../../Utilities";
import { useSiteContext } from "../../../../../../Context";
import ForagerButton from "../../../../../../Generic/ForagerButton";
import { Icon } from "@iconify/react";
import Modal from "../../../../../../Generic/Modal";
import TimeDashboardModal from "./TimeDashboard/TimeDashboardModal";
import ForagerTable from "../../../../../../Generic/ForagerTable";
import ForagerReport, {
  ForagerReportInfo,
} from "../../../../../../Classes/Report";

function ReportsModal(props) {
  const [filteringClientBy, SetFilteringClientBy] = useState(null);
  const [filteringJobBy, SetFilteringJobBy] = useState(null);
  const [filteringTaskBy, SetFilteringTaskBy] = useState(null);
  const [dateRangeStart, SetDateRangeStart] = useState("");
  const [dateRangeEnd, SetDateRangeEnd] = useState("");
  const [reportCategory, SetReportCategory] = useState(0);
  const [reportType, SetReportType] = useState(0);
  const [reportData, SetReportData] = useState([]);
  const [filteredReportsData, SetFilteredReportsData] = useState([]);
  const [dateRangeType, SetDateRangeType] = useState(1);
  const [monthEndDate, SetMonthEndDate] = useState(0);
  const [monthEndMonth, SetMonthEndMonth] = useState(new Date().getUTCMonth());
  const [monthEndYear, SetMonthEndYear] = useState(new Date().getUTCFullYear());
  const [report, SetReport] = useState(null);
  const [newReport, SetNewReport] = useState(null);
  const [showMenu, SetShowMenu] = useState(true);
  const [loadingReport, SetLoadingReport] = useState(false);
  const [showTimeDashboard, SetShowTimeDashboard] = useState(false);
  const { loggedInUser, GetData, fieldCustomizations, AddAlert } =
    useSiteContext();

  const monthEndDates = ForagerReportInfo.GetMonthEnds(
    monthEndMonth,
    monthEndYear
  );
  const reportCategories =
    ForagerReportInfo.GetReportCategories(fieldCustomizations);

  function ShowMenu() {
    SetShowMenu(!showMenu);
  }

  function ChangeYear(value) {
    SetMonthEndYear(value);
  }

  function ChangeMonth(value) {
    SetMonthEndMonth(value);
  }

  function ChangeMonthEndDate(value) {
    SetMonthEndDate(value);
  }

  function CalculateDates() {
    let endDate = new Date();
    switch (dateRangeType) {
      case 1:
        let startDate = new Date();
        if (dateRangeStart !== "") {
          startDate = new Date(dateRangeStart);
        }
        if (dateRangeEnd !== "") {
          endDate = new Date(dateRangeEnd);
        }
        SetDateRangeStart(Utilities.FormatDate(startDate));
        break;
      case 2:
        if (dateRangeEnd !== "") {
          endDate = new Date(dateRangeEnd);
        }
        let newStartDate = null;
        if (monthEndDate === 0) {
          endDate = new Date(monthEndYear, monthEndMonth + 1, 0);
          newStartDate = new Date(endDate.getFullYear(), endDate.getMonth(), 1);
        } else {
          endDate = new Date(monthEndYear, monthEndMonth, monthEndDate);
          newStartDate = new Date(
            endDate.getFullYear(),
            endDate.getMonth() - 1,
            endDate.getDate() + 1
          );
        }
        SetDateRangeStart(Utilities.FormatDate(newStartDate));
        break;
      default:
        break;
    }
    SetDateRangeEnd(Utilities.FormatDate(endDate));
  }

  function ChangeDateRangeType(type) {
    SetDateRangeType(type);
  }

  function FilterByClient(client) {
    SetFilteringClientBy(client);
  }

  function FilterByJob(job) {
    SetFilteringJobBy(job);
  }

  function FilterByTask(task) {
    SetFilteringTaskBy(task);
  }

  function ChangeDateRangeStart(e) {
    SetDateRangeStart(e.target.value);
  }

  function ChangeDateRangeEnd(e) {
    SetDateRangeEnd(e.target.value);
  }

  function ChangeReportCategory(category) {
    SetReportType(0);
    SetReportCategory(category);
  }

  function ChangeReportType(type) {
    SetReportType(type);
  }

  async function GetDataForReports(type, SetFunction) {
    let newEndDate = new Date(dateRangeEnd);
    newEndDate.setDate(newEndDate.getDate() + 1);
    newEndDate = Utilities.FormatDate(newEndDate);
    let params = {
      customer: loggedInUser.customer.id,
      getReportOnlyFields: true,
      fullDetails: true,
      startDate: dateRangeStart,
      endDate: newEndDate,
    };
    if (reportCategory === 1) {
      params.includeTimeEntries = true;
    }
    if (reportType === 2) {
      params.DateRangeOnSublists = "true";
    }
    SetShowMenu(false);
    SetLoadingReport(true);
    let data = await GetData(type, params);
    if (data instanceof Array) {
      SetReportData(data);
    } else {
      AddAlert("error", "Error getting data for report");
    }
    SetLoadingReport(false);
  }

  function CreateReportData() {
    SetReportData([]);
    ResetReport();
    let newTempReport = new ForagerReport({
      category: reportCategory,
      type: reportType,
      startDate: dateRangeStart,
      endDate: dateRangeEnd,
      dateRangeType: dateRangeType,
      fieldCustomizations: fieldCustomizations,
    });
    if (reportCategory === 2) {
      GetDataForReports("Job");
    }
    if (reportCategory === 3) {
      GetDataForReports("Task");
    }
    if (reportCategory === 1 && reportType !== 2) {
      GetDataForReports("TimeEntry");
    }
    if (reportCategory === 1 && reportType === 2) {
      GetDataForReports("User");
    }
    SetNewReport(newTempReport);
  }

  function FilterReportsData() {
    let newFilteredReportsData = reportData.slice();
    if (filteringClientBy != null) {
      newFilteredReportsData = newFilteredReportsData.filter((report) => {
        return (
          (report.job != null &&
            report.job.client != null &&
            report.job.client.id === filteringClientBy) ||
          (report.task != null &&
            report.task.client != null &&
            report.task.client.id === filteringClientBy)
        );
      });
    }
    if (filteringJobBy != null) {
      newFilteredReportsData = newFilteredReportsData.filter((report) => {
        if (reportCategory === 2) {
          return report.id === filteringJobBy;
        }
        if (reportCategory === 3) {
          return report.job != null && report.job.id === filteringJobBy;
        }
        if (reportCategory === 1) {
          if (report.job != null && report.job.id != null) {
            return report.scheduleItem.id === filteringJobBy;
          }
          return (
            report.scheduleItem != null &&
            report.scheduleItem.job != null &&
            report.scheduleItem.job.id != null &&
            report.scheduleItem.job.id === filteringJobBy
          );
        }
        return null;
      });
    }
    if (
      filteringTaskBy != null &&
      (reportCategory === 1 || reportCategory === 3)
    ) {
      newFilteredReportsData = newFilteredReportsData.filter((report) => {
        if (reportCategory === fieldCustomizations.general.task.name) {
          return (
            report.scheduleItem != null &&
            report.scheduleItem.id != null &&
            report.scheduleItem.id === filteringTaskBy
          );
        }
        if (reportCategory === 1) {
          return (
            report.scheduleItem != null &&
            report.scheduleItem.id != null &&
            report.scheduleItem.id === filteringTaskBy
          );
        }
        return null;
      });
    }
    if (reportCategory === 1 && reportType === 1) {
      let groupedByTask = [];
      newFilteredReportsData.forEach((reportItem) => {
        if (reportItem.scheduleItem?.id != null) {
          let existingTask = groupedByTask.find(
            (task) =>
              task.id === reportItem.scheduleItem.id &&
              task.user?.id === reportItem.user.id
          );
          if (existingTask == null) {
            existingTask = reportItem.scheduleItem;
            existingTask.user = reportItem.user;
            existingTask.timeEntries = [];
            groupedByTask.push(existingTask);
          }
          existingTask.timeEntries.push(reportItem);
        }
      });
      newFilteredReportsData = groupedByTask;
    }
    SetFilteredReportsData(newFilteredReportsData);
  }

  function ResetReport() {
    let reportObject = {
      category: reportCategory,
      categoryName: "",
      reportType: reportType,
      reportTypeName: "",
      dateRangeType: dateRangeType,
      dateRangeStart: dateRangeStart,
      dateRangeEnd: dateRangeEnd,
      data: [],
    };

    if (reportCategory > 0) {
      let currentCategory = reportCategories.find(
        (i) => i.id === reportCategory
      );
      if (currentCategory != null) {
        reportObject.categoryName = currentCategory.name;
        let currentReportType = currentCategory.types.find(
          (i) => i.id === reportType
        );
        if (currentReportType != null) {
          reportObject.reportTypeName = currentReportType.name;
        }
      }
    }

    SetReport(reportObject);
  }

  function ToggleTimeDashboardModal() {
    SetShowTimeDashboard(!showTimeDashboard);
  }

  useEffect(() => {
    if (dateRangeType === 2) {
      CalculateDates();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monthEndDate, monthEndMonth, monthEndYear]);

  useEffect(() => {
    FilterReportsData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteringClientBy, filteringJobBy, filteringTaskBy, reportData]);

  useEffect(() => {
    SetReport({
      ...report,
      data: filteredReportsData,
    });
    let newTempReport = newReport;
    if (newTempReport != null) {
      newTempReport.data = filteredReportsData;
    }
    SetNewReport(newTempReport);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredReportsData]);

  useEffect(() => {
    CalculateDates();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRangeType]);

  useEffect(() => {
    ResetReport();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="reportsModalContainer">
        <div className="relativeAnchor">
          <div
            className={
              "modalBodySection reportOptions" + (showMenu ? " showMenu" : "")
            }
          >
            <div
              className={"settingsContainer" + (showMenu ? "" : " menuHidden")}
            >
              <div
                className={"settingLabel reports" + (showMenu ? " main" : "")}
                onClick={ShowMenu}
              >
                <Icon className="menuButton" icon={"ci:hamburger-md"} />
                {showMenu ? "Options" : ""}
              </div>
              {showMenu ? (
                <>
                  <div className="setting required">
                    <div className="relativeAnchor">
                      <Icon icon="mdi:required" className="requiredIndicator" />
                    </div>
                    <DropDownMenu
                      className="modalDropDown reports"
                      noneSelectedLabel="(Report Category)"
                      options={reportCategories}
                      value={reportCategory}
                      onChange={ChangeReportCategory}
                      showBlank={false}
                    />
                  </div>
                  <div className="setting required">
                    <div className="relativeAnchor">
                      <Icon icon="mdi:required" className="requiredIndicator" />
                    </div>
                    <DropDownMenu
                      className="modalDropDown reports"
                      noneSelectedLabel="(Report Type)"
                      options={
                        reportCategories[reportCategory - 1] != null &&
                        reportCategories[reportCategory - 1].types != null
                          ? reportCategories[reportCategory - 1].types
                          : []
                      }
                      showBlank={false}
                      value={reportType}
                      onChange={ChangeReportType}
                      disabled={reportCategory === 0}
                    />
                  </div>
                  <div className="setting">
                    <DropDownMenu
                      className="modalDropDown reports"
                      noneSelectedLabel="(Range Type)"
                      options={ForagerReportInfo.dateRangeTypes}
                      value={dateRangeType}
                      onChange={ChangeDateRangeType}
                      showBlank={false}
                    />
                  </div>
                  {dateRangeType === 1 ? (
                    <>
                      <div className="setting">
                        <div className="settingLabel reports">Date Range</div>
                      </div>
                      <div className="setting">
                        <div className="reportsModalBodyFilterItemDate">
                          <input
                            type="date"
                            onChange={ChangeDateRangeStart}
                            value={dateRangeStart}
                          />
                          <div>to</div>
                          <input
                            type="date"
                            onChange={ChangeDateRangeEnd}
                            value={dateRangeEnd}
                          />
                        </div>
                      </div>
                    </>
                  ) : null}
                  {dateRangeType === 2 ? (
                    <>
                      <div className="setting">
                        <div className="settingLabel reports">Month End</div>
                      </div>
                      <div className="setting">
                        <DropDownMenu
                          className="modalDropDown reports"
                          onChange={ChangeYear}
                          options={ForagerReportInfo.years}
                          showBlank={false}
                          value={monthEndYear}
                          dontSort={true}
                        />
                      </div>
                      <div className="setting">
                        <DropDownMenu
                          className="modalDropDown reports"
                          onChange={ChangeMonth}
                          options={ForagerReportInfo.months}
                          showBlank={false}
                          value={monthEndMonth}
                          dontSort={true}
                        />
                      </div>
                      <div className="setting">
                        <DropDownMenu
                          className="modalDropDown reports"
                          onChange={ChangeMonthEndDate}
                          options={monthEndDates}
                          showBlank={false}
                          value={monthEndDate}
                          dontSort={true}
                        />
                      </div>
                    </>
                  ) : null}
                  <div className="setting">
                    <div className="settingLabel reports">Filters</div>
                  </div>
                  <div className="setting">
                    <DropDownMenu
                      className="modalDropDown reports"
                      options={props.clientsForFilter}
                      onChange={FilterByClient}
                      value={filteringClientBy}
                      showBlank={true}
                      noneSelectedLabel={
                        "All " + fieldCustomizations.general.client.pluralName
                      }
                    />
                  </div>
                  <div className="setting">
                    <DropDownMenu
                      className="modalDropDown reports"
                      options={props.jobsForFilter}
                      onChange={FilterByJob}
                      value={filteringJobBy}
                      showBlank={true}
                      noneSelectedLabel={
                        "All " + fieldCustomizations.general.job.pluralName
                      }
                    />
                  </div>
                  <div className="setting">
                    <DropDownMenu
                      className="modalDropDown reports"
                      options={props.tasksForFilter}
                      onChange={FilterByTask}
                      value={filteringTaskBy}
                      showBlank={true}
                      noneSelectedLabel={
                        "All " + fieldCustomizations.general.task.pluralName
                      }
                    />
                  </div>
                  <div className="setting buttons">
                    <ForagerButton
                      reversedColors={true}
                      onClick={CreateReportData}
                      disabled={
                        reportCategory === 0 ||
                        reportType === 0 ||
                        loadingReport
                      }
                    >
                      Build Reports
                    </ForagerButton>
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </div>
        <div className="modalHeader reports">
          <ForagerButton
            onClick={ToggleTimeDashboardModal}
            reversedColors={true}
            lessVerticalPadding={true}
          >
            Time Dashboard
          </ForagerButton>
          <div>Reports</div>
        </div>
        <div className="modalBody reports">
          {newReport != null ? (
            <ForagerTable
              columns={newReport.GetReportColumns()}
              subTableItemsProperty={newReport.GetSubTablePathArray()}
              subTableColumns={newReport.GetSubTableColumns(
                fieldCustomizations
              )}
              items={filteredReportsData}
            />
          ) : null}
        </div>
      </div>
      {showTimeDashboard ? (
        <Modal
          modificationClass="noPaddingAlternate"
          CloseModal={ToggleTimeDashboardModal}
        >
          <TimeDashboardModal />
        </Modal>
      ) : null}
    </>
  );
}

export default ReportsModal;
