import React from "react";
import ForagerButton from "./ForagerButton";
import * as Utilities from "../Utilities";

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      className: props.className || "",
      isDev: process.env.NODE_ENV === "development",
      errorInfo: [],
      loggedInUser: props.loggedInUser,
      AddData: props.AddData,
    };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    let newStack = error.stack.toString();
    let newSplitStack = newStack.split("\n");

    return { hasError: true, errorInfo: newSplitStack };
  }

  componentDidCatch(error, info) {
    // console.log(error, info.componentStack);

    if (!this.state.isDev) {
      let errorData = new FormData();
      if (this.state.loggedInUser != null) {
        errorData.append("customer", this.state.loggedInUser.customer.id);
        errorData.append("customerId", this.state.loggedInUser.customer.id);
        errorData.append("userEmail", this.state.loggedInUser.email);
      } else {
        errorData.append("userEmail", "none");
      }
      errorData.append("error", error);
      errorData.append("message", info.componentStack.toString().trim());
      errorData.append("url", window.location.href);
      this.state.AddData("ErrorLog", errorData);
    }
  }

  render() {
    if (this.state.hasError) {
      if (this.state.isDev) {
        return (
          <div className={"errorModal " + this.state.className}>
            <div>An error happened during your request.</div>
            <div className="errorInfo">
              {this.state.errorInfo.map((line, index) => (
                <div className="stackLine" key={index}>
                  {line}
                </div>
              ))}
            </div>
          </div>
        );
      }
      return (
        <div className={"errorModal " + this.state.className}>
          <div>An error happened during your request.</div>
          <div>Please try refreshing the page.</div>
          <ForagerButton onClick={Utilities.RefreshPage}>
            Refresh Page
          </ForagerButton>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div>If this does not correct the issue,</div>
          <ForagerButton onClick={Utilities.EmailSupport}>
            Contact Support
          </ForagerButton>
        </div>
      );
    }

    return this.props.children;
  }
}
