import React, { useState, useEffect } from "react";
import GanttItemInformation from "./GanttItemInformation";
import axios from "axios";
import * as Constants from "../../../../../Constants";
import * as Utilities from "../../../../../Utilities";
import { useSiteContext } from "../../../../../Context";
import { useNavigate } from "react-router-dom";

function GanttItemModal(props) {
  const [editMode, SetEditMode] = useState(false);
  const [readyForEditCheck, SetReadyForEditCheck] = useState(false);
  const [name, SetName] = useState("");
  const [parentId, SetParentId] = useState(0);
  const [jobName, SetJobName] = useState("");
  const [clientId, SetClientId] = useState(0);
  const [clientName, SetClientName] = useState("");
  const [statusId, SetStatusId] = useState(0);
  const [statusName, SetStatusName] = useState("");
  const [priority, SetPriority] = useState(0);
  const [startDate, SetStartDate] = useState(
    new Date().toISOString().substring(0, 10)
  );
  const [endDate, SetEndDate] = useState(
    new Date().toISOString().substring(0, 10)
  );
  const [estimatedHours, SetEstimatedHours] = useState(0);
  const [projectManagerId, SetProjectManagerId] = useState(0);
  const [projectManagerName, SetProjectManagerName] = useState("");
  const [assignedUsers, SetAssignedUsers] = useState([]);
  const [description, SetDescription] = useState("");
  const [customFieldValues, SetCustomFieldValues] = useState([]);
  const [currentlySaving, SetCurrentlySaving] = useState(false);
  const [updateComment, SetUpdateComment] = useState("");
  const {
    AddAlert,
    AddData,
    loggedInUser,
    SendSignal,
    refreshSignal,
    statuses,
    customerSettings,
    fieldCustomizations,
  } = useSiteContext();
  const navigate = useNavigate();

  async function ChangeEditMode() {
    SetEditMode(!editMode);
  }

  function ChangeName(e) {
    SetName(e.target.value);
  }

  function ChangeJob(value) {
    SetParentId(value);
    let job = props.jobs.find((j) => j.id === value);
    if (job != null) {
      if (job.client != null) {
        if (job.clientId !== 0) {
          SetClientId(job.client.id);
          SetClientName(job.client.name);
          return;
        }
        SetClientId(0);
        SetClientName(
          `No ${fieldCustomizations.general.client.name} Assigned To ${fieldCustomizations.general.job.name}`
        );
      }
    }
  }

  function ChangeClient(value) {
    SetClientId(value);
  }

  function ChangeStatus(value) {
    SetStatusId(value);
  }

  function ChangePriority(e) {
    SetPriority(e.target.value);
  }

  function ChangeStartDate(e) {
    SetStartDate(new Date(e.target.value).toISOString().substring(0, 10));
    if (new Date(e.target.value) > new Date(endDate)) {
      SetEndDate(new Date(e.target.value).toISOString().substring(0, 10));
    }
  }

  function ChangeEndDate(e) {
    SetEndDate(new Date(e.target.value).toISOString().substring(0, 10));
  }

  function ChangeEstimatedHours(e) {
    let regex = "^[\\d]*\\.?\\d?\\d?$";
    if (RegExp(regex).test(e.target.value)) {
      SetEstimatedHours(e.target.value);
    }
  }

  function ChangeProjectManager(value) {
    SetProjectManagerId(value);
  }

  function ChangeAssignedUsers(newAssignedUsers) {
    SetAssignedUsers(newAssignedUsers);
  }

  function ChangeDescription(index, value) {
    SetDescription(value);
  }

  function ChangeCustomFieldValues(fieldId, event) {
    let newCustomFieldValues = customFieldValues.slice();
    let newCustomFieldValue = newCustomFieldValues.find(
      (field) => field.fieldId === fieldId
    );
    switch (event.target.type) {
      case "checkbox":
        newCustomFieldValue.value = event.target.checked;
        break;
      default:
        newCustomFieldValue.value = event.target.value;
        break;
    }
    SetCustomFieldValues(newCustomFieldValues);
  }

  function ChangeUpdateComment(e) {
    SetUpdateComment(e.target.value);
  }

  function VerifyEntries() {
    let errors = [];
    let emptyFields = [];
    if (name === "") {
      emptyFields.push("Name");
    }
    if (props.type === "Task") {
      if (parentId === 0) {
        emptyFields.push(fieldCustomizations.general.job.name);
      }
    }
    if (clientId === 0) {
      emptyFields.push("Client");
    }
    if (statusId === 0) {
      emptyFields.push("Status");
    }
    if (description === "") {
      emptyFields.push("Description");
    }
    if (emptyFields.length > 0) {
      errors.push(
        "The following fields cannot be empty: " + emptyFields.join(", ")
      );
    }
    if (new Date(startDate) > new Date(endDate)) {
      errors.push("Start date cannot be after the end date");
    }
    if (errors.length > 0) {
      AddAlert("error", errors.join(" - "));
      return new FormData();
    }

    let addFormData = new FormData();

    addFormData.append("name", name);
    if (props.type === "Task") {
      addFormData.append("parentId", parentId);
    }
    addFormData.append("clientId", clientId);
    addFormData.append("statusId", statusId);
    addFormData.append("startDate", startDate);
    addFormData.append("endDate", endDate);
    addFormData.append("budgetedTime", estimatedHours);
    if (projectManagerId !== 0) {
      addFormData.append("projectManagerId", projectManagerId);
    }
    addFormData.append("description", description);
    addFormData.append("customer", loggedInUser.customer.id);
    addFormData.append("customerId", loggedInUser.customer.id);
    addFormData.append("customFields", JSON.stringify(customFieldValues));

    return addFormData;
  }

  function CheckEditsPending() {
    if (props.item == null) return false;
    let nameMatches = name === props.item.name;
    let parentIdMatches = true;
    if (props.type === "Task") {
      parentIdMatches = parentId === props.item.job.id;
    }
    let clientIdMatches = true;
    if (props.item.client != null) {
      clientIdMatches = clientId === props.item.client.id;
    } else {
      clientIdMatches = clientId === 0;
    }
    let statusIdMatches = true;
    if (props.item.status != null) {
      statusIdMatches = statusId === props.item.status.id;
    } else {
      statusIdMatches = statusId === 0;
    }
    let priorityMatches = priority === props.item.priority;
    let startDateMatches =
      startDate ===
      new Date(props.item.startDate).toISOString().substring(0, 10);
    let endDateMatches =
      endDate === new Date(props.item.endDate).toISOString().substring(0, 10);
    let estimatedHoursMatches = estimatedHours === props.item.budgetedTime;
    let projectManagerIdMatches = true;
    if (props.item.projectManager != null) {
      projectManagerIdMatches =
        projectManagerId === props.item.projectManager.id;
    } else {
      projectManagerIdMatches = projectManagerId === 0;
    }
    let assignedUsersMatches = Utilities.CheckIfArraysAreEqual(
      assignedUsers,
      props.item.assignedUsers.map((user) => user.id)
    );
    let descriptionMatches = description === props.item.description;
    let customFieldValuesMatch =
      JSON.stringify(customFieldValues) ===
      JSON.stringify(GenerateCustomFieldValues());
    if (
      nameMatches &&
      parentIdMatches &&
      clientIdMatches &&
      statusIdMatches &&
      priorityMatches &&
      startDateMatches &&
      endDateMatches &&
      estimatedHoursMatches &&
      projectManagerIdMatches &&
      assignedUsersMatches &&
      descriptionMatches &&
      customFieldValuesMatch
    ) {
      props.SetEditsPending(false);
      return new FormData();
    }

    let formData = new FormData();
    if (!nameMatches) {
      formData.append("name", name);
    }
    if (!parentIdMatches) {
      formData.append("parentId", parentId);
    }
    if (!clientIdMatches) {
      formData.append("clientId", clientId);
    }
    if (!statusIdMatches) {
      formData.append("statusId", statusId);
    }
    if (!priorityMatches) {
      formData.append("priority", priority);
    }
    if (!startDateMatches) {
      formData.append("startDate", startDate);
    }
    if (!endDateMatches) {
      formData.append("endDate", endDate);
    }
    if (!estimatedHoursMatches) {
      formData.append("budgetedTime", estimatedHours);
    }
    if (!projectManagerIdMatches) {
      formData.append("projectManagerId", projectManagerId);
    }
    if (!assignedUsersMatches) {
      let currentlyAssignedUsers = props.item.assignedUsers.map(
        (user) => user.id
      );
      let newAssignedUsers = assignedUsers.slice();
      newAssignedUsers = newAssignedUsers.filter(
        (user) => !currentlyAssignedUsers.includes(user)
      );
      let newUnassignedUsers = currentlyAssignedUsers.filter(
        (user) => !assignedUsers.includes(user)
      );
      formData.append("assignedUsers", newAssignedUsers);
      formData.append("unassignedUsers", newUnassignedUsers);
    }
    if (!descriptionMatches) {
      formData.append("description", description);
    }
    if (!customFieldValuesMatch) {
      formData.append("customFields", JSON.stringify(customFieldValues));
    }
    props.SetEditsPending(true);
    return formData;
  }

  async function AddItem() {
    var formData = VerifyEntries();
    if (formData.entries().next().done) {
      return;
    }

    SetCurrentlySaving(true);

    let addResult = "";
    let errorOccurred = false;

    addResult = await axios({
      method: "post",
      url: Constants.API_URL + "Add" + Constants.API_KEY,
      headers: {
        "Content-Type": "application/json",
        UserId: loggedInUser.id,
        AddType: props.type,
      },
      data: formData,
    }).catch((error) => {
      errorOccurred = true;
      console.log(error);
      return "";
    });
    if (addResult !== "" && addResult?.data != null) {
      addResult = addResult.data;
    }
    if (addResult.includes("Successful") && !errorOccurred) {
      AddAlert("notification", props.type + " added successfully");
    } else {
      AddAlert("error", props.type + " could not be added");
    }

    if (!addResult.includes("Successful") || errorOccurred) {
      SetCurrentlySaving(false);
      return;
    }

    let addedId = addResult.split(";")[1];
    props.SetEditsPending(false);
    SetCurrentlySaving(false);
    props.RefreshItems();
    SendSignal("Refresh", "Add " + props.type + " " + name, "Refresh");
    setTimeout(() => {
      navigate("/" + props.addGroup + "/" + addedId);
    }, 100);
    props.CloseModal(true);
  }

  async function UpdateItem() {
    var formData = CheckEditsPending();
    if (formData.entries().next().done) {
      AddAlert("notification", "No changes made");
      return;
    }
    SetCurrentlySaving(true);

    let assignmentFormData = new FormData();

    if (formData.get("assignedUsers") != null) {
      assignmentFormData.append("assignedItems", formData.get("assignedUsers"));
      assignmentFormData.append(
        "removedItems",
        formData.get("unassignedUsers")
      );
      assignmentFormData.append("assignToId", props.item.id);
      assignmentFormData.append("assignToType", props.type);
      assignmentFormData.append("assignToList", "AssignedUsers");
      assignmentFormData.append("assigneeType", "User");
      formData.delete("assignedUsers");
      formData.delete("unassignedUsers");
    }

    let needsUpdateDetails = !formData.entries().next().done;
    let needsUpdateAssignments = !assignmentFormData.entries().next().done;

    let updateDetailsResult = "";
    let updateAssignmentsResult = "";

    let errorOccurred = false;

    if (needsUpdateDetails) {
      formData.append("id", props.item.id);
      updateDetailsResult = await axios({
        method: "patch",
        url: Constants.API_URL + "Update" + Constants.API_KEY,
        headers: {
          "Content-Type": "application/json",
          UserId: loggedInUser.id,
          UpdateType: props.type,
        },
        data: formData,
      }).catch((error) => {
        errorOccurred = true;
        console.log(error);
      });
      if (updateDetailsResult != null && updateDetailsResult.data != null) {
        updateDetailsResult = updateDetailsResult.data;
      }
      if (!errorOccurred && updateDetailsResult !== "Failed") {
        AddAlert(
          "notification",
          fieldCustomizations.general[props.type.toLowerCase()].name +
            " updated successfully"
        );
      } else {
        AddAlert(
          "error",
          fieldCustomizations.general[props.type.toLowerCase()].name +
            " could not be updated"
        );
      }
    }
    if (needsUpdateAssignments) {
      updateAssignmentsResult = await axios({
        method: "patch",
        url: Constants.API_URL + "UpdateAssignment" + Constants.API_KEY,
        headers: {
          "Content-Type": "application/json",
          UserId: loggedInUser.id,
          assignType: "AssignedUsers" + props.type,
        },
        data: assignmentFormData,
      }).catch((error) => {
        errorOccurred = true;
        console.log(error);
      });
      if (
        updateAssignmentsResult != null &&
        updateAssignmentsResult.data != null
      ) {
        updateAssignmentsResult = updateAssignmentsResult.data;
      }
      if (!errorOccurred && updateAssignmentsResult !== "Failed") {
        AddAlert(
          "notification",
          fieldCustomizations.general.user.pluralName + " Assigned"
        );
      } else {
        AddAlert(
          "error",
          fieldCustomizations.general.user.pluralName +
            " could not be assigned."
        );
      }
    }

    if (
      (needsUpdateDetails && updateDetailsResult === "Failed") ||
      (needsUpdateAssignments && updateAssignmentsResult === "Failed") ||
      errorOccurred
    ) {
      SetCurrentlySaving(false);
      return;
    }
    await SaveUpdateComment();
    await props.RefreshItems();
    await props.RefreshJob();
    SetCurrentlySaving(false);
    await props.SetEditsPending(false);
    ChangeEditMode();
    SendSignal("Refresh", "Update Job " + props.item.id, "Refresh");
  }

  async function SaveUpdateComment() {
    if (props.item == null || updateComment === "" || updateComment == null) {
      return;
    }
    let formData = new FormData();
    formData.append("userId", loggedInUser.id);
    formData.append("scheduleItemId", props.item.id);
    formData.append("timestamp", "now");
    formData.append("content", updateComment);
    let response = await AddData("Comment", formData);
    if (response != null && !isNaN(response)) {
      let alertMessage = "Update comment saved successfully.";
      AddAlert("notification", alertMessage);
    } else {
      AddAlert(
        "error",
        "There was an error saving your update comment. Please manually enter the comment in the comments section."
      );
    }
    SetUpdateComment("");
  }

  async function UpdateTaskDependencies() {
    let tasks = [];
    let correctClientId = null;
    let correctProjectManagerId = null;
    if (props.item.client != null) {
      correctClientId = props.item.client.id;
    }
    if (props.item.projectManager != null) {
      correctProjectManagerId = props.item.projectManager.id;
    }
    switch (props.type) {
      case "Job":
        tasks = props.item.tasks;
        break;
      case "Task":
        tasks = [props.item];
        if (props.item.job == null) {
          return;
        }
        if (props.item.job.client != null) {
          correctClientId = props.item.job.client.id;
        }
        if (props.item.job.projectManager != null) {
          correctProjectManagerId = props.item.job.projectManager.id;
        }
        break;
      default:
        return;
    }

    if (correctClientId == null && correctProjectManagerId == null) {
      return;
    }

    let hadAnUpdate = false;
    for (let i = 0; i < tasks.length; i++) {
      let clientIdMatches =
        tasks[i].client != null && tasks[i].client.id === correctClientId;
      let projectManagerIdMatches =
        tasks[i].projectManager != null &&
        tasks[i].projectManager.id === correctProjectManagerId;
      let needsUpdateStatus = false;
      if (
        !props.item.status.countedAsActive &&
        props.type === "Job" &&
        tasks[i].status != null
      ) {
        needsUpdateStatus = tasks[i].status.countedAsActive;
      }

      if (!clientIdMatches || !projectManagerIdMatches || needsUpdateStatus) {
        var formData = new FormData();
        if (!clientIdMatches && correctClientId != null) {
          formData.append("clientId", correctClientId);
        }
        if (!projectManagerIdMatches && correctProjectManagerId != null) {
          formData.append("projectManagerId", correctProjectManagerId);
        }
        if (needsUpdateStatus) {
          formData.append("statusId", props.item.status.id);
        }
        if (formData.entries().next().done) {
          continue;
        }
        formData.append("id", tasks[i].id);

        axios({
          method: "patch",
          url: Constants.API_URL + "Update" + Constants.API_KEY,
          headers: {
            "Content-Type": "multipart/form-data",
            UserId: loggedInUser.id,
            UpdateType: "Task",
          },
          data: formData,
        });
        hadAnUpdate = true;
      }
    }
    if (hadAnUpdate) {
      setTimeout(() => {
        props.RefreshItems();
        props.RefreshJob();
      }, 1000);
    }
  }

  function CheckRefreshOfJob(message) {
    if (message === "" || props.item == null) {
      return false;
    }
    let itemToCheck = "Job " + props.item.id;
    if (message.includes(itemToCheck)) {
      return true;
    }
    for (let i = 0; i < props.item.tasks?.length; i++) {
      itemToCheck = "Task " + props.item.tasks[i].id;
      if (message.includes(itemToCheck)) {
        return true;
      }
    }
    return false;
  }

  function GenerateGanttItemStatusParameters() {
    let fieldCustomizationGroup = fieldCustomizations[props.type.toLowerCase()];
    let itemParameterArray = [];
    if (props.type === "Task") {
      itemParameterArray.push(
        Utilities.GenerateGanttItemStatusParameter(
          "job",
          fieldCustomizations.general.job.name,
          parentId,
          "Dropdown",
          itemParameterArray.length,
          true,
          ChangeJob,
          props.jobs,
          jobName,
          true,
          true
        )
      );
    }
    itemParameterArray.push(
      props.type === "Task" && parentId != null && parentId !== 0
        ? Utilities.GenerateGanttItemStatusParameter(
            "",
            "Client",
            clientId,
            "Dropdown",
            itemParameterArray.length,
            false,
            ChangeClient,
            props.clients,
            clientName
          )
        : Utilities.GenerateGanttItemStatusParameter(
            "",
            "Client",
            clientId,
            "Dropdown",
            itemParameterArray.length,
            true,
            ChangeClient,
            props.clients,
            clientName,
            true,
            true
          )
    );
    itemParameterArray.push(
      Utilities.GenerateGanttItemStatusParameter(
        "",
        "Status",
        statusId,
        "Dropdown",
        itemParameterArray.length,
        true,
        ChangeStatus,
        statuses,
        statusName,
        null,
        null,
        true,
        "listOrder"
      )
    );
    itemParameterArray.push(
      Utilities.GenerateGanttItemStatusParameter(
        "",
        "Priority",
        priority,
        "Number",
        itemParameterArray.length,
        true,
        ChangePriority
      )
    );
    itemParameterArray.push(
      Utilities.GenerateGanttItemStatusParameter(
        "",
        "Start Date",
        startDate,
        "Date",
        itemParameterArray.length,
        true,
        ChangeStartDate,
        null,
        Utilities.GetDateDisplay(startDate),
        null,
        null,
        true
      )
    );
    itemParameterArray.push(
      Utilities.GenerateGanttItemStatusParameter(
        "",
        "End Date",
        endDate,
        "Date",
        itemParameterArray.length,
        true,
        ChangeEndDate,
        null,
        Utilities.GetDateDisplay(endDate),
        null,
        null,
        true,
        null,
        startDate
      )
    );
    itemParameterArray.push(
      Utilities.GenerateGanttItemStatusParameter(
        "",
        fieldCustomizationGroup.budgetedHours.name,
        estimatedHours,
        "Text",
        itemParameterArray.length,
        true,
        ChangeEstimatedHours
      )
    );
    if (props.item?.tasks != null) {
      itemParameterArray.push(
        Utilities.GenerateGanttItemStatusParameter(
          "",
          fieldCustomizationGroup.budgetedHours.name +
            " From " +
            fieldCustomizations.general.task.pluralName,
          props.item.tasks.reduce((a, b) => a + b.budgetedTime, 0).toFixed(2),
          "Text",
          itemParameterArray.length,
          false
        )
      );
    }

    let totalTime = 0;
    let percentage = 0;
    let percentageDisplay = "";
    if (props.item != null) {
      if (props.item.totalTimeRounded15 != null) {
        totalTime += props.item.totalTimeRounded15;
      }
      if (props.item.tasks != null) {
        for (let i = 0; i < props.item.tasks.length; i++) {
          if (props.item.tasks[i].totalTimeRounded15 != null) {
            totalTime += props.item.tasks[i].totalTimeRounded15;
          }
        }
      }
      totalTime = totalTime.toFixed(2);
      if (totalTime > 0 && estimatedHours > 0) {
        percentage = (totalTime / estimatedHours) * 100;
      }
    }
    if (percentage !== 0) {
      percentageDisplay = " (" + percentage.toFixed(0) + "%)";
    }
    if (!props.add)
      itemParameterArray.push(
        Utilities.GenerateGanttItemStatusParameter(
          "",
          "Total Time",
          totalTime + " hrs." + percentageDisplay,
          "Text",
          itemParameterArray.length,
          false
        )
      );
    if (!(props.add && props.type === "Task")) {
      itemParameterArray.push(
        props.type === "Task" && parentId != null && parentId !== 0
          ? Utilities.GenerateGanttItemStatusParameter(
              "",
              fieldCustomizationGroup.projectManager.name,
              projectManagerId,
              "Dropdown",
              itemParameterArray.length,
              false,
              ChangeProjectManager,
              props.users,
              projectManagerName
            )
          : Utilities.GenerateGanttItemStatusParameter(
              "",
              fieldCustomizationGroup.projectManager.name,
              projectManagerId,
              "Dropdown",
              itemParameterArray.length,
              true,
              ChangeProjectManager,
              props.users,
              projectManagerName,
              true,
              true
            )
      );
    }
    if (!props.add && props.item.assignedUsers != null) {
      itemParameterArray.push(
        Utilities.GenerateGanttItemStatusParameter(
          "",
          "Assigned " + fieldCustomizations.general.user.pluralName,
          assignedUsers,
          "Array",
          itemParameterArray.length,
          true,
          ChangeAssignedUsers,
          props.users
        )
      );
    }
    let customFields = Object.entries(fieldCustomizationGroup)
      .map((field) => {
        return field[1];
      })
      .filter((field) => field.isCustomField);
    for (let i = 0; i < customFields.length; i++) {
      itemParameterArray.push(
        Utilities.GenerateGanttItemStatusParameter(
          "",
          customFields[i].name,
          () => {
            return (
              customFieldValues.find(
                (field) => field.fieldId === customFields[i].fieldId
              )?.value ?? ""
            );
          },
          customFields[i].type,
          itemParameterArray.length,
          true,
          (event) => ChangeCustomFieldValues(customFields[i].fieldId, event),
          null,
          customFields[i].type === "Date"
            ? Utilities.GetDateDisplay(
                customFieldValues.find(
                  (field) => field.fieldId === customFields[i].fieldId
                )?.value ?? ""
              )
            : null
        )
      );
    }

    return itemParameterArray;
  }

  function ResetItemInfo() {
    if (!props.add) {
      SetName(props.item.name);
      if (props.type === "Task") {
        SetParentId(props.item.job.id);
        if (props.item.job.name != null) {
          SetJobName(props.item.job.name);
        }
      }
      if (
        props.item.job != null &&
        props.item.job.client != null &&
        props.item.job.clientId !== 0
      ) {
        SetClientId(props.item.job.client.id);
        SetClientName(props.item.job.client.name);
      } else {
        if (props.item.client != null) {
          SetClientId(props.item.client.id);
          SetClientName(props.item.client.name);
        } else {
          SetClientId(0);
          SetClientName("");
        }
      }
      if (
        props.item.job != null &&
        props.item.job.projectManager != null &&
        props.item.job.projectManagerId !== 0
      ) {
        SetProjectManagerId(props.item.job.projectManager.id);
        SetProjectManagerName(props.item.job.projectManager.name);
      } else {
        if (props.item.projectManager != null) {
          SetProjectManagerId(props.item.projectManager.id);
          SetProjectManagerName(props.item.projectManager.name);
        } else {
          SetProjectManagerId(0);
          SetProjectManagerName("");
        }
      }
      if (props.item.status.name != null) {
        SetStatusId(props.item.status.id);
        SetStatusName(props.item.status.name);
      } else {
        SetStatusId(0);
        SetStatusName("");
      }
      if (props.item.priority != null) {
        SetPriority(props.item.priority);
      } else {
        SetPriority(0);
      }
      SetStartDate(
        new Date(props.item.startDate).toISOString().substring(0, 10)
      );
      SetEndDate(new Date(props.item.endDate).toISOString().substring(0, 10));
      SetEstimatedHours(props.item.budgetedTime);
      SetAssignedUsers(props.item.assignedUsers.map((user) => user.id));
      SetDescription(props.item.description);
      SetCustomFieldValues(GenerateCustomFieldValues());
      SetReadyForEditCheck(true);
      return;
    }
    if (props.attachToJob != null) {
      SetParentId(props.attachToJob.id);
      SetJobName(props.attachToJob.name);
      if (
        props.attachToJob.client != null &&
        props.attachToJob.clientId !== 0
      ) {
        SetClientId(props.attachToJob.client.id);
        SetClientName(props.attachToJob.client.name);
      }
    }
    if (customerSettings != null && customerSettings.status != null) {
      SetStatusId(customerSettings.status.id);
      if (customerSettings.status.name != null) {
        SetStatusName(customerSettings.status.name);
      }
    }
  }

  function GenerateCustomFieldValues() {
    let newCustomFieldValues = [];
    if (props.item == null) {
      return newCustomFieldValues;
    }
    let fieldCustomizationGroup = fieldCustomizations[props.type.toLowerCase()];
    let customFields = Object.entries(fieldCustomizationGroup)
      .map((field) => {
        return field[1];
      })
      .filter((field) => field.isCustomField);
    let customFieldsFromJson = [];
    if (props.item.customFields !== "") {
      customFieldsFromJson = JSON.parse(props.item.customFields);
    }
    for (let i = 0; i < customFields.length; i++) {
      let itemsValue = customFieldsFromJson.find(
        (field) => field.fieldId === customFields[i].fieldId
      );
      newCustomFieldValues.push({
        fieldId: customFields[i].fieldId,
        value: itemsValue?.value ?? "",
      });
    }
    return newCustomFieldValues;
  }

  useEffect(() => {
    if (CheckRefreshOfJob(refreshSignal)) {
      props.RefreshJob();
      if (!props.editsPending) {
        AddAlert(
          "notification",
          fieldCustomizations.general.job.name + " updated by another user."
        );
      } else {
        AddAlert(
          "notification",
          fieldCustomizations.general.job.name +
            " updated by another user. Your Edits might overwrite theirs."
        );
      }
    }
    if (props.add) {
      props.SetEditsPending(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshSignal, props.editsPending]);

  useEffect(() => {
    if ((props.item != null && !props.editsPending) || props.add) {
      ResetItemInfo();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.item, props.attachToJob]);

  useEffect(() => {
    if (readyForEditCheck) {
      UpdateTaskDependencies();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.item, readyForEditCheck]);

  const customValuesJson = JSON.stringify(customFieldValues);

  useEffect(() => {
    if (readyForEditCheck) {
      CheckEditsPending();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.item,
    name,
    parentId,
    clientId,
    statusId,
    startDate,
    endDate,
    estimatedHours,
    projectManagerId,
    assignedUsers,
    description,
    customValuesJson,
    priority,
  ]);

  return (
    <div className="ganttItemModalContainer">
      <div className="ganttItemModalBodySection">
        <GanttItemInformation
          item={props.item}
          editMode={editMode || props.add}
          ChangeEditMode={props.add ? props.CloseModal : ChangeEditMode}
          itemStatusParameters={GenerateGanttItemStatusParameters()}
          CloseModal={props.CloseModal}
          RefreshJob={props.RefreshJob}
          RefreshItems={props.RefreshItems}
          name={name}
          ChangeName={ChangeName}
          description={description}
          ChangeDescription={ChangeDescription}
          users={props.usersForFilter}
          clients={props.clients}
          SetEditsPending={props.SetEditsPending}
          editsPending={props.editsPending}
          hasTasks={props.type === "Job"}
          UpdateItem={props.add ? AddItem : UpdateItem}
          ResetDisplay={ResetItemInfo}
          type={props.type}
          add={props.add}
          jobs={props.jobs}
          addTitle={
            "Add " +
            fieldCustomizations.general[props.type.toLowerCase()].name +
            (props.attachToJob != null ? " to " + props.attachToJob.name : "")
          }
          currentlySaving={currentlySaving}
          updateComment={updateComment}
          ChangeUpdateComment={ChangeUpdateComment}
          customFieldValues={customFieldValues}
        />
      </div>
    </div>
  );
}

export default GanttItemModal;
