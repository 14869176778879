import React, { useState, useEffect } from "react";
import GanttHeader from "./GanttHeader/GanttHeader";
import GanttChart from "./GanttChart/GanttChart";
import { useSiteContext } from "../../Context";

function Gantt(props) {
  const [jobs, SetJobs] = useState([]);
  const [tasks, SetTasks] = useState([]);
  const [rangeNumber, SetRangeNumber] = useState(4);
  const {
    refreshSignal,
    loggedInUser,
    GetData,
    statuses,
    fieldCustomizations,
  } = useSiteContext();
  const [hasFilters, SetHasFilters] = useState(false);

  async function GetGanttData(type, SetFunction) {
    if (
      props.startDate !== "" &&
      props.endDate !== "" &&
      loggedInUser.id != null &&
      statuses.length !== 0
    ) {
      let editedStartDate = new Date(props.startDate);
      editedStartDate.setDate(editedStartDate.getDate() - 60);
      let options = {
        startDate: editedStartDate,
        endDate: props.endDate,
        customer: loggedInUser.customer.id,
        excludeStatuses:
          props.statusFilter.length > 0 ? props.statusFilter.join(",") : "",
        filter1Operator: "not in",
        filter1Type: "int",
        recursionLevel: 2,
      };
      let newGanttData = await GetData(type, options, "gantt");
      if (newGanttData instanceof Array) {
        SetFunction(newGanttData);
      }
    }
  }

  async function GetJobs() {
    GetGanttData("Job", SetJobs);
  }

  async function GetTasks() {
    GetGanttData("Task", SetTasks);
  }

  function RemoveFilter(type) {
    switch (type) {
      case "user":
        props.ChangeFilteredUsers([]);
        break;
      case "job":
        props.ChangeFilteredJobs([]);
        break;
      case "client":
        props.ChangeFilteredClients([]);
        break;
      default:
        break;
    }
  }

  function GenerateFilterOptions() {
    let jobsFromJobs = [];
    let jobsFromTasks = [];
    let tasksFromTasks = [];
    let tasksFromJobs = [];
    let clientFromJobs = [];
    let clientFromTasks = [];
    let userFromJobs = [];
    let userFromTasks = [];

    if (jobs.length > 0) {
      jobsFromJobs = jobs.slice();
      clientFromJobs = jobs.map((j) => j.client);
      jobs.forEach((j) => {
        let jobUsers = j.assignedUsers.slice();
        userFromJobs = userFromJobs.concat(jobUsers);
      });
    }

    if (tasks.length > 0) {
      tasksFromTasks = tasks.map((t) => {
        let currentTask = t;
        if (
          currentTask.parentId !== 0 &&
          currentTask.job != null &&
          currentTask.job.clientId !== 0
        ) {
          currentTask.client = t.job.client;
        }
        return currentTask;
      });
      jobsFromTasks = tasks.filter((t) => t.job != null).map((t) => t.job);
      clientFromTasks = tasks.map((t) => t.client);
      tasks.forEach((t) => {
        let taskUsers = t.assignedUsers.slice();
        userFromTasks = userFromTasks.concat(taskUsers);
      });
    }

    let newJobsForFilter = jobsFromJobs.concat(jobsFromTasks);
    let newTasksForFilter = tasksFromTasks.concat(tasksFromJobs);
    let newClientsForFilter = clientFromJobs.concat(clientFromTasks);
    let newUsersForFilter = userFromJobs.concat(userFromTasks);

    newJobsForFilter = newJobsForFilter.filter(
      (job, index, currentArray) =>
        currentArray.findIndex((job2) => job2.id === job.id) === index &&
        job.id !== 0
    );

    newTasksForFilter = newTasksForFilter.filter(
      (task, index, currentArray) =>
        currentArray.findIndex((task2) => task2.id === task.id) === index &&
        task.id !== 0
    );

    newClientsForFilter = newClientsForFilter.filter(
      (client, index, currentArray) =>
        currentArray.findIndex((client2) => client2.id === client.id) ===
          index && client.id !== 0
    );

    newUsersForFilter = newUsersForFilter.filter(
      (user, index, currentArray) =>
        currentArray.findIndex((user2) => user2.id === user.id) === index &&
        user.id !== 0
    );

    props.ChangeJobsForFilter(newJobsForFilter);
    props.ChangeTasksForFilter(newTasksForFilter);
    props.ChangeClientsForFilter(newClientsForFilter);
    props.ChangeUsersForFilter(newUsersForFilter);
  }

  function ChangeRangeNumber(rangeNumber) {
    SetRangeNumber(rangeNumber);
  }

  useEffect(() => {
    GetJobs();
    GetTasks();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.startDate, props.endDate, props.statusFilter]);

  useEffect(() => {
    if (refreshSignal !== "") {
      GetJobs();
      GetTasks();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshSignal]);

  useEffect(() => {
    GenerateFilterOptions();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobs, tasks]);

  useEffect(() => {
    if (
      props.filteredJobs.length > 0 ||
      props.filteredClients.length > 0 ||
      props.filteredUsers.length > 0
    ) {
      SetHasFilters(true);
    } else {
      SetHasFilters(false);
    }
    // throw new Error("Something went weirdlywrong");
  }, [props.filteredJobs, props.filteredClients, props.filteredUsers]);

  return (
    <div className={"ganttContainer range" + rangeNumber}>
      <GanttHeader
        rangeNumber={rangeNumber}
        ChangeRangeNumber={ChangeRangeNumber}
        ChangeFilteredUsers={props.ChangeFilteredUsers}
        ChangeFilteredJobs={props.ChangeFilteredJobs}
        ChangeFilteredTasks={props.ChangeFilteredTasks}
        ChangeFilteredClients={props.ChangeFilteredClients}
        ChangeFilteredSortName={props.ChangeFilteredSortName}
        ChangeFilteredSortValue={props.ChangeFilteredSortValue}
        filteredSortName={props.filteredSortName}
        filteredSortValue={props.filteredSortValue}
        filteredUsers={props.filteredUsers}
        filteredJobs={props.filteredJobs}
        filteredClients={props.filteredClients}
        jobsForFilter={props.jobsForFilter}
        clientsForFilter={props.clientsForFilter}
        usersForFilter={props.usersForFilter}
        statusFilter={props.statusFilter}
        RemoveFilter={RemoveFilter}
        hasFilters={hasFilters}
        path={props.path}
      />
      {props.path[1] != null ? (
        <GanttChart
          startDate={props.startDate}
          endDate={props.endDate}
          search={props.search}
          statusFilter={props.statusFilter}
          filteredUsers={props.filteredUsers}
          filteredJobs={props.filteredJobs}
          filteredClients={props.filteredClients}
          filteredSortName={props.filteredSortName}
          filteredSortValue={props.filteredSortValue}
          jobsForFilter={props.jobsForFilter}
          timeSpan={props.timeSpan}
          path={props.path}
          rangeNumber={rangeNumber}
          items={
            props.path[1] === fieldCustomizations.general.job.pluralName
              ? jobs
              : props.path[1] === fieldCustomizations.general.task.pluralName
              ? tasks
              : []
          }
          RefreshItems={
            props.path[1] === fieldCustomizations.general.job.pluralName
              ? GetJobs
              : props.path[1] === fieldCustomizations.general.task.pluralName
              ? GetTasks
              : null
          }
          ChangeItemsBefore={props.ChangeItemsBefore}
        />
      ) : null}
    </div>
  );
}

export default Gantt;
