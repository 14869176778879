import React, { useState, useEffect } from "react";
import Day from "../Calendar/Day";
import { useNavigate } from "react-router-dom";
import { useSiteContext } from "../../../../Context";
import * as Constants from "../../../../Constants";
import * as Utilities from "../../../../Utilities";

function ChartItem(props) {
  const navigate = useNavigate();
  const [barInfo, SetBarInfo] = useState(CalculateBarInfo());
  const [transparentImage, SetTransparentImage] = useState(null);
  const [canQuickUpdate, SetCanQuickUpdate] = useState(false);
  const [newDate, SetNewDate] = useState(null);

  const { PermissionCheck, fieldCustomizations, UpdateData, AddAlert } =
    useSiteContext();

  function CalculateContainerClass() {
    if (props.spacer) {
      return "chartItemContainer spacer";
    }
    if (props.item == null) {
      return "chartItemContainer";
    }
    if (props.item.name.length > 62) {
      return "chartItemContainer threeLine";
    }
    if (props.item.name.length > 31) {
      return "chartItemContainer twoLine";
    }
    return "chartItemContainer";
  }

  function CalculateDaysClass() {
    let classToReturn = "chartItemDaysContainer";
    if (!props.even) {
      classToReturn += " odd";
    }
    if (props.spacer) {
      classToReturn += " spacer";
    }
    return classToReturn;
  }

  function CalculateBarInfo(adjustStart = 0, adjustEnd = 0) {
    let barInfoToReturn = {
      startColumn: 1,
      endColumn: 1,
      barClass: "",
      hidden: true,
    };
    if (props.item != null) {
      barInfoToReturn.hidden = false;
      barInfoToReturn.startColumn = (
        Utilities.GetDayTimespan(
          new Date(props.startDate),
          new Date(props.item.startDate)
        ) +
        1 +
        adjustStart
      ).toFixed(0);
      let startForEndCalculation = new Date(props.item.startDate);
      if (barInfoToReturn.startColumn < 1) {
        barInfoToReturn.startColumn = 1;
        startForEndCalculation = new Date(props.startDate);
        barInfoToReturn.barClass += " barStartsBefore";
      }
      barInfoToReturn.endColumn = (
        Utilities.GetDayTimespan(
          startForEndCalculation,
          new Date(props.item.endDate)
        ) +
        1 +
        adjustEnd
      ).toFixed(0);
      if (barInfoToReturn.endColumn > props.timeSpan) {
        barInfoToReturn.endColumn = props.timeSpan;
        barInfoToReturn.barClass += " barEndsAfter";
      }
      if (
        props.item.status != null &&
        props.item.status.color != null &&
        props.item.status.color !== 1
      ) {
        let lineColor = Constants.colorOptions.find(
          (c) => c.id === props.item.status.color
        );
        if (lineColor != null) {
          barInfoToReturn.barClass += " " + lineColor.colorClass;
        }
      }
    }
    return barInfoToReturn;
  }

  function CalculateItemOverBudgetClass() {
    let result = "";

    let totalTime = props.item?.calculatedTotalTime.TotalTimeExact;

    if (props.item?.tasks !== null && props.item?.tasks !== undefined) {
      totalTime = props.item?.tasks.reduce((totalTasksTime, task) => {
        return totalTasksTime + task.totalTimeRounded15;
      }, totalTime);
    }
    if (props.item?.budgetedTime > 0 && totalTime > props.item?.budgetedTime) {
      result = " GanttItemOverBudget";
    }
    return result;
  }

  function CalculateItemOverdueClass() {
    let result = "";
    let today = new Date();
    today.setHours(0, 0, 0, 0);

    if (
      props.item?.status.name !== "Completed" &&
      today > Date.parse(props.item?.endDate)
    ) {
      result = " GanttItemOverdue";
    }
    return result;
  }

  function OpenModal() {
    navigate("/" + props.path[1] + "/" + props.item.id);
  }

  function GenerateDragImage() {
    let transparentDragImage = new Image(0, 0);
    transparentDragImage.src =
      "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7";
    SetTransparentImage(transparentDragImage);
  }

  function HideDragImage(event) {
    event.dataTransfer.setDragImage(transparentImage, 0, 0);
  }

  function MeasureWhereDateIsBeingDragged(event, isEndDate) {
    let elementsUnderMouse = document.elementsFromPoint(
      event.clientX,
      event.clientY
    );
    let day = elementsUnderMouse.find((e) =>
      e.classList.contains("dayContainer")
    );
    if (day?.dataset?.date == null) {
      return;
    }
    let dateOfDayContainer = new Date(day?.dataset?.date);
    let daysToAdd =
      Date.parse(dateOfDayContainer) -
      Date.parse(isEndDate ? props.item.endDate : props.item.startDate);
    daysToAdd = daysToAdd / (1000 * 60 * 60 * 24);

    if (isEndDate) {
      SetBarInfo(CalculateBarInfo(0, daysToAdd));
    } else {
      SetBarInfo(CalculateBarInfo(daysToAdd, -daysToAdd));
    }
    if (daysToAdd !== 0) {
      SetNewDate(dateOfDayContainer);
    } else {
      SetNewDate(null);
    }
  }

  async function QuickUpdateItemDate(e, isEndDate) {
    if (newDate != null) {
      let formData = new FormData();
      formData.append("id", props.item.id);
      if (isEndDate) {
        formData.append("endDate", newDate.toISOString().substring(0, 10));
      } else {
        formData.append("startDate", newDate.toISOString().substring(0, 10));
      }

      let response = await UpdateData(props.type, formData);
      SetNewDate(null);
      SetBarInfo(CalculateBarInfo());
      if (!response.includes("Successful")) {
        AddAlert("error", "Error updating item date.");
        return;
      }
      AddAlert("notification", "Item date updated.");
      props.RefreshItems();
    }
  }

  useEffect(() => {
    SetBarInfo(CalculateBarInfo());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.timeSpan, props.item, props.rangeNumber]);

  useEffect(() => {
    GenerateDragImage();
    let canQuickUpdateJobs = PermissionCheck("job", 5, 59);
    let canQuickUpdateTasks = PermissionCheck("task", 5, 60);

    if (
      canQuickUpdateJobs &&
      props.path[1] === fieldCustomizations.general.job.pluralName
    ) {
      SetCanQuickUpdate(true);
    }
    if (
      canQuickUpdateTasks &&
      props.path[1] === fieldCustomizations.general.task.pluralName
    ) {
      SetCanQuickUpdate(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={CalculateContainerClass()}>
      <div
        className={
          "chartItemLabelContainer" +
          CalculateItemOverBudgetClass() +
          CalculateItemOverdueClass()
        }
        onClick={OpenModal}
      >
        <div className="chartItemLabel">
          {props.item ? Utilities.TruncateString(props.item.name) : null}
        </div>
      </div>
      <div className={CalculateDaysClass()}>
        {props.days.map((day, index) => (
          <Day
            key={index}
            date={day.day}
            trueDate={day.date}
            isToday={day.isToday}
          />
        ))}
        {props.item != null ? (
          <div
            className="dayLine startPoint"
            style={{ gridColumnStart: barInfo.startColumn }}
          >
            <div className="dayLine sizeGrid">
              <div
                className={"dayLine lineContainer " + barInfo.barClass}
                style={{
                  gridColumnStart: 1,
                  gridColumnEnd: "span " + barInfo.endColumn,
                }}
              >
                <div className={"dayLine fullLine" + barInfo.barClass}>
                  {canQuickUpdate ? (
                    <>
                      <div
                        className="changeStartOnChart"
                        onDragStart={HideDragImage}
                        onDrag={(e) => MeasureWhereDateIsBeingDragged(e, false)}
                        onDragEnd={(e) => QuickUpdateItemDate(e, false)}
                        draggable
                      ></div>
                      <div
                        className="changeEndOnChart"
                        onDragStart={HideDragImage}
                        onDrag={(e) => MeasureWhereDateIsBeingDragged(e, true)}
                        onDragEnd={(e) => QuickUpdateItemDate(e, true)}
                        draggable
                      ></div>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default ChartItem;
