import React from "react";
import { Editor } from "@tinymce/tinymce-react";
import { useSiteContext } from "../Context";

function HtmlEditor(props) {
  const { darkMode } = useSiteContext();

  function GetPluginList() {
    let pluginList = [
      "autoresize",
      "advlist",
      "autolink",
      "lists",
      "link",
      "image",
      "charmap",
      "preview",
      "anchor",
      "searchreplace",
      "visualblocks",
      "code",
      "fullscreen",
      "insertdatetime",
      "media",
      "table",
      "help",
      "wordcount",
    ];
    if (props.disablePlugins != null) {
      pluginList = pluginList.filter((x) => !props.disablePlugins.includes(x));
    }
    return pluginList;
  }

  return (
    <Editor
      apiKey="758p3p6omrvksdfnommrnolv2u1z2uqc5z8e47n92z6q3wr9"
      onInit={(evt, editor) => (props.editorRef.current = editor)}
      value={props.value != null ? props.value : ""}
      className={props.className}
      init={{
        content_css: darkMode ? "dark" : "",
        skin: darkMode ? "oxide-dark" : "oxide",
        height: "100%",
        menubar: false,
        plugins: GetPluginList(),
        toolbar_mode: "floating",
        mobile: {
          toolbar_mode: "floating",
        },
        toolbar: [
          { name: "history", items: ["undo", "redo"] },
          { name: "styles", items: ["styles", "format", "font", "fontsize"] },
          {
            name: "editing",
            items: ["selectall"],
          },
          {
            name: "alignment",
            items: ["alignleft", "aligncenter", "alignright", "alignjustify"],
          },
          {
            name: "clipboard",
            items: ["pasteText", "print", "link"],
          },
          {
            name: "basicstyles",
            items: [
              "bold",
              "italic",
              "underline",
              "strikethrough",
              "subscript",
              "superscript",
              "removeformat",
            ],
          },
          {
            name: "paragraph",
            items: ["numlist", "bullist", "outdent", "indent", "blockquote"],
          },
          { name: "links", items: ["link", "unlink"] },
          {
            name: "insert",
            items: ["image", "table", "hr", "pagebreak", "insertdatetime"],
          },
        ],
        content_style:
          "body { font-family:Helvetica,Arial,sans-serif; font-size:14px; background-color: transparent; }",
        resize: false,
        setup: function (editor) {
          editor.on("keydown", function (event) {
            if (event.key === "Tab") {
              editor.execCommand(
                "mceInsertContent",
                false,
                "&nbsp;&nbsp;&nbsp;&nbsp;"
              ); // inserts tab
              event.preventDefault();
              event.stopPropagation();
              return false;
            }
          });
          editor.on("Paste Change input Undo Redo", function () {
            props.onChange(props.index, editor.getContent());
          });
        },
        branding: false,
      }}
    />
  );
}

export default HtmlEditor;
