import React from "react";
import Range from "./Range";
import DropDownMenu from "../../../Generic/DropDownMenu";
import { useSiteContext } from "../../../Context";
import { Icon } from "@iconify/react";
import Modal from "../../../Generic/Modal";
import FilterSelectorModal from "../../Filter/FilterSelectorModal";

function GanttHeader(props) {
  const [filterSelector, SetFilterSelector] = React.useState(false);

  const { fieldCustomizations } = useSiteContext();

  function ToggleFilterSelector() {
    SetFilterSelector(!filterSelector);
  }

  function SortOnChangeName(value) {
    props.ChangeFilteredSortName(value);
  }

  function ChangeSortDirection() {
    props.ChangeFilteredSortValue(!props.filteredSortValue);
  }

  return (
    <>
      <div className="ganttHeaderContainer">
        <div className="ganttHeaderColumnSort">
          <DropDownMenu
            className="sortByDropdown"
            value={props.filteredSortName}
            onChange={SortOnChangeName}
            options={[
              { id: "Client", name: fieldCustomizations.general.client.name },
              {
                id: "Job",
                name: fieldCustomizations.general.job.name,
              },
              { id: "Name", name: "Name" },
              { id: "Status", name: "Status" },
              { id: "Start Date", name: "Start Date" },
              { id: "End Date", name: "End Date" },
              { id: "Priority", name: "Priority" },
            ]}
            dontSort={true}
          />
          <Icon
            icon={
              props.filteredSortValue
                ? "akar-icons:arrow-down"
                : "akar-icons:arrow-up"
            }
            className="sortByArrow"
            onClick={ChangeSortDirection}
          />
          <Icon
            icon="mdi:filter"
            className="sortByArrow"
            onClick={ToggleFilterSelector}
          />
        </div>
        <div className="ganttFilterNoticeContainer">
          {props.hasFilters ? (
            <>
              <div
                className="filterNoticeItem main"
                onClick={() => {
                  props.RemoveFilter("job");
                  props.RemoveFilter("client");
                  props.RemoveFilter("user");
                }}
              >
                <div className="removeFilterX">x</div>
                <div className="filterNoticeLabel">Filtered By:</div>
              </div>
              <div className="ganttFilterNotice">
                {props.filteredJobs.length > 0 ? (
                  <div
                    className="filterNoticeItem"
                    onClick={() => props.RemoveFilter("job")}
                  >
                    <div className="removeFilterX">x</div>
                    <div className="filterNoticeItemLabel">
                      {fieldCustomizations.general.job.name}:
                    </div>
                    <div className="filterNoticeItemValue">
                      {props.jobsForFilter
                        .filter((j) => {
                          return props.filteredJobs.includes(j.id);
                        })
                        .map((j) => j.name)
                        .join(", ")}
                    </div>
                  </div>
                ) : null}
                {props.filteredClients.length > 0 ? (
                  <div
                    className="filterNoticeItem"
                    onClick={() => props.RemoveFilter("client")}
                  >
                    <div className="removeFilterX">x</div>
                    <div className="filterNoticeItemLabel">{`${fieldCustomizations.general.client.name}:`}</div>
                    <div className="filterNoticeItemValue">
                      {props.clientsForFilter
                        .filter((c) => {
                          return props.filteredClients.includes(c.id);
                        })
                        .map((c) => c.name)
                        .join(", ")}
                    </div>
                  </div>
                ) : null}
                {props.filteredUsers.length > 0 ? (
                  <div
                    className="filterNoticeItem"
                    onClick={() => props.RemoveFilter("user")}
                  >
                    <div className="removeFilterX">x</div>
                    <div className="filterNoticeItemLabel">
                      {fieldCustomizations.general.user.name + ":"}
                    </div>
                    <div className="filterNoticeItemValue">
                      {props.usersForFilter
                        .filter((u) => {
                          return props.filteredUsers.includes(u.id);
                        })
                        .map((u) => u.name)
                        .join(", ")}
                    </div>
                  </div>
                ) : null}
              </div>
            </>
          ) : null}
        </div>
        <Range
          rangeNumber={props.rangeNumber}
          ChangeRangeNumber={props.ChangeRangeNumber}
        />
      </div>
      {filterSelector ? (
        <Modal
          CloseModal={ToggleFilterSelector}
          modificationClass="noPaddingAlternate"
        >
          <FilterSelectorModal
            title={"Select Filters"}
            SetStatusFilter={props.SetStatusFilter}
            ChangeFilteredUsers={props.ChangeFilteredUsers}
            ChangeFilteredJobs={props.ChangeFilteredJobs}
            ChangeFilteredTasks={props.ChangeFilteredTasks}
            ChangeFilteredClients={props.ChangeFilteredClients}
            statusFilter={props.statusFilter}
            filteredUsers={props.filteredUsers}
            filteredJobs={props.filteredJobs}
            filteredTasks={props.filteredTasks}
            filteredClients={props.filteredClients}
            jobsForFilter={props.jobsForFilter}
            clientsForFilter={props.clientsForFilter}
            usersForFilter={props.usersForFilter}
            tasksForFilter={props.tasksForFilter}
            showJobFilter={
              !props.path[1].includes(fieldCustomizations.general.job.name)
            }
          />
        </Modal>
      ) : null}
    </>
  );
}

export default GanttHeader;
