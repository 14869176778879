import ForagerTableColumn from "./ForagerTableColumn";
import ReportColumns from "./ReportColumns";

class ForagerReport {
  constructor({
    category = 0,
    type = 0,
    startDate = new Date(),
    endDate = new Date(),
    dateRangeType = 1,
    fieldCustomizations = null,
  }) {
    this.category = category;
    this.type = type;
    this.startDate = startDate;
    this.endDate = endDate;
    this.dateRangeType = dateRangeType;
    this.data = [];
    this.fieldCustomizations = fieldCustomizations;
    this.ReportColumns = new ReportColumns(fieldCustomizations);
  }

  GetReportColumns() {
    switch (this.category) {
      case 1:
        return this.GetUserReportColumns();
      case 2:
        return this.GetScheduleItemReportColumns("job");
      case 3:
        return this.GetScheduleItemReportColumns("task");
      default:
        return [];
    }
  }

  GetUserReportColumns() {
    let columns = this.ReportColumns.GetUserColumns();
    switch (this.type) {
      case 1:
        columns[0].propertyPathArray = ["user", "name"];
        columns = columns.concat(
          this.ReportColumns.GetScheduleItemColumns("task", false)
        );
        columns.push(
          new ForagerTableColumn({
            header: "Earliest Time Entry",
            ValueFunction: (item) => {
              let earliestTimeEntry = item.timeEntries[0].startTime;
              for (let i = 1; i < item.timeEntries?.length; i++) {
                if (item.timeEntries[i].startTime < earliestTimeEntry) {
                  earliestTimeEntry = item.timeEntries[i].startTime;
                }
              }
              return new Date(earliestTimeEntry).toLocaleDateString();
            },
            sortable: true,
            sortType: "date",
          })
        );
        columns.push(
          new ForagerTableColumn({
            header: "Latest Time Entry",
            ValueFunction: (item) => {
              let latestTimeEntry = item.timeEntries[0].endTime;
              for (let i = 1; i < item.timeEntries.length; i++) {
                if (item.timeEntries[i].endTime > latestTimeEntry) {
                  latestTimeEntry = item.timeEntries[i].endTime;
                }
              }
              return new Date(latestTimeEntry).toLocaleDateString();
            },
            sortable: true,
            sortType: "date",
          })
        );
        columns.push(
          new ForagerTableColumn({
            header: "Total Hours",
            ValueFunction: (item) => {
              let totalHours = 0;
              for (let i = 0; i < item.timeEntries.length; i++) {
                totalHours += item.timeEntries[i].lengthInHoursRounded15;
              }
              return totalHours.toFixed(2) + " hours";
            },
            sortable: true,
            sortType: "number",
            isDefaultSort: true,
            sortValueFunction: (item) => {
              let totalHours = 0;
              for (let i = 0; i < item.timeEntries.length; i++) {
                totalHours += item.timeEntries[i].lengthInHoursRounded15;
              }
              return totalHours;
            },
          })
        );
        break;
      case 2:
        columns.push(
          new ForagerTableColumn({
            header: "Total Hours",
            ValueFunction: (item) => {
              let totalHours = 0;
              for (let i = 0; i < item.timeEntries.length; i++) {
                totalHours += item.timeEntries[i].lengthInHours;
              }
              return totalHours.toFixed(2) + " hours";
            },
            sortable: true,
            sortType: "number",
            sortValueFunction: (item) => {
              let totalHours = 0;
              for (let i = 0; i < item.timeEntries.length; i++) {
                totalHours += item.timeEntries[i].lengthInHours;
              }
              return totalHours;
            },
          })
        );
        break;
      case 3:
        columns[0].propertyPathArray = ["user", "name"];
        columns = columns.concat(this.ReportColumns.GetTimeEntryColumns());
        columns.push(
          new ForagerTableColumn({
            header: "Total Hours",
            ValueFunction: (item) => {
              return item.lengthInHoursRounded15 + " hours";
            },
            sortable: true,
            sortType: "number",
            sortPath: ["lengthInHoursRounded15"],
          })
        );
        break;
      default:
        break;
    }
    return columns;
  }

  GetScheduleItemReportColumns(type) {
    let columns = this.ReportColumns.GetScheduleItemColumns(type);
    switch (this.type) {
      case 4:
        columns.push(
          new ForagerTableColumn({
            header: "Time To Close",
            ValueFunction: (item) => {
              let dateDifference =
                (new Date(item.endDate) - new Date(item.startDate)) /
                1000 /
                60 /
                60 /
                24;
              return dateDifference.toFixed(0) + " days";
            },
            sortable: true,
            sortType: "number",
            sortValueFunction: (item) => {
              return new Date(item.endDate) - new Date(item.startDate);
            },
          })
        );
        break;
      case 5:
        columns.push(
          new ForagerTableColumn({
            header: "Days Remaining",
            ValueFunction: (item) => {
              let dateDifference =
                (new Date(item.endDate) - new Date()) / 1000 / 60 / 60 / 24;
              return dateDifference.toFixed(0) + " days";
            },
            sortable: true,
            sortType: "number",
            sortValueFunction: (item) => {
              return new Date(item.endDate) - new Date();
            },
          })
        );
        break;
      case 6:
        columns.push(
          new ForagerTableColumn({
            header: "Days Overdue",
            ValueFunction: (item) => {
              let dateDifference =
                (new Date() - new Date(item.endDate)) / 1000 / 60 / 60 / 24;
              if (dateDifference < 0) {
                return "On Schedule";
              }
              return dateDifference.toFixed(0) + " days";
            },
            sortable: true,
            isDefaultSort: true,
            sortType: "number",
            sortValueFunction: (item) => {
              return new Date() - new Date(item.endDate);
            },
          })
        );
        break;
      case 7:
        columns.push(
          new ForagerTableColumn({
            header: "Time Spent",
            ValueFunction: (item) => {
              return (
                item?.calculatedTotalTime?.TotalTimeRounded15?.toFixed(2) +
                " hours"
              );
            },
            sortable: true,
            sortType: "number",
            isDefaultSort: true,
            sortValueFunction: (item) => {
              return item?.calculatedTotalTime?.TotalTimeRounded15?.toFixed(2);
            },
          })
        );
        break;
      default:
        break;
    }
    return columns;
  }

  GetSubTableColumns() {
    switch (this.category) {
      case 1:
        return this.GetUserSubTableColumns();
      case 2:
      case 3:
        return null;
      default:
        return null;
    }
  }

  GetUserSubTableColumns() {
    let columns = null;
    switch (this.type) {
      case 1:
        columns = this.ReportColumns.GetTimeEntryColumns();
        columns.push(
          new ForagerTableColumn({
            header: "Hours",
            ValueFunction: (item) => {
              return item.lengthInHoursRounded15.toFixed(2) + " hours";
            },
            isDefaultSort: true,
            sortable: true,
            sortType: "number",
            sortValueFunction: (item) => {
              return item.lengthInHoursRounded15;
            },
          })
        );
        break;
      case 2:
        columns = this.ReportColumns.GetTimeEntryColumns();
        columns.push(
          new ForagerTableColumn({
            header: "Hours",
            ValueFunction: (item) => {
              return item.lengthInHours.toFixed(2) + " hours";
            },
            sortable: true,
            sortPath: ["lengthInHours"],
          })
        );
        break;
      case 3:
        break;
      default:
        break;
    }
    return columns;
  }

  // GetScheduleItemSubTableColumns() {
  //   return null;
  // }

  GetSubTablePathArray() {
    switch (this.category) {
      case 1:
        switch (this.type) {
          case 1:
          case 2:
            return "timeEntries";
          case 3:
            return ["scheduleItems"];
          default:
            return [];
        }
      case 2:
      case 3:
        return ["scheduleItems"];
      default:
        return [];
    }
  }
}

const jobTaskTypes = [
  { id: 4, name: "Time To Close" },
  { id: 5, name: "Days Remaining" },
  { id: 6, name: "Days Overdue" },
  { id: 7, name: "Time Spent" },
];

export const ForagerReportInfo = {
  GetReportCategories: (fieldCustomizations) => [
    {
      id: 1,
      name: GetReportCategoryName(fieldCustomizations, 1),
      types: [
        { id: 1, name: "Time Spent" },
        { id: 2, name: "Payroll" },
        { id: 3, name: "All Time" },
      ],
    },
    {
      id: 2,
      name: GetReportCategoryName(fieldCustomizations, 2),
      types: jobTaskTypes,
    },
    {
      id: 3,
      name: GetReportCategoryName(fieldCustomizations, 3),
      types: jobTaskTypes,
    },
  ],
  dateRangeTypes: [
    { id: 1, name: "Specific Dates" },
    { id: 2, name: "Monthly" },
  ],
  months: [
    { id: 0, name: "January" },
    { id: 1, name: "February" },
    { id: 2, name: "March" },
    { id: 3, name: "April" },
    { id: 4, name: "May" },
    { id: 5, name: "June" },
    { id: 6, name: "July" },
    { id: 7, name: "August" },
    { id: 8, name: "September" },
    { id: 9, name: "October" },
    { id: 10, name: "November" },
    { id: 11, name: "December" },
  ],
  years: GetYears(),
  GetMonthEnds: (month, year) => {
    var monthEnds = [{ id: 0, name: "Last" }];
    var lastDay = new Date(year, month + 1, 0);
    for (var i = 1; i <= lastDay.getDate(); i++) {
      let monthEnd = { id: i, name: i + "th" };
      if (i === 1) monthEnd.name = i + "st";
      if (i === 2) monthEnd.name = i + "nd";
      if (i === 3) monthEnd.name = i + "rd";
      if (i === 21) monthEnd.name = i + "st";
      if (i === 31) monthEnd.name = i + "st";
      monthEnds.push(monthEnd);
    }
    return monthEnds;
  },
};

function GetReportCategoryName(fieldCustomizations, categoryId) {
  switch (categoryId) {
    case 1:
      return fieldCustomizations.general.user.name;
    case 2:
      return fieldCustomizations.general.job.name;
    case 3:
      return fieldCustomizations.general.task.name;
    default:
      return "";
  }
}

function GetYears() {
  let yearsArray = [];
  let currentYear = new Date().getUTCFullYear();
  for (let i = 0; i < 10; i++) {
    yearsArray.push({
      id: currentYear - i,
      name: (currentYear - i).toString(),
    });
  }
  return yearsArray;
}

export default ForagerReport;
