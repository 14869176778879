import { Icon } from "@iconify/react";
import React from "react";

function DateRange(props) {
  function ItemsBeforeIndicatorClicked() {
    let newStartDate = new Date(props.startDate);
    newStartDate.setDate(newStartDate.getDate() - 60);
    props.ChangeStartDate(newStartDate.toISOString().split("T")[0]);
  }

  function ChangeStartDate(e) {
    props.ChangeStartDate(e.target.value);
  }

  function ChangeEndDate(e) {
    props.ChangeEndDate(e.target.value);
  }

  return (
    <div
      className={
        "dateRangeContainer" + (props.expanded ? "" : " hiddenOnSmall")
      }
    >
      <div></div>
      <div className="dateRangeTitle">
        {props.itemsBefore > 0 ? (
          <Icon
            className="itemsBeforeIndicator"
            icon="ic:sharp-crisis-alert"
            data-tip={
              props.itemsBefore +
              " items fit your filters within 60 days before your start date.<br>Click to expand range."
            }
            data-multiline={true}
            onClick={ItemsBeforeIndicatorClicked}
          />
        ) : null}
        Date Range
      </div>
      <div className="dateRangeSelectorContainer">
        <input
          className="dateRangeInput"
          type="date"
          value={props.startDate}
          onChange={ChangeStartDate}
        />
        <div className="dateRangeSelectorTo">to</div>
        <input
          className="dateRangeInput"
          type="date"
          value={props.endDate}
          onChange={ChangeEndDate}
        />
      </div>
    </div>
  );
}

export default DateRange;
