import React, { useEffect, useState } from "react";
import { useSiteContext } from "../../../../../../../Context";
import ForagerTable from "../../../../../../../Generic/ForagerTable";
import ForagerTableColumn from "../../../../../../../Classes/ForagerTableColumn";
import DropDownMenu from "../../../../../../../Generic/DropDownMenu";

function TimeDashboardModal(props) {
  const { fieldCustomizations, GetData, loggedInUser } = useSiteContext();
  const [timeEntries, SetTimeEntries] = useState([]);
  const [filteredTimeEntries, SetFilteredTimeEntries] = useState([]);
  const [timeEntriesUsers, SetTimeEntriesUsers] = useState([]);
  const [timeEntriesClients, SetTimeEntriesClients] = useState([]);
  const [timeEntriesJobs, SetTimeEntriesJobs] = useState([]);
  const [timeEntriesTasks, SetTimeEntriesTasks] = useState([]);
  const [userIdFilteredTo, SetUserIdFilteredTo] = useState("");
  const [clientIdFilteredTo, SetClientIdFilteredTo] = useState(0);
  const [jobIdFilteredTo, SetJobIdFilteredTo] = useState(0);
  const [taskIdFilteredTo, SetTaskIdFilteredTo] = useState(0);
  const [clientIdsFilteredOut, SetClientIdsFilteredOut] = useState([]);
  const [monthStart, SetMonthStart] = useState();

  const [availableMonths, SetAvailableMonths] = useState();
  // const [loading, SetLoading] = useState(true);

  function GetAvailableMonths() {
    let months = [];
    let currentMonthStart = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      1
    );
    for (let i = 0; i < 12; i++) {
      months.push({
        id: new Date(currentMonthStart).toISOString(),
        name: currentMonthStart.toLocaleDateString("default", {
          month: "long",
          year: "numeric",
        }),
      });
      currentMonthStart.setMonth(currentMonthStart.getMonth() - 1);
    }
    SetAvailableMonths(months);
    SetMonthStart(months[0].id);
  }

  async function GetAllTimeEntries() {
    if (monthStart == null) {
      return;
    }
    // SetLoading(true);
    let monthStartDate = new Date(monthStart);
    let monthEndDate = new Date(
      monthStartDate.getFullYear(),
      monthStartDate.getMonth() + 1,
      1
    );

    let result = await GetData("TimeEntry", {
      customerId: loggedInUser.customer.id,
      orderBy: "startTime",
      descending: true,
      startDate: monthStartDate.toLocaleDateString(),
      endDate: monthEndDate.toLocaleDateString(),
    });
    if (Array.isArray(result)) {
      SetTimeEntries(result);
    }
    // SetLoading(false);
  }

  function ChangeFilteredUser(userId) {
    if (userId === userIdFilteredTo) {
      SetUserIdFilteredTo("");
      return;
    }
    SetUserIdFilteredTo(userId);
  }

  function ChangeFilteredClient(clientId) {
    if (clientId === clientIdFilteredTo) {
      SetClientIdFilteredTo(0);
      return;
    }
    SetClientIdFilteredTo(clientId);
  }

  function ChangeFilteredJob(jobId) {
    if (jobId === jobIdFilteredTo) {
      SetJobIdFilteredTo(0);
      return;
    }
    SetJobIdFilteredTo(jobId);
  }

  function ChangeFilteredTask(task) {
    if (task === taskIdFilteredTo) {
      SetTaskIdFilteredTo(0);
      return;
    }
    SetTaskIdFilteredTo(task);
  }

  function FilterTimeEntries() {
    let filtered = timeEntries;
    if (userIdFilteredTo !== "") {
      filtered = filtered.filter((t) => t.user.id === userIdFilteredTo);
    }
    if (clientIdFilteredTo > 0) {
      filtered = filtered.filter(
        (t) =>
          t.client?.id === clientIdFilteredTo ||
          t.scheduleItem?.job?.client?.id === clientIdFilteredTo ||
          t.scheduleItem?.client?.id === clientIdFilteredTo
      );
    }
    if (jobIdFilteredTo > 0) {
      filtered = filtered.filter(
        (t) =>
          t.scheduleItem?.job?.id === jobIdFilteredTo ||
          t.scheduleItem?.id === jobIdFilteredTo
      );
    }
    if (taskIdFilteredTo > 0) {
      filtered = filtered.filter((t) => {
        if (isNaN(taskIdFilteredTo)) {
          return t.client?.name + " - " + t.notes === taskIdFilteredTo;
        }
        return t.scheduleItem?.id === taskIdFilteredTo;
      });
    }
    if (isNaN(taskIdFilteredTo)) {
      filtered = filtered.filter((t) => {
        if (t.client == null) {
          return false;
        }
        return t.client?.name + " - " + t.notes === taskIdFilteredTo;
      });
    }
    if (clientIdsFilteredOut.length > 0) {
      filtered = filtered.filter(
        (t) =>
          !clientIdsFilteredOut.includes(t.client?.id) &&
          !clientIdsFilteredOut.includes(t.scheduleItem?.job?.client?.id) &&
          !clientIdsFilteredOut.includes(t.scheduleItem?.client?.id)
      );
    }
    SetFilteredTimeEntries(filtered);
  }

  function ProcessUserInfo() {
    let users = [];
    filteredTimeEntries.forEach((timeEntry) => {
      let user = users.find((user) => user.id === timeEntry.user.id);
      if (!user) {
        let newUser = timeEntry.user;
        newUser.timeEntries = filteredTimeEntries.filter(
          (t) => t.user.id === newUser.id
        );
        newUser.totalHours = 0;
        newUser.timeEntries.forEach((t) => {
          if (!isNaN(t.lengthInHoursRounded15)) {
            newUser.totalHours += +t.lengthInHoursRounded15;
          }
        });
        newUser.hourGoal =
          timeEntry.user.hourGoal === 0
            ? newUser.totalHours
            : timeEntry.user.hourGoal;
        newUser.percentage = (
          (newUser.totalHours / newUser.hourGoal) *
          100
        ).toFixed(1);
        users.push(newUser);
      }
    });
    SetTimeEntriesUsers(users);
  }

  function ProcessClientInfo() {
    let clients = [];
    filteredTimeEntries.forEach((timeEntry) => {
      let client = timeEntry.client;
      if (client == null) {
        client = timeEntry.scheduleItem?.job?.client;
        if (client == null) {
          client = timeEntry.scheduleItem?.client;
        }
      }
      let currentClient = clients.find((c) => c.id === client.id);
      if (!currentClient) {
        let newClient = {};
        newClient.id = client.id;
        newClient.name = client.name;
        newClient.timeEntries = [];
        newClient.totalHours = 0;
        currentClient = newClient;
        clients.push(newClient);
      }
      currentClient.timeEntries.push(timeEntry);
    });
    clients.forEach((client) => {
      client.timeEntries.forEach((t) => {
        if (!isNaN(t.lengthInHoursRounded15)) {
          client.totalHours += +t.lengthInHoursRounded15;
        }
      });
    });
    SetTimeEntriesClients(clients);
  }

  function ProcessJobInfo() {
    let jobs = [];
    filteredTimeEntries.forEach((timeEntry) => {
      let job = timeEntry.scheduleItem?.job;
      if (job == null) {
        job = timeEntry.scheduleItem;
      }
      if (job == null) {
        return;
      }
      let currentJob = jobs.find((j) => j.id === job.id);
      if (!currentJob) {
        let newJob = {};
        newJob.id = job.id;
        newJob.name = job.name;
        newJob.timeEntries = [];
        newJob.totalHours = 0;
        newJob.budgetedTime = job.budgetedTime;
        currentJob = newJob;
        jobs.push(newJob);
      }
      currentJob.timeEntries.push(timeEntry);
    });
    jobs.forEach((job) => {
      job.timeEntries.forEach((t) => {
        if (!isNaN(t.lengthInHoursRounded15)) {
          job.totalHours += +t.lengthInHoursRounded15;
        }
      });
    });
    SetTimeEntriesJobs(jobs);
  }

  function ProcessTaskInfo() {
    let tasks = [];
    filteredTimeEntries.forEach((timeEntry) => {
      let task = timeEntry.scheduleItem;
      if (task == null && timeEntry.client != null) {
        let ticketId = timeEntry.client.name + " - " + timeEntry.notes;
        let currentTicket = tasks.find((t) => t.id === ticketId);
        if (!currentTicket) {
          let newTicket = {};
          newTicket.id = ticketId;
          newTicket.name = timeEntry.notes;
          newTicket.job = "Ticket: " + timeEntry.client.name;
          newTicket.timeEntries = [];
          newTicket.totalHours = 0;
          newTicket.budgetedTime = 0;
          currentTicket = newTicket;
          tasks.push(newTicket);
        }
        currentTicket.timeEntries.push(timeEntry);
        return;
      }
      if (task.scheduleItemTypeId !== 2) {
        return;
      }
      let currentTask = tasks.find((t) => t.id === task.id);
      if (!currentTask) {
        let newTask = {};
        newTask.id = task.id;
        newTask.name = task.name;
        newTask.job = "(NONE)";
        if (task.job != null) {
          newTask.job = task.job.name;
        }
        newTask.timeEntries = [];
        newTask.totalHours = 0;
        newTask.budgetedTime = task.budgetedTime;
        currentTask = newTask;
        tasks.push(newTask);
      }
      currentTask.timeEntries.push(timeEntry);
    });
    tasks.forEach((task) => {
      task.timeEntries.forEach((t) => {
        if (!isNaN(t.lengthInHoursRounded15)) {
          task.totalHours += +t.lengthInHoursRounded15;
        }
      });
    });
    SetTimeEntriesTasks(tasks);
  }

  function GetPercentageBarBackground(percentage) {
    let percentageDecimal = percentage / 100;
    let hue = (percentageDecimal * 120).toString(10);
    return ["hsl(", hue, ",100%,50%)"].join("");
  }

  var userTableColumns = [
    new ForagerTableColumn({
      header: fieldCustomizations.general.user.name,
      property: "name",
      sortable: true,
      CellOnClick: (user) => ChangeFilteredUser(user.id),
    }),
    new ForagerTableColumn({
      header: "Total Hours / Goal",
      ValueFunction: (user) => user.totalHours + " / " + user.hourGoal,
    }),
    new ForagerTableColumn({
      header: "Progress",
      ValueFunction: (user) => {
        return (
          <>
            <div
              className="percentageBar"
              style={{
                width: user.percentage <= 100 ? user.percentage + "%" : "100%",
                backgroundColor: GetPercentageBarBackground(
                  user.percentage <= 100 ? user.percentage : 100
                ),
              }}
            ></div>
            <div className="percentageNumber">{user.percentage + "%"}</div>
          </>
        );
      },
      stretch: true,
      cellClass: "fullCell",
      sortable: true,
      sortValueFunction: (user) => user.totalHours / user.hourGoal,
      isDefaultSort: true,
    }),
  ];

  var clientTableColumns = [
    new ForagerTableColumn({
      header: "All",
      ValueFunction: (client) => {
        return "-";
      },
      CellOnClick: (client) => {
        if (clientIdsFilteredOut.includes(client.id)) {
          SetClientIdsFilteredOut(
            clientIdsFilteredOut.filter((c) => c !== client.id)
          );
        } else {
          SetClientIdsFilteredOut([...clientIdsFilteredOut, client.id]);
        }
      },
      HeaderOnClick: () => {
        SetClientIdsFilteredOut([]);
      },
    }),
    new ForagerTableColumn({
      header: "Client",
      property: "name",
      sortable: true,
      stretch: true,
      totalRowHeader: true,
      CellOnClick: (client) => ChangeFilteredClient(client.id),
    }),
    new ForagerTableColumn({
      header: "Total Hours",
      property: "totalHours",
      sortable: true,
      isDefaultSort: true,
      isTotaled: true,
    }),
  ];

  var jobTableColumns = [
    new ForagerTableColumn({
      header: "Job",
      property: "name",
      sortable: true,
      stretch: true,
      totalRowHeader: true,
      CellOnClick: (job) => ChangeFilteredJob(job.id),
    }),
    new ForagerTableColumn({
      header: "Total Hours",
      property: "totalHours",
      sortable: true,
      isDefaultSort: true,
      isTotaled: true,
    }),
    new ForagerTableColumn({
      header: "Budgeted Time",
      property: "budgetedTime",
    }),
  ];

  var taskTableColumns = [
    new ForagerTableColumn({
      header: "Job",
      property: "job",
      sortable: true,
      stretch: true,
      totalRowHeader: true,
    }),
    new ForagerTableColumn({
      header: "Task",
      property: "name",
      sortable: true,
      stretch: true,
      CellOnClick: (task) => ChangeFilteredTask(task.id),
    }),
    new ForagerTableColumn({
      header: "Total Hours",
      property: "totalHours",
      sortable: true,
      isDefaultSort: true,
      isTotaled: true,
    }),
    new ForagerTableColumn({
      header: "Budgeted Time",
      property: "budgetedTime",
    }),
  ];

  useEffect(() => {
    ProcessUserInfo();
    ProcessClientInfo();
    ProcessJobInfo();
    ProcessTaskInfo();

    // eslint-disable-next-line
  }, [filteredTimeEntries]);

  useEffect(() => {
    FilterTimeEntries();

    // eslint-disable-next-line
  }, [
    timeEntries,
    userIdFilteredTo,
    clientIdFilteredTo,
    jobIdFilteredTo,
    taskIdFilteredTo,
    clientIdsFilteredOut,
  ]);

  useEffect(() => {
    GetAllTimeEntries();

    // eslint-disable-next-line
  }, [monthStart]);

  useEffect(() => {
    GetAvailableMonths();

    // eslint-disable-next-line
  }, []);

  return (
    <div className="timeDashboardContainer">
      <div className="adminModalTitleBar threeSection">
        <DropDownMenu
          options={availableMonths}
          value={monthStart}
          onChange={(month) => {
            SetMonthStart(month);
          }}
          dontSort={true}
        />
        <div className="adminModalTitle">Monthly Time</div>
        <div></div>
      </div>
      <div className="timeDashboardBody">
        <div className="timeDashboardSection">
          <ForagerTable
            columns={userTableColumns}
            items={timeEntriesUsers}
            highlightedId={userIdFilteredTo}
          />
        </div>
        <div className="timeDashboardSection">
          <ForagerTable
            columns={jobTableColumns}
            items={timeEntriesJobs}
            hasTotalRow={true}
            highlightedId={jobIdFilteredTo}
          />
        </div>
        <div className="timeDashboardSection">
          <ForagerTable
            columns={clientTableColumns}
            items={timeEntriesClients}
            hasTotalRow={true}
            highlightedId={clientIdFilteredTo}
          />
        </div>
        <div className="timeDashboardSection">
          <ForagerTable
            columns={taskTableColumns}
            items={timeEntriesTasks}
            hasTotalRow={true}
            highlightedId={taskIdFilteredTo}
          />
        </div>
      </div>
    </div>
  );
}

export default TimeDashboardModal;
