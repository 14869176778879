import React, { useState, useEffect } from "react";
import { useSiteContext } from "../../../../../../Context";
import TimeEntry from "./TimeEntry";
import AddEditTimeModal from "./AddEditTimeModal";
import Modal from "../../../../../../Generic/Modal";
import DropDownMenu from "../../../../../../Generic/DropDownMenu";
import TimeClockActions from "../../../../../TimeClockActions";
import { Icon } from "@iconify/react";

function TimeClockModal(props) {
  const [addEditTime, SetAddEditTime] = useState(false);
  const [filteringClientBy, SetFilteringClientBy] = useState(null);
  const [filteringJobBy, SetFilteringJobBy] = useState(null);
  const [filteringTaskBy, SetFilteringTaskBy] = useState(null);
  const [filteredTimeEntries, SetFilteredTimeEntries] = useState([]);
  const [showFilters, SetShowFilters] = useState(false);
  const [timeEntryToCopy, SetTimeEntryToCopy] = useState(null);
  const [totalForCurrentMonth, SetTotalForCurrentMonth] = useState(0);
  const [totalForLastMonth, SetTotalForLastMonth] = useState(0);
  const {
    AddAlert,
    timeEntries,
    GetTimeEntries,
    currentTimeEntry,
    UpdateData,
    fieldCustomizations,
  } = useSiteContext();

  function GetClientsInTimeEntries() {
    let clientsInTimeEntries = [];
    timeEntries.forEach((entry) => {
      if (
        entry.scheduleItem?.client != null &&
        !clientsInTimeEntries.some(
          (client) => client.id === entry.scheduleItem.client.id
        )
      ) {
        clientsInTimeEntries.push(entry.scheduleItem.client);
      } else if (
        entry.scheduleItem?.job?.client != null &&
        !clientsInTimeEntries.some(
          (client) => client.id === entry.scheduleItem.job.client.id
        )
      ) {
        clientsInTimeEntries.push(entry.scheduleItem.job.client);
      }
    });
    return clientsInTimeEntries;
  }

  function GetJobsInTimeEntries() {
    let jobsInTimeEntries = [];
    timeEntries.forEach((entry) => {
      if (
        entry.scheduleItem != null &&
        entry.scheduleItem.scheduleItemTypeId === 1 &&
        !jobsInTimeEntries.some((job) => job.id === entry.scheduleItem.id)
      ) {
        jobsInTimeEntries.push(entry.scheduleItem);
      } else if (
        entry.scheduleItem != null &&
        entry.scheduleItem.scheduleItemTypeId === 2 &&
        entry.scheduleItem.job != null &&
        !jobsInTimeEntries.some((job) => job.id === entry.scheduleItem.job.id)
      ) {
        jobsInTimeEntries.push(entry.scheduleItem.job);
      }
    });
    return jobsInTimeEntries;
  }

  function GetTasksInTimeEntries() {
    let tasksInTimeEntries = [];
    timeEntries.forEach((entry) => {
      if (
        entry.scheduleItem != null &&
        entry.scheduleItem.scheduleItemTypeId === 2 &&
        !tasksInTimeEntries.some((task) => task.id === entry.scheduleItem.id)
      ) {
        tasksInTimeEntries.push(entry.scheduleItem);
      }
    });
    return tasksInTimeEntries;
  }

  function ShowFilters() {
    SetShowFilters(!showFilters);
  }

  function AddEditTime(timeEntry) {
    SetTimeEntryToCopy(timeEntry);
    SetAddEditTime(!addEditTime);
  }

  function FilterByClient(client) {
    SetFilteringClientBy(client);
  }

  function FilterByJob(job) {
    SetFilteringJobBy(job);
  }

  function FilterByTask(task) {
    SetFilteringTaskBy(task);
  }

  function FilterTimeEntries() {
    let newFilteredTimeEntries = timeEntries.slice();
    if (filteringClientBy != null) {
      newFilteredTimeEntries = newFilteredTimeEntries.filter((entry) => {
        return (
          (entry.scheduleItem?.client != null &&
            entry.scheduleItem.client.id === filteringClientBy) ||
          (entry.client != null && entry.client.id === filteringClientBy)
        );
      });
    }
    if (filteringJobBy != null) {
      newFilteredTimeEntries = newFilteredTimeEntries.filter((entry) => {
        return (
          (entry.scheduleItem != null &&
            entry.scheduleItem.id === filteringJobBy) ||
          (entry.scheduleItem != null &&
            entry.scheduleItem.job != null &&
            entry.scheduleItem.job.id === filteringJobBy)
        );
      });
    }
    if (filteringTaskBy != null) {
      newFilteredTimeEntries = newFilteredTimeEntries.filter((entry) => {
        return (
          entry.scheduleItem != null &&
          entry.scheduleItem.id === filteringTaskBy
        );
      });
    }

    newFilteredTimeEntries = InsertDayDividers(newFilteredTimeEntries);

    SetFilteredTimeEntries(newFilteredTimeEntries);
  }

  async function ClockOut() {
    let endTime = new Date();
    endTime.setSeconds(0, 0);
    let formData = new FormData();
    formData.append("id", currentTimeEntry.id);
    formData.append("endTime", endTime.toISOString());
    let response = await UpdateData("TimeEntry", formData);
    if (!response.includes("Success")) {
      AddAlert("error", "There was an error clocking out. Please try again.");
    }
    GetTimeEntries();
  }

  function GetTotalForMonths() {
    let currentMonthTotal = 0;
    let lastMonthTotal = 0;

    let currentMonthStart = new Date();
    currentMonthStart.setDate(1);
    currentMonthStart.setHours(0, 0, 0, 0);

    let lastMonthStart = structuredClone(currentMonthStart);
    lastMonthStart.setMonth(currentMonthStart.getMonth() - 1);

    filteredTimeEntries.forEach((entry) => {
      let entryEndDate = new Date(entry.endTime);
      if (
        entry.endTime != null &&
        entry.endTime !== "" &&
        entryEndDate >= lastMonthStart
      ) {
        if (entryEndDate >= currentMonthStart) {
          currentMonthTotal += entry.lengthInHoursRounded15;
        } else {
          lastMonthTotal += entry.lengthInHoursRounded15;
        }
      }
    });

    SetTotalForCurrentMonth(currentMonthTotal);
    SetTotalForLastMonth(lastMonthTotal);
  }

  function InsertDayDividers(timeEntriesToDivide) {
    let newTimeEntries = [];
    let lastEntry = null;
    timeEntriesToDivide.forEach((entry) => {
      if (
        lastEntry == null ||
        new Date(lastEntry.startTime).toLocaleDateString() !==
          new Date(entry.startTime).toLocaleDateString()
      ) {
        let dayDivider = {
          id: "dayDivider" + entry.id,
          name: new Date(entry.startTime).toLocaleDateString(),
          dayDivider: true,
          date: entry.startTime,
          totalForDay: 0,
        };
        for (let i = 0; i < timeEntriesToDivide.length; i++) {
          let timeEntry = timeEntriesToDivide[i];
          if (
            new Date(timeEntry.startTime).toLocaleDateString() ===
            new Date(dayDivider.date).toLocaleDateString()
          ) {
            dayDivider.totalForDay += timeEntry.lengthInHoursRounded15;
          }
        }
        newTimeEntries.push(dayDivider);
      }
      newTimeEntries.push(entry);
      lastEntry = entry;
    });
    return newTimeEntries;
  }

  useEffect(() => {
    if (timeEntries != null) {
      FilterTimeEntries();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeEntries, filteringClientBy, filteringJobBy, filteringTaskBy]);

  useEffect(() => {
    GetTotalForMonths();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredTimeEntries]);

  useEffect(() => {
    GetTimeEntries();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="timeClockModalContainer">
        <div className="timeClockModalButtonsTop">
          <TimeClockActions
            showText={true}
            showTimer={true}
            ClockInFunction={AddEditTime}
            ClockOutFunction={ClockOut}
            totalForCurrentMonth={totalForCurrentMonth}
            totalForLastMonth={totalForLastMonth}
          />
        </div>
        <div className="timeClockModalBodyContainer">
          <div className="timeClockModalBodyRight">
            <div className="timeClockModalBodyRightItems">
              <div className="timeEntryContainer header">
                <div className="timeEntryItem name">
                  {fieldCustomizations.general.job.name}
                </div>
                <div className="timeEntryItem name">
                  {fieldCustomizations.general.task.name}
                </div>
                <div className="timeEntryItem">In</div>
                <div className="timeEntryItem">Out</div>
                <div className="timeEntryItem">Hours</div>
                <div className="timeEntryItem"></div>
              </div>
              {filteredTimeEntries.map((timeEntry) => (
                <TimeEntry
                  key={timeEntry.id}
                  timeEntry={timeEntry}
                  AddEditTime={AddEditTime}
                  jobsForFilter={props.jobsForFilter}
                  usersForFilter={props.usersForFilter}
                  tasksForFilter={props.tasksForFilter}
                  CloseModal={props.CloseModal}
                  ToggleIQOpen={props.ToggleIQOpen}
                />
              ))}
            </div>
          </div>
          <div className="relativeAnchor">
            <div
              className={
                "timeClockModalBodyFilters" +
                (showFilters ? " showFilters" : "")
              }
            >
              <div className="timeClockModalTitle" onClick={ShowFilters}>
                {showFilters ? "Filter Entries" : ""}
                <Icon className="filterIcon" icon="mdi:filter" />
              </div>
              {showFilters ? (
                <>
                  <div className="timeClockModalDropDownContainer">
                    <DropDownMenu
                      className="timeClockModalDropDown"
                      options={GetClientsInTimeEntries()}
                      onChange={FilterByClient}
                      value={filteringClientBy}
                      showBlank={true}
                      noneSelectedLabel={
                        "All " + fieldCustomizations.general.client.pluralName
                      }
                    />
                  </div>
                  <div className="timeClockModalDropDownContainer">
                    <DropDownMenu
                      className="timeClockModalDropDown"
                      options={GetJobsInTimeEntries()}
                      onChange={FilterByJob}
                      value={filteringJobBy}
                      showBlank={true}
                      noneSelectedLabel={
                        "All " + fieldCustomizations.general.job.pluralName
                      }
                    />
                  </div>
                  <div className="timeClockModalDropDownContainer">
                    <DropDownMenu
                      className="timeClockModalDropDown"
                      options={GetTasksInTimeEntries()}
                      onChange={FilterByTask}
                      value={filteringTaskBy}
                      showBlank={true}
                      noneSelectedLabel={
                        "All " + fieldCustomizations.general.task.pluralName
                      }
                    />
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      {addEditTime && (
        <Modal CloseModal={AddEditTime} modificationClass="noPaddingAlternate">
          <AddEditTimeModal
            CloseModal={AddEditTime}
            mode="Add"
            jobsForFilter={props.jobsForFilter}
            usersForFilter={props.usersForFilter}
            tasksForFilter={props.tasksForFilter}
            timeEntryToCopy={timeEntryToCopy}
          />
        </Modal>
      )}
    </>
  );
}

export default TimeClockModal;
