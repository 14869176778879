import React, { useState, useRef, useEffect } from "react";
import { Document, Page } from "react-pdf";
import { Icon } from "@iconify/react";
import HQLogoSVG from "./HQLogoSVG";

function PDFViewer(props) {
  const [numPages, setNumPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(props.startPage || 1);
  const [isLoaded, setIsLoaded] = useState(false);
  const pdfViewerContainerRef = useRef(null);

  function HandleDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function HandlePageLoadSuccess(e) {
    setIsLoaded(true);
  }

  function HandleKeyPress(event) {
    if (event.key === "ArrowLeft" && currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    } else if (event.key === "ArrowRight" && currentPage < numPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  }

  useEffect(() => {
    if (!props.isPreview) {
      pdfViewerContainerRef.current.focus();
    }
  });

  return props.isPreview ? (
    <>
      <Document
        file={props.file}
        loading={
          <div className="logo spinning pdfViewerLogo" alt="Loading Animation">
            <HQLogoSVG full={false} />
          </div>
        }
      >
        <Page
          pageNumber={props.pageNumber || 1}
          renderAnnotationLayer={false}
          renderTextLayer={false}
          loading={
            <div
              className="logo spinning pdfViewerLogo"
              alt="Loading Animation"
            >
              <HQLogoSVG full={false} />
            </div>
          }
          canvasBackground={!isLoaded ? "transparent" : ""}
          onLoadSuccess={HandlePageLoadSuccess}
        />
      </Document>
    </>
  ) : (
    <div
      className="pdfViewerModal"
      tabIndex={0}
      onKeyDown={HandleKeyPress}
      ref={pdfViewerContainerRef}
    >
      <div className="pdfViewerDocument">
        {isLoaded && (
          <div className="pdfViewerControlsContainer">
            <div
              className={
                currentPage <= 1
                  ? "pdfButtons pdfPreviousPage disabled"
                  : "pdfButtons pdfPreviousPage"
              }
              onClick={() => setCurrentPage((prevPage) => prevPage - 1)}
            >
              <Icon icon={"material-symbols:chevron-left-rounded"} />
            </div>

            <div className="pdfViewerPageNumbers">
              Page {currentPage} of {numPages}
            </div>
            <div
              className={
                currentPage === numPages
                  ? "pdfButtons pdfNextPage disabled"
                  : "pdfButtons pdfNextPage"
              }
              onClick={() => setCurrentPage((prevPage) => prevPage + 1)}
            >
              <Icon icon={"material-symbols:chevron-right-rounded"} />
            </div>
            <div className="pdfViewerDownloadButton">
              <a
                href={props.file}
                download
                target="_blank"
                rel="noreferrer"
                aria-hidden="true"
                alt="Download Document"
              >
                <Icon
                  icon="material-symbols:download"
                  className="pdfViewerDownloadIcon"
                />
              </a>
            </div>
          </div>
        )}
        <Document
          file={props.file}
          onLoadSuccess={HandleDocumentLoadSuccess}
          loading={
            <div
              className="logo spinning pdfViewerLogo"
              alt="Loading Animation"
            >
              <HQLogoSVG full={false} />
            </div>
          }
        >
          <Page
            pageNumber={currentPage}
            renderAnnotationLayer={false}
            renderTextLayer={false}
            onLoadSuccess={HandlePageLoadSuccess}
            className={"pdfViewerPage"}
            loading={
              <div
                className="logo spinning pdfViewerLogo"
                alt="Loading Animation"
              >
                <HQLogoSVG full={false} />
              </div>
            }
            canvasBackground={!isLoaded ? "transparent" : ""}
          />
        </Document>
      </div>
    </div>
  );
}

export default PDFViewer;
