import React, { useContext, useEffect, useState } from "react";
import * as SignalR from "@microsoft/signalr";
import * as Constants from "./Constants";
import axios from "axios";
import Customizations from "./Classes/Customizations";
import ReactTooltip from "react-tooltip";

const CurrentContext = React.createContext();

function Context({ children }) {
  const [alertArray, SetAlertArray] = useState([]);
  const [mainUser, SetMainUser] = useState(null);
  const [impersonatedUser, SetImpersonatedUser] = useState(null);
  const [hubConnection, SetHubConnection] = useState(null);
  const [refreshSignal, SetRefreshSignal] = useState("");
  const [darkMode, SetDarkMode] = useState(false);
  const [colorScheme, SetColorScheme] = useState("green");
  const [timeEntries, SetTimeEntries] = useState([]);
  const [currentTimeEntry, SetCurrentTimeEntry] = useState(null);
  //eslint-disable-next-line
  const [randomSessionKey, SetRandomSessionKey] = useState(
    GenerateRandomSessionKey()
  );
  const [showMainLoadingScreen, SetShowMainLoadingScreen] = useState(false);
  const [clients, SetClients] = useState([]);
  const [users, SetUsers] = useState([]);
  const [statuses, SetStatuses] = useState([]);
  const [customerSettings, SetCustomerSettings] = useState(null);

  const impersonating = impersonatedUser != null;
  const loggedInUser = impersonating ? impersonatedUser : mainUser;
  const darkModeVerbage = darkMode ? "dark" : "light";

  const [fieldCustomizations, SetFieldCustomizations] = useState(
    new Customizations(loggedInUser, customerSettings)
  );

  function GenerateRandomSessionKey() {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < 20; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  function AddAlert(type, message) {
    let id = new Date().getTime();
    var alert = {
      id: id,
      type: type,
      message: message,
    };
    SetAlertArray((alertArrayPrev) => {
      if (alertArrayPrev.length < 5) {
        return [...alertArrayPrev, alert];
      }
      let idArray = alertArrayPrev.map((alert) => alert.id);
      return [
        ...alertArrayPrev.filter((a) => a.id !== Math.min(...idArray)),
        alert,
      ];
    });
    setTimeout(() => {
      RemoveAlert(alert.id);
    }, 30000);
  }

  function RemoveAlert(id) {
    SetAlertArray((alertArrayPrev) =>
      alertArrayPrev.filter((alert) => alert.id !== id)
    );
  }

  function SendSignal(target, message, messageType) {
    var formData = new FormData();
    formData.append("customer", loggedInUser.customer.id);
    formData.append("customerId", loggedInUser.customer.id);
    formData.append("message", message + " - " + randomSessionKey);
    formData.append("messageType", messageType);
    formData.append("target", target);
    formData.append("initiatingUser", randomSessionKey);

    axios({
      method: "post",
      url: Constants.API_URL + "Signal" + Constants.API_KEY,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    });
  }

  function FeatureCheck(feature) {
    let featureId = Constants.features[feature];
    let hasFeature =
      loggedInUser?.permissions != null &&
      loggedInUser?.permissions.some(
        (permission) => permission.section.featureId === featureId
      );
    return hasFeature;
  }

  function PermissionCheck(section, type, id = null) {
    let sectionId = isNaN(section)
      ? Constants.permissionSections[section]
      : section;
    let typeId = isNaN(type) ? Constants.permissionTypes[type] : type;
    let permission = null;
    let permissionLambda = null;
    if (id != null) {
      permissionLambda = (p) =>
        p.sectionId === sectionId && p.type === typeId && p.id === id;
    } else {
      permissionLambda = (p) => p.sectionId === sectionId && p.type === typeId;
    }
    permission =
      loggedInUser?.permissions != null &&
      loggedInUser?.permissions.find(permissionLambda);
    return permission != null;
  }

  function DarkModeSwitch(isOn) {
    if (isOn) {
      localStorage.setItem("foragerTheme", colorScheme + "-dark");
    } else {
      localStorage.setItem("foragerTheme", colorScheme + "-light");
    }
    SetDarkMode(isOn);
  }

  function ColorSchemeSwitch() {
    switch (colorScheme) {
      case "green":
        SetColorScheme("blue");
        localStorage.setItem("foragerTheme", "blue-" + darkModeVerbage);
        break;
      case "blue":
        SetColorScheme("orange");
        localStorage.setItem("foragerTheme", "orange-" + darkModeVerbage);
        break;
      case "orange":
        SetColorScheme("green");
        localStorage.setItem("foragerTheme", "green-" + darkModeVerbage);
        break;
      default:
        SetColorScheme("green");
        localStorage.setItem("foragerTheme", "green-" + darkModeVerbage);
        break;
    }
  }

  async function GetTimeEntries() {
    let earliestDate = new Date();
    earliestDate.setDate(earliestDate.getDate() - 60);
    if (loggedInUser != null) {
      let newTimeEntries = await GetData("TimeEntry", {
        customerId: loggedInUser.customer.id,
        userId: loggedInUser.id,
        recursionLevel: 2,
        orderBy: "startTime",
        descending: true,
        startDate: earliestDate.toLocaleDateString(),
      });
      if (newTimeEntries instanceof Array) {
        SetTimeEntries(newTimeEntries);
      }
    }
  }

  function CalculateCurrentTimeEntry() {
    if (timeEntries != null && timeEntries.length !== 0) {
      let incompleteTimeEntries = timeEntries.filter(
        (timeEntry) => timeEntry.endTime == null
      );
      if (incompleteTimeEntries.length === 0) {
        SetCurrentTimeEntry(null);
      } else {
        SetCurrentTimeEntry(incompleteTimeEntries[0]);
      }
    }
  }

  function GetTimeSinceDateTime(dateToCheck) {
    if (!(dateToCheck instanceof Date)) {
      console.log("Value passed to GetTimeSinceDateTime is not a Date.");
      return "00:00:00";
    }
    let totalSeconds = new Date().getTime() - dateToCheck.getTime();
    totalSeconds = Math.floor(totalSeconds / 1000);
    let negativeString = "";
    if (totalSeconds < 0) {
      totalSeconds = totalSeconds * -1;
      negativeString = "-";
    }
    let seconds = Math.floor(totalSeconds % 60);
    let minutes = Math.floor(totalSeconds / 60) % 60;
    let hours = Math.floor(totalSeconds / 60 / 60) % 60;
    if (seconds < 10) {
      seconds = "0" + seconds;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    return `${negativeString}${hours}:${minutes}:${seconds}`;
  }

  async function GetData(type, params, loadingScreen = "") {
    let errorMessage = "";
    let response = await axios({
      method: "get",
      url: Constants.API_URL + "Get" + Constants.API_KEY,
      params: params,
      headers: {
        "Content-Type": "application/json",
        UserId: loggedInUser.id,
        GetType: type,
      },
      loadingScreen: loadingScreen,
    }).catch((error) => {
      errorMessage = error;
    });
    if (errorMessage !== "") {
      console.log(errorMessage);
      return errorMessage;
    }
    return response.data;
  }

  async function AddData(type, data, loadingScreen = "") {
    let errorMessage = "";
    let response = await axios({
      method: "post",
      url: Constants.API_URL + "Add" + Constants.API_KEY,
      data: data,
      headers: {
        "Content-Type": "application/json",
        UserId: loggedInUser.id,
        AddType: type,
      },
      loadingScreen: loadingScreen,
    }).catch((error) => {
      console.log(error);
      errorMessage = error;
    });
    if (
      errorMessage === "" &&
      response.data != null &&
      response.data.includes(";")
    ) {
      let responseArray = response.data.split(";");
      let newId = responseArray[1];
      return newId;
    }
    return errorMessage;
  }

  async function AddMultiple(items, loadingScreen = "") {
    let responses = [];
    for (let i = 0; i < items.length; i++) {
      let response = {
        id: items[i].id,
        success: true,
        response: await AddData(items[i].type, items[i].data, loadingScreen),
      };
      response.success = response.response != null && !isNaN(response.response);
      responses.push(response);
    }
    return responses;
  }

  async function UpdateData(type, data, loadingScreen = "") {
    let responseMessage = "";
    let response = await axios({
      method: "patch",
      url: Constants.API_URL + "Update" + Constants.API_KEY,
      data: data,
      headers: {
        "Content-Type": "application/json",
        UserId: loggedInUser.id,
        UpdateType: type,
      },
      loadingScreen: loadingScreen,
    })
      .then((resp) => {
        responseMessage = resp.data;
      })
      .catch((error) => {
        console.log(error);
        responseMessage = error;
      });

    if (responseMessage === "") {
      responseMessage = response;
    }

    return responseMessage;
  }

  async function UpdateMultiple(items, loadingScreen = "") {
    let responses = [];
    for (let i = 0; i < items.length; i++) {
      let response = {
        id: items[i].data.id,
        success: true,
        response: await UpdateData(items[i].type, items[i].data, loadingScreen),
      };
      response.success =
        response.response != null && response.response.includes("Success");
      responses.push(response);
    }
    return responses;
  }

  async function DeleteData(type, id, loadingScreen = "") {
    let formData = new FormData();
    formData.append("id", id);
    let responseMessage = "";
    let response = await axios({
      method: "delete",
      url: Constants.API_URL + "Remove" + Constants.API_KEY,
      data: formData,
      headers: {
        "Content-Type": "application/json",
        UserId: loggedInUser.id,
        RemoveType: type,
      },
      loadingScreen: loadingScreen,
    })
      .then((resp) => {
        responseMessage = resp.data;
      })
      .catch((error) => {
        console.log(error);
        responseMessage = error;
      });

    if (responseMessage === "") {
      responseMessage = response;
    }

    return responseMessage;
  }

  async function DeleteMultiple(items, loadingScreen = "") {
    let responses = [];
    for (let i = 0; i < items.length; i++) {
      let response = {
        id: items[i].data.id,
        success: true,
        response: await DeleteData(
          items[i].type,
          items[i].data.id,
          loadingScreen
        ),
      };
      response.success =
        response.response != null && response.response.includes("Success");
      responses.push(response);
    }
    return responses;
  }

  async function RefreshClients() {
    let newClients = await GetData("Client", {
      customer: loggedInUser.customer.id,
      orderBy: "name",
    });
    if (newClients instanceof Array) {
      SetClients(newClients);
    }
  }

  async function RefreshUsers() {
    let newUsers = await GetData("User", {
      customer: loggedInUser.customer.id,
      orderBy: "name",
    });
    if (newUsers instanceof Array) {
      SetUsers(newUsers);
    }
  }

  async function RefreshStatuses() {
    let newStatuses = await GetData(
      "Status",
      {
        customer: loggedInUser.customer.id,
        isAvailable: true,
      },
      "gantt"
    );
    if (newStatuses instanceof Array) {
      SetStatuses(newStatuses);
    }
  }

  async function RefreshCustomerSettings() {
    let newCustomerSettings = await GetData(
      "CustomerSettings",
      {
        customer: loggedInUser.customer.id,
      },
      "main"
    );
    if (
      newCustomerSettings instanceof Array &&
      newCustomerSettings.length > 0
    ) {
      SetCustomerSettings(newCustomerSettings[0]);
    }
  }

  function ShowLoadingScreen(Show) {
    SetShowMainLoadingScreen(Show);
    let loadingScreen = document.getElementById("loadingScreen");
    if (loadingScreen == null) {
      return;
    }
    if (Show) {
      document.getElementById("loadingScreen").style.visibility = "visible";
    } else {
      document.getElementById("loadingScreen").style.visibility = "hidden";
    }
  }

  function ShowLoadingScreens(screens) {
    let primaryLoadingScreens = screens.filter((s) => s.primary);
    let secondaryLoadingScreens = screens.filter((s) => !s.primary);
    let hasPrimary = false;
    primaryLoadingScreens.forEach((screen) => {
      let element = screen.GetElement();
      if (element != null) {
        if (screen.amount > 0) {
          element.style.visibility = "visible";
          secondaryLoadingScreens.forEach((secondaryScreen) => {
            let secondaryElement = secondaryScreen.GetElement();
            if (secondaryElement != null) {
              secondaryElement.style.visibility = "hidden";
            }
          });
          hasPrimary = true;
        } else {
          element.style.visibility = "hidden";
        }
      }
    });

    if (hasPrimary) {
      return;
    }

    secondaryLoadingScreens.forEach((screen) => {
      let element = screen.GetElement();
      if (element != null) {
        if (screen.amount > 0) {
          element.style.visibility = "visible";
        } else {
          element.style.visibility = "hidden";
        }
      }
    });
  }

  function InsertIntoAxiosRequests() {
    //Insert new loading screens here
    var loadingScreenInfo = [
      {
        screen: "main",
        amount: 0,
        GetElement: () => document.getElementById("loadingScreen"),
        primary: true,
      },
      {
        screen: "gantt",
        amount: 0,
        GetElement: () => document.getElementById("ganttLoadingScreen"),
        primary: false,
      },
    ];

    function ProcessLoadingScreenInfo(screen, changeAmountBy = 0) {
      let screenInfo = loadingScreenInfo.find((s) => s.screen === screen);
      if (screenInfo == null) {
        return;
      }
      screenInfo.amount += changeAmountBy;
      ShowLoadingScreens(loadingScreenInfo);
    }

    axios.interceptors.request.use(
      (config) => {
        ProcessLoadingScreenInfo(config.loadingScreen, 1);
        return config;
      },
      (error) => {
        ProcessLoadingScreenInfo(error.config?.loadingScreen, 0);
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      (response) => {
        ProcessLoadingScreenInfo(response.config?.loadingScreen, -1);
        return response;
      },
      (error) => {
        ProcessLoadingScreenInfo(error.config?.loadingScreen, -1);
        return Promise.reject(error);
      }
    );
  }

  function RebuildTooltips(string) {
    ReactTooltip.hide();

    setTimeout(() => {
      ReactTooltip.rebuild();
    }, 100);
    return string;
  }

  useEffect(() => {
    let loadingScreens = [document.getElementById("loadingScreen")];
    let activeLoadingScreen = null;
    if (showMainLoadingScreen) {
      activeLoadingScreen = 0;
    }
    if (activeLoadingScreen != null) {
      for (let i = 0; i < loadingScreens.length; i++) {
        if (loadingScreens[i] != null) {
          if (i === activeLoadingScreen) {
            loadingScreens[i].style.visibility = "visible";
          } else {
            loadingScreens[i].style.visibility = "hidden";
          }
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showMainLoadingScreen]);

  useEffect(() => {
    if (mainUser) {
      SetHubConnection(
        new SignalR.HubConnectionBuilder()
          .withUrl(Constants.API_URL, {
            headers: { UserId: loggedInUser.id.toUpperCase() },
          })
          .build()
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainUser]);

  useEffect(() => {
    if (hubConnection) {
      hubConnection.start();
      hubConnection.on("Refresh", (message) => {
        let messageConverted = JSON.parse(message);
        if (messageConverted.InitiatingUser !== randomSessionKey) {
          SetRefreshSignal(messageConverted.Action);
          setTimeout(() => {
            SetRefreshSignal("");
          }, 100);
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hubConnection]);

  useEffect(() => {
    document
      .querySelector("body")
      .setAttribute("data-foragerTheme", colorScheme + "-" + darkModeVerbage);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [darkMode, colorScheme]);

  useEffect(() => {
    if (loggedInUser != null) {
      GetTimeEntries();
      RefreshCustomerSettings();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedInUser]);

  useEffect(() => {
    let refreshInterval = null;
    if (timeEntries != null) {
      CalculateCurrentTimeEntry();
      refreshInterval = setInterval(() => {
        GetTimeEntries();
      }, 60000);
    }

    return () => {
      clearInterval(refreshInterval);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeEntries]);

  useEffect(() => {
    SetFieldCustomizations(new Customizations(loggedInUser, customerSettings));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedInUser, customerSettings]);

  useEffect(() => {
    let storedTheme = localStorage.getItem("foragerTheme");
    if (storedTheme != null) {
      SetDarkMode(storedTheme.includes("dark"));
      SetColorScheme(storedTheme.split("-")[0]);
    }
    CalculateCurrentTimeEntry();
    InsertIntoAxiosRequests();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CurrentContext.Provider
      value={{
        alertArray,
        AddAlert,
        RemoveAlert,
        loggedInUser,
        mainUser,
        SetMainUser,
        hubConnection,
        SendSignal,
        impersonatedUser,
        SetImpersonatedUser,
        impersonating,
        GetData,
        AddData,
        AddMultiple,
        UpdateData,
        UpdateMultiple,
        DeleteData,
        DeleteMultiple,
        FeatureCheck,
        PermissionCheck,
        refreshSignal,
        darkMode,
        DarkModeSwitch,
        ColorSchemeSwitch,
        GetTimeSinceDateTime,
        ShowLoadingScreen,
        currentTimeEntry,
        timeEntries,
        GetTimeEntries,
        clients,
        RefreshClients,
        users,
        RefreshUsers,
        statuses,
        RefreshStatuses,
        customerSettings,
        RefreshCustomerSettings,
        fieldCustomizations,
        RebuildTooltips,
      }}
    >
      {children}
    </CurrentContext.Provider>
  );
}

export function useSiteContext() {
  const context = useContext(CurrentContext);
  if (!context) {
    throw new Error("useSiteContext must be used within a Context");
  }
  return context;
}

export default Context;
