import React from "react";

function Search(props) {
  return (
    <div
      className={"searchContainer" + (props.expanded ? "" : " hiddenOnSmall")}
    >
      <div className="searchTitle">Search</div>
      <div className="searchInputContainer">
        <input
          className="searchInput"
          type="text"
          value={props.search}
          onChange={props.ChangeSearch}
        />
      </div>
    </div>
  );
}

export default Search;
