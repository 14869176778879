import React, { useState } from "react";
import { Icon } from "@iconify/react";
import * as Utilities from "../../../../../Utilities";
import { useSiteContext } from "../../../../../Context";

const JobFilesItem = (props) => {
  const [validImage, SetValidImage] = useState(true);

  const { AddAlert } = useSiteContext();

  const isPDF = props.file.name.includes(".pdf");

  function ChangePreviewImage() {
    props.ChangeFilePreview(props.file.saSURI);
  }

  function CopyToClipboard() {
    Utilities.CopyToClipboard(props.file.saSURI);
    AddAlert("notification", "File Link copied to clipboard.");
  }

  function DownloadClicked() {
    window.open(props.file.saSURI, "_blank");
  }

  function HandleOpenInViewer() {
    props.OpenInPDFViewer(props.file.saSURI);
  }

  return (
    <>
      <div
        className="JobFilesItemPart"
        onMouseEnter={ChangePreviewImage}
        onClick={props.ChangeIsClicked}
      >
        {validImage ? (
          <img
            src={props.file.saSURI}
            onError={(e) => {
              e.target.onerror = null;
              SetValidImage(false);
            }}
            alt="Selected File"
          />
        ) : isPDF ? (
          <Icon
            icon="material-symbols:picture-as-pdf"
            className="JobImageIcon"
          />
        ) : (
          <Icon icon="mdi:file-find" className="JobImageIcon" />
        )}
      </div>
      <div
        onMouseEnter={ChangePreviewImage}
        onClick={props.ChangeIsClicked}
        className="JobFilesItemPart"
      >
        {props.deleting ? "Deleting..." : props.file.name}
      </div>
      {isPDF ? (
        <div className="JobFilesItemPart download" onClick={HandleOpenInViewer}>
          <Icon icon="material-symbols:open-in-browser" />
        </div>
      ) : (
        <div className="JobFilesItemPart download" onClick={DownloadClicked}>
          <Icon icon="material-symbols:download" />
        </div>
      )}
      <div className="JobFilesItemPart copy" onClick={CopyToClipboard}>
        <Icon icon="uil:copy" />
      </div>
      <div
        className="JobFilesItemPart delete"
        onClick={() => props.deleteMethod(props.file)}
      >
        <Icon icon="akar-icons:trash-can" />
      </div>
    </>
  );
};

export default JobFilesItem;
