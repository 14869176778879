import React from "react";
import DateRange from "./DateRange";
import ContentSelector from "./ContentSelector";
import Search from "./Search";
import Status from "./Status";

function Filter(props) {
  const [expanded, SetExpanded] = React.useState(false);

  function ToggleExpanded() {
    SetExpanded(!expanded);
  }

  return (
    <div className="filterContainer">
      <DateRange
        ChangeStartDate={props.ChangeStartDate}
        ChangeEndDate={props.ChangeEndDate}
        startDate={props.startDate}
        endDate={props.endDate}
        expanded={expanded}
        itemsBefore={props.itemsBefore}
      />
      <Status
        statusFilter={props.statusFilter}
        SetStatusFilter={props.SetStatusFilter}
        expanded={expanded}
      />
      <ContentSelector
        path={props.path}
        expanded={expanded}
        ToggleExpanded={ToggleExpanded}
      />
      <div></div>
      <Search
        ChangeSearch={props.ChangeSearch}
        search={props.search}
        expanded={expanded}
      />
    </div>
  );
}

export default Filter;
