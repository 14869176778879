import React, { useState, useEffect } from "react";
import { useSiteContext } from "../../../../../../Context";
import DropDownMenu from "../../../../../../Generic/DropDownMenu";
import ForagerButton from "../../../../../../Generic/ForagerButton";
import AutosizedTextArea from "../../../../../../Generic/AutosizedTextArea";

function AddEditTimeModal(props) {
  const [clientId, SetClientId] = useState(props.timeEntry?.client?.id ?? 0);
  const [clientName, SetClientName] = useState(
    props.timeEntry?.client?.name ??
      props.timeEntry?.scheduleItem?.client?.name ??
      ""
  );
  const [job, SetJob] = useState(
    props.timeEntry?.scheduleItem?.job?.id ??
      props.timeEntry?.scheduleItem?.id ??
      0
  );
  const [task, SetTask] = useState(
    props.mode === "Edit"
      ? props.timeEntry.scheduleItem != null &&
        props.timeEntry.scheduleItem.scheduleItemTypeId === 2
        ? props.timeEntry.scheduleItem.id
        : 0
      : 0
  );
  const [timeIn, SetTimeIn] = useState(
    props.timeEntry?.startTime?.substring(0, 16) ?? ""
  );
  const [timeOut, SetTimeOut] = useState(
    props.timeEntry?.endTime?.substring(0, 16) ?? ""
  );
  const [notes, SetNotes] = useState(props.timeEntry?.notes ?? "");
  const [filteredTasks, SetFilteredTasks] = useState([]);
  const [isTicket, SetIsTicket] = useState(props.timeEntry?.clientId !== 0);

  const {
    AddAlert,
    loggedInUser,
    UpdateData,
    AddData,
    GetTimeEntries,
    timeEntries,
    fieldCustomizations,
    clients,
  } = useSiteContext();

  function ChangeClientId(clientId) {
    SetClientId(clientId);
    let fullClient = clients.find((c) => c.id === clientId);
    if (fullClient != null) {
      SetClientName(fullClient.name);
      return;
    }
    SetClientName("");
  }

  function ChangeJob(job) {
    SetJob(job ?? 0);
    let fullJob = props.jobsForFilter.find((j) => j.id === job);
    if (fullJob != null) {
      SetClientName(fullJob.client.name);
      SetTask(0);
      return;
    }
    SetClientName("");
  }

  function ChangeTask(task) {
    SetTask(task ?? 0);
    let fullTask = props.tasksForFilter.find((t) => t.id === task);
    if (fullTask != null) {
      SetClientName(fullTask.client.name);
      return;
    }
    SetClientName("");
  }

  function ChangeTimeIn(e) {
    SetTimeIn(e.target.value);
  }

  function ChangeTimeOut(e) {
    SetTimeOut(e.target.value);
  }

  function ChangeNotes(e) {
    SetNotes(e.target.value);
  }

  function ChangeIsTicket(e) {
    SetIsTicket(e.target.checked);
  }

  function FilterTaskByJob() {
    let newFilteredTasks = props.tasksForFilter.slice();
    newFilteredTasks = newFilteredTasks.filter((task) => {
      return task.job.id === job;
    });
    SetFilteredTasks(newFilteredTasks);
  }

  async function NewTimeEntry() {
    if (timeEntries.some((te) => te.endTime === null)) {
      AddAlert("error", "You must clock out before clocking in again.");
      return;
    }
    var formData = new FormData();

    let verificationResult = VerifyHasSelection(formData);
    if (verificationResult !== "") {
      AddAlert("error", verificationResult + "for this clock in.");
      return;
    }
    let currentTime = new Date();
    // currentTime.setSeconds(0, 0);
    formData.append("startTime", currentTime.toISOString());
    formData.append("userId", loggedInUser.id);
    formData.append("notes", notes);
    let newTimeEntryId = await AddData("TimeEntry", formData);
    if (newTimeEntryId != null) {
      GetTimeEntries();
      props.CloseModal();
    }
  }

  async function EditTimeEntry() {
    var formData = new FormData();

    let verificationResult = VerifyHasSelection(formData);
    if (verificationResult !== "") {
      AddAlert("error", verificationResult + "for this time entry.");
      return;
    }

    if (timeIn > timeOut) {
      AddAlert("error", "Time in must be before time out.");
      return;
    }

    let timeIn0Seconds = new Date(timeIn);
    timeIn0Seconds.setSeconds(0, 0);
    let timeOut0Seconds = new Date(timeOut);
    timeOut0Seconds.setSeconds(0, 0);
    formData.append("startTime", timeIn0Seconds.toISOString());
    formData.append("endTime", timeOut0Seconds.toISOString());
    formData.append("userId", loggedInUser.id);
    formData.append("id", props.timeEntry.id);
    formData.append("notes", notes);
    let response = await UpdateData("TimeEntry", formData);
    if (!response.includes("Success")) {
      AddAlert(
        "error",
        "There was an error updating the time entry. Please try again."
      );
      return;
    }
    GetTimeEntries();
    props.CloseModal();
  }

  function VerifyHasSelection(formData) {
    if (isTicket && clientId !== 0) {
      formData.append("clientId", clientId);
      formData.append("scheduleItemId", 0);
    } else if (task !== 0) {
      formData.append("clientId", 0);
      formData.append("scheduleItemId", task);
    } else if (job !== 0) {
      formData.append("clientId", 0);
      formData.append("scheduleItemId", job);
    } else if (isTicket) {
      return (
        "You must select a " + fieldCustomizations.general.client.name + " "
      );
    } else {
      return "You must select a job, or task ";
    }
    return "";
  }

  useEffect(() => {
    if (props.timeEntry == null) {
      SetNotes(props.timeEntryToCopy?.notes ?? "");
      ChangeClientId(props.timeEntryToCopy?.client?.id ?? 0);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.timeEntryToCopy]);

  useEffect(() => {
    FilterTaskByJob();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [job]);

  return (
    <>
      <div className="addEditTimeModalContainer">
        <div className="addEditTimeModalHeader">
          {props.mode === "Edit" ? "Edit Time Card" : "Create Time Card"}
        </div>
        <div className="addEditTimeModalBody">
          <div className="addEditTimeModalBodyItemContainer">
            <div className="addEditTimeModalBodyItem left">Is Ticket</div>
            <input
              className="ganttItemStatusValueEdit checkbox"
              type="checkbox"
              checked={isTicket}
              onChange={ChangeIsTicket}
            />
          </div>
          <div className="addEditTimeModalBodyItemContainer">
            <div className="addEditTimeModalBodyItem left">
              {fieldCustomizations.general.client.name}
            </div>
            {isTicket ? (
              <div className="addEditTimeModalBodyItemDropDownContainer">
                <DropDownMenu
                  className="timeClockModalDropDown"
                  options={clients}
                  value={clientId}
                  onChange={ChangeClientId}
                  showBlank={true}
                />
              </div>
            ) : (
              <div className="addEditTimeModalBodyItem text">{clientName}</div>
            )}
          </div>
          {!isTicket ? (
            <>
              <div className="addEditTimeModalBodyItemContainer">
                <div className="addEditTimeModalBodyItem left">
                  {fieldCustomizations.general.job.name}
                </div>
                <div className="addEditTimeModalBodyItemDropDownContainer">
                  <DropDownMenu
                    className="timeClockModalDropDown"
                    options={props.jobsForFilter}
                    value={job}
                    onChange={ChangeJob}
                    showBlank={true}
                  />
                </div>
              </div>
              <div className="addEditTimeModalBodyItemContainer">
                <div className="addEditTimeModalBodyItem left">
                  {fieldCustomizations.general.task.name}
                </div>
                <div className="addEditTimeModalBodyItemDropDownContainer">
                  <DropDownMenu
                    className="timeClockModalDropDown"
                    options={filteredTasks}
                    value={task}
                    onChange={ChangeTask}
                    showBlank={true}
                  />
                </div>
              </div>
            </>
          ) : null}
          <div className="addEditTimeModalBodyItemContainer">
            <div className="addEditTimeModalBodyItem left">Notes</div>
            <div className="addEditTimeModalBodyItemDropDownContainer notes">
              <AutosizedTextArea
                className="foragerInputModalInput textarea"
                placeholder={"Enter notes here..."}
                value={notes}
                onChange={ChangeNotes}
                spellCheck={true}
                minHeight={80}
              />
            </div>
          </div>
          {props.mode === "Edit" ? (
            <>
              <div className="addEditTimeModalBodyItemContainer">
                <div className="addEditTimeModalBodyItem left">Time In</div>
                <input
                  className="addEditTimeModalBodyItem right"
                  type="datetime-local"
                  value={timeIn}
                  onChange={ChangeTimeIn}
                />
              </div>
              <div className="addEditTimeModalBodyItemContainer">
                <div className="addEditTimeModalBodyItem left">Time Out</div>
                <input
                  className="addEditTimeModalBodyItem right"
                  type="datetime-local"
                  value={timeOut}
                  onChange={ChangeTimeOut}
                />
              </div>
            </>
          ) : null}
        </div>
        <div className="addEditTimeModalFooter">
          <ForagerButton
            className="addEditTimeModalFooterButton"
            onClick={props.CloseModal}
            exit={true}
          >
            Cancel
          </ForagerButton>
          <ForagerButton
            className="addEditTimeModalFooterButton"
            onClick={props.mode === "Edit" ? EditTimeEntry : NewTimeEntry}
          >
            {props.mode === "Edit" ? "Update" : "Clock In"}
          </ForagerButton>
        </div>
      </div>
    </>
  );
}

export default AddEditTimeModal;
