import React, { useEffect, useRef } from "react";
import { Icon } from "@iconify/react";
import IQLoading from "./IQLoading";
import IQMessage from "./IQMessage";
import { useIQContext } from "../../IQContext";
import Modal from "../../Generic/Modal";
import PDFViewer from "../../Generic/PDFViewer";
import IQSideBar from "./IQSideBar";
import IQExpandedSideBar from "./IQExpandedSideBar";
import DocumentManagement from "./DocumentManagement/DocumentManagement";
import ForagerButton from "../../Generic/ForagerButton";

function IQ(props) {
  const messagesEndRef = useRef(null);

  const {
    knowledgeBaseOptions,
    selectedKnowledgeBases,
    pastMessages,
    isThinking,
    question,
    chatModelOption,
    isPDFViewerOpen,
    PDFViewerFile,
    PDFPageNumber,
    expandedSideBar,
    SetQuestion,
    HandleKnowledgeBaseSelection,
    ToggleChatModel,
    ShowExtendedSideBar,
    AskQuestion,
    SetIsPDFViewerOpen,
    SetPDFViewerFile,
    SetPDFPageNumber,
    hideSidebar,
    ClearPastMessages,
  } = useIQContext();

  function HandleClick(e) {
    AskQuestionStart(question);
  }

  function HandleTyping(event) {
    SetQuestion(event.target.value);
  }

  function HandleEnterKey(event) {
    if (
      event.key === "Enter" &&
      !event.shiftKey &&
      selectedKnowledgeBases.length > 0 &&
      question !== "" &&
      !isThinking
    ) {
      AskQuestionStart(question);
      event.preventDefault();
    }
  }

  function AskQuestionStart(
    newQuestion,
    isSummary = false,
    knowledgeBaseIds = null
  ) {
    AskQuestion(newQuestion, isSummary, knowledgeBaseIds);
    ScrollToBottom();
  }

  function ScrollToBottom() {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }

  function GetSelectedKnowledgeBases() {
    if (selectedKnowledgeBases.length === 0) {
      return (
        <div className="selectedKnowledgeBase pleaseSelect">
          Please select a knowledge base.
        </div>
      );
    }
    return selectedKnowledgeBases
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((knowledgeBase) => (
        <div className="selectedKnowledgeBase" key={knowledgeBase.id}>
          {knowledgeBase.name}
        </div>
      ));
  }

  useEffect(() => {
    ScrollToBottom();
  }, [pastMessages, isThinking]);

  return (
    <>
      <IQSideBar
        ToggleChatModel={ToggleChatModel}
        chatModelOption={chatModelOption}
        sideBarSelection={expandedSideBar}
        ShowExtendedSideBar={ShowExtendedSideBar}
      />
      <div className={"IQBodyContainer" + (!hideSidebar ? " showSidebar" : "")}>
        <IQExpandedSideBar
          knowledgeBases={knowledgeBaseOptions}
          selectedKnowledgeBases={selectedKnowledgeBases}
          HandleKnowledgeBaseSelection={HandleKnowledgeBaseSelection}
        />
        {[
          "DocumentManagement",
          "DocumentManagementEdit",
          "DocumentManagementDelete",
          "KnowledgeBaseManagement",
          "KnowledgeBaseManagementEdit",
          "KnowledgeBaseManagementDelete",
        ].includes(expandedSideBar) ? (
          <DocumentManagement />
        ) : (
          <>
            <div className="IQBody">
              <div className="IQChatContent">
                {pastMessages.length > 0 &&
                  pastMessages
                    .filter(
                      (message) =>
                        (message.role === "user" ||
                          message.role === "assistant") &&
                        message.content !== null &&
                        message.content !== ""
                    )
                    .map((message, index) => {
                      return (
                        <IQMessage
                          message={message.content}
                          role={message.role}
                          key={index}
                          SetIsPDFViewerOpen={SetIsPDFViewerOpen}
                          SetPDFViewerFile={SetPDFViewerFile}
                          SetPDFPageNumber={SetPDFPageNumber}
                        />
                      );
                    })}
                {isThinking && <IQLoading ScrollToBottom={ScrollToBottom} />}
                <div ref={messagesEndRef}></div>
              </div>
            </div>
            {pastMessages.length > 0 && (
              <ForagerButton
                className="clearChatButton"
                dataTip="Clear Chat"
                onClick={ClearPastMessages}
                lessVerticalPadding={true}
                circular={true}
                smallText={true}
              >
                X
              </ForagerButton>
            )}
            <div className="IQChatBarContainer">
              <div className="inputContainer">
                <div className="knowledgeBasesSelectedContainer">
                  <div
                    className={
                      "knowledgeBasesSelected" +
                      (selectedKnowledgeBases.length === 0
                        ? " pleaseSelect"
                        : "")
                    }
                  >
                    {GetSelectedKnowledgeBases()}
                  </div>
                </div>
                <input
                  name="question"
                  type="=text"
                  placeholder="Enter your question here..."
                  className="chatBar"
                  id="questionBox"
                  value={question}
                  onChange={HandleTyping}
                  onKeyUp={HandleEnterKey}
                ></input>
                <div
                  className={
                    "askQuestionWrapper" +
                    (question === "" ||
                    selectedKnowledgeBases.length === 0 ||
                    isThinking
                      ? " disabled"
                      : "")
                  }
                >
                  <Icon
                    id="sendQuestion"
                    icon={"mdi:chat"}
                    onClick={HandleClick}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      {isPDFViewerOpen && (
        <Modal
          CloseModal={() => {
            SetIsPDFViewerOpen(false);
          }}
          modificationClass="pdfViewer noPadding"
        >
          <PDFViewer file={PDFViewerFile} startPage={PDFPageNumber} />
        </Modal>
      )}
    </>
  );
}
export default IQ;
