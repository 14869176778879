import { React, useState } from "react";
import MultiSelectorModal from "../../Generic/MultiSelectorField";
import { useSiteContext } from "../../Context";

function FilterSelectorModal(props) {
  const [userSelector, SetUserSelector] = useState(false);
  const [clientSelector, SetClientSelector] = useState(false);
  const [jobSelector, SetJobSelector] = useState(false);

  const { fieldCustomizations } = useSiteContext();

  return (
    <>
      <div className="filterModalContainer">
        <div className="filteredModalTitleBar topBar">
          <div className="itemAssignmentModalTitle">{props.title}</div>
        </div>
        <div className="filteredModalItemsContainer">
          <div className="filteredModalItem">
            <div className="filteredModalItemLabel">
              <div>Assigned</div>
            </div>
            <div className="filteredModalItemValue">
              <div className="filteredModalItemValueInner">
                <MultiSelectorModal
                  assignableItems={props.usersForFilter}
                  currentlyAssigned={props.filteredUsers}
                  SetAssignmentFunction={props.ChangeFilteredUsers}
                  title={
                    "Select " + fieldCustomizations.general.user.pluralName
                  }
                  updateDb={false}
                  inverted={false}
                  CloseModal={() => {
                    SetUserSelector(!userSelector);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="filteredModalItem">
            <div className="filteredModalItemLabel">
              <div>{fieldCustomizations.general.client.name}</div>
            </div>
            <div className="filteredModalItemValue">
              <div className="filteredModalItemValueInner">
                <MultiSelectorModal
                  assignableItems={props.clientsForFilter}
                  currentlyAssigned={props.filteredClients}
                  SetAssignmentFunction={props.ChangeFilteredClients}
                  title={
                    "Select " + fieldCustomizations.general.client.pluralName
                  }
                  updateDb={false}
                  inverted={false}
                  CloseModal={() => {
                    SetClientSelector(!clientSelector);
                  }}
                />
              </div>
            </div>
          </div>
          {props.showJobFilter ? (
            <div className="filteredModalItem">
              <div className="filteredModalItemLabel">
                <div>{fieldCustomizations.general.job.name}</div>
              </div>
              <div className="filteredModalItemValue">
                <div className="filteredModalItemValueInner">
                  <MultiSelectorModal
                    assignableItems={props.jobsForFilter}
                    currentlyAssigned={props.filteredJobs}
                    SetAssignmentFunction={props.ChangeFilteredJobs}
                    title={
                      "Select " + fieldCustomizations.general.job.pluralName
                    }
                    updateDb={false}
                    inverted={false}
                    CloseModal={() => {
                      SetJobSelector(!jobSelector);
                    }}
                  />
                </div>
              </div>
            </div>
          ) : null}
        </div>
        <div className="filteredModalTitleBar bottomBar"></div>
      </div>
    </>
  );
}

export default FilterSelectorModal;
