import React, { useState } from "react";
import { Icon } from "@iconify/react";
import ForagerButton from "../../../../../Generic/ForagerButton";
import Modal from "../../../../../Generic/Modal";
import ForagerInputModal from "../../../../../Generic/ForagerInputModal";
import * as Constants from "../../../../../Constants";
import axios from "axios";
import { useSiteContext } from "../../../../../Context";

function ActivityContent(props) {
  const [commentEditModalOpen, SetCommentEditModalOpen] = useState(false);
  const [commentSaving, SetCommentSaving] = useState(false);
  const [canCloseCommentBox, SetCanCloseCommentBox] = useState(true);

  const { loggedInUser, AddAlert } = useSiteContext();

  const editedTag = CheckIfEdited()
    ? "(Edited at : " + new Date(props.item.edited).toLocaleString() + ") "
    : "";

  function CheckIfEdited() {
    if (props.item.commentId == null) {
      return;
    }
    let editedUnixTimestamp = new Date(props.item.edited).valueOf();
    let commentUnixTimestamp = new Date(props.item.timestamp).valueOf();
    let acceptableDifference = 1000 * 60;
    return (
      Math.abs(editedUnixTimestamp - commentUnixTimestamp) >
      acceptableDifference
    );
  }

  function OpenCommentEditModal() {
    SetCommentEditModalOpen(true);
  }

  function CloseCommentEditModal() {
    if (
      canCloseCommentBox ||
      window.confirm(
        "This will discard any changes to the comment that you have made. Are you sure you want to continue?"
      )
    ) {
      SetCommentEditModalOpen(false);
      SetCommentSaving(false);
      SetCanCloseCommentBox(true);
    }
  }

  async function SaveEditedComment(newComment) {
    SetCommentSaving(true);

    var formData = new FormData();
    formData.append("id", props.item.commentId);
    if (props.item.content === newComment) {
      SetCommentSaving(false);
      return;
    }
    formData.append("content", newComment);
    formData.append("edited", new Date().toISOString());

    let response = await axios({
      method: "patch",
      url: Constants.API_URL + "Update" + Constants.API_KEY,
      headers: {
        "Content-Type": "multipart/form-data",
        UserId: loggedInUser.id,
        UpdateType: "Comment",
      },
      data: formData,
    }).catch((error) => {
      console.log(error);
      return error;
    });

    if (response.data?.includes("Success")) {
      props.RefreshComments();
      SetCommentEditModalOpen(false);
      SetCommentSaving(false);
      AddAlert("notification", "Comment updated successfully");
      return;
    }

    AddAlert("error", "Comment could not be updated");
    SetCommentSaving(false);
  }

  return (
    <>
      {props.item.isLoggedInUser ? (
        <div className="activityContent">
          <ForagerButton
            onClick={OpenCommentEditModal}
            noVerticalPadding={true}
            noHorizontalPadding={true}
            reversedColors={true}
            noBackground={true}
          >
            <Icon icon="bxs:edit"></Icon>
          </ForagerButton>{" "}
          {editedTag + props.item.content}
        </div>
      ) : (
        editedTag + props.item.content
      )}
      {commentEditModalOpen ? (
        <Modal CloseModal={CloseCommentEditModal}>
          <ForagerInputModal
            title="Edit Comment"
            placeholder="Enter Comment..."
            onCancel={CloseCommentEditModal}
            onSubmit={SaveEditedComment}
            value={props.item.content}
            type="textarea"
            saving={commentSaving}
            minHeight={80}
            SetCanClose={SetCanCloseCommentBox}
          ></ForagerInputModal>
        </Modal>
      ) : null}
    </>
  );
}

export default ActivityContent;
