import React, { useEffect } from "react";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import { useSiteContext } from "../../Context";

function ContentSelector(props) {
  const { fieldCustomizations } = useSiteContext();

  // const contentOrder = ["Jobs", "Tasks", "Teams", "Equipment"];
  const contentOrder = [
    fieldCustomizations.general.job.pluralName,
    fieldCustomizations.general.task.pluralName,
  ];
  const currentIndex = contentOrder.indexOf(props.path[1]);

  const navigate = useNavigate();

  function ChangeContent(direction) {
    let newIndex = currentIndex;

    if (direction === "left") {
      newIndex--;
    } else {
      newIndex++;
    }
    if (newIndex < 0) {
      newIndex = contentOrder.length - 1;
    } else if (newIndex > contentOrder.length - 1) {
      newIndex = 0;
    }
    navigate("/" + contentOrder[newIndex]);
  }

  useEffect(() => {
    if (props.path[1] != null && currentIndex === -1) {
      navigate("/" + contentOrder[0]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.path[1]]);

  return (
    <div className="contentSelectorContainer">
      <div
        className="expandFilter hiddenOnLarge"
        onClick={props.ToggleExpanded}
      >
        <Icon
          icon={
            props.expanded ? "ic:round-expand-less" : "ic:round-expand-more"
          }
          className="minimizeIcon"
        />
      </div>
      <Icon
        className="contentSelectorIcon"
        icon="fluent:triangle-left-20-filled"
        onClick={() => ChangeContent("left")}
      />
      <div className="contentSelectorTitle">
        {props.path[1] != null ? props.path[1] : ""}
      </div>
      <Icon
        className="contentSelectorIcon"
        icon="fluent:triangle-right-20-filled"
        onClick={() => ChangeContent("right")}
      />
    </div>
  );
}

export default ContentSelector;
