import React from "react";
import * as Utilities from "../../../../../Utilities";
import * as Constants from "../../../../../Constants";
import axios from "axios";
import { Icon } from "@iconify/react";
import { useSiteContext } from "../../../../../Context";
import ForagerButton from "../../../../../Generic/ForagerButton";

function ChangePasswordModal(props) {
  const [currentPassword, SetCurrentPassword] = React.useState("");
  const [newPassword, SetNewPassword] = React.useState("");
  const [confirmNewPassword, SetConfirmNewPassword] = React.useState("");
  const [passwordVisible, SetPasswordVisible] = React.useState(false);

  const { AddAlert, loggedInUser, RebuildTooltips } = useSiteContext();

  const ChangeCurrentPassword = (event) => {
    SetCurrentPassword(event.target.value);
  };

  const ChangeNewPassword = (event) => {
    SetNewPassword(event.target.value);
  };

  const ChangeConfirmNewPassword = (event) => {
    SetConfirmNewPassword(event.target.value);
  };

  const TogglePasswordVisibility = () => {
    SetPasswordVisible(!passwordVisible);
  };

  const PasswordsMatch = () => {
    return newPassword === confirmNewPassword;
  };

  const HandleEnter = (event) => {
    if (event.key === "Enter") {
      UpdatePassword();
    }
  };

  function UpdatePassword() {
    if (Utilities.ValidateField("Password", newPassword) && PasswordsMatch()) {
      var formData = new FormData();
      formData.append("email", loggedInUser.email);
      formData.append("password", currentPassword);
      formData.append("newPassword", newPassword);
      axios({
        method: "post",
        url: Constants.API_URL + "ChangePassword" + Constants.API_KEY,
        headers: { "Content-Type": "multipart/form-data" },
        data: formData,
        loadingScreen: "main",
      })
        .then((response) => {
          if (response.data !== "Failed") {
            SetPasswordVisible(false);
            AddAlert("notification", "Changed password successfully.");
            props.CloseModal();
          } else {
            AddAlert("error", "The orginal password is incorrect.");
          }
        })
        .catch((error) => {
          AddAlert(
            "error",
            "Could not connect to the server. Please try again later."
          );
        });
    } else {
      AddAlert(
        "error",
        "Please make sure password requirements are met as well as confirm password matches the new password."
      );
      SetConfirmNewPassword("");
    }
  }

  return (
    <div className="changePasswordModalContainer">
      <div className="adminModalTitleBar">
        <div className="adminModalTitle">Change Your Password</div>
      </div>
      <div className="loginInputContainer">
        <label className="loginInputLabel">Current Password:</label>
        <div className="loginInputPasswordContainer">
          <input
            className="loginInput"
            type={passwordVisible ? "text" : "password"}
            value={currentPassword}
            onChange={ChangeCurrentPassword}
            onKeyDown={(event) => {
              HandleEnter(event);
            }}
          />
          <Icon
            icon={
              passwordVisible ? "akar-icons:eye-open" : "akar-icons:eye-slashed"
            }
            className="loginPasswordEye"
            data-tip="View/Hide Password"
            onClick={TogglePasswordVisibility}
          />
        </div>
      </div>
      <div className="loginInputContainer">
        <label className="loginInputLabel">New Password:</label>
        <div className="loginInputPasswordContainer">
          <input
            className={
              Utilities.ValidateField("Password", newPassword)
                ? "loginInput"
                : "loginInput inputError"
            }
            data-tip={
              Utilities.ValidateField("Password", newPassword)
                ? ""
                : "Password must meet the following criteria:<br>- At Least 8 Characters<br>- One Number<br>- One Capital Letter<br>- One Lower-Case Letter<br>- One Special Character from the below:<br>+-_!@#$%^&*.,/\\?"
            }
            data-multiline={true}
            data-class="tooltipError"
            data-effect="solid"
            type={passwordVisible ? "text" : "password"}
            value={newPassword}
            onChange={ChangeNewPassword}
            onKeyDown={(event) => {
              HandleEnter(event);
            }}
          />
          <Icon
            icon={
              passwordVisible ? "akar-icons:eye-open" : "akar-icons:eye-slashed"
            }
            className="loginPasswordEye"
            data-tip="View/Hide Password"
            onClick={TogglePasswordVisibility}
          />
        </div>
      </div>
      <div className="loginInputContainer">
        <label className="loginInputLabel">Confirm Password:</label>
        <div className="loginInputPasswordContainer">
          <input
            className={
              PasswordsMatch() ? "loginInput" : "loginInput inputError"
            }
            data-tip={
              PasswordsMatch()
                ? RebuildTooltips()
                : RebuildTooltips("Passwords do not match.")
            }
            data-class={PasswordsMatch() ? null : "tooltipError"}
            data-effect={PasswordsMatch() ? null : "solid"}
            type={passwordVisible ? "text" : "password"}
            value={confirmNewPassword}
            onChange={ChangeConfirmNewPassword}
            onKeyDown={(event) => {
              HandleEnter(event);
            }}
          />
          <Icon
            icon={
              passwordVisible ? "akar-icons:eye-open" : "akar-icons:eye-slashed"
            }
            className="loginPasswordEye"
            data-tip="View/Hide Password"
            onClick={TogglePasswordVisibility}
          />
        </div>
      </div>
      <div className="adminModalTitleBar spaced">
        <ForagerButton
          lessVerticalPadding={true}
          onClick={props.CloseModal}
          exit={true}
        >
          Cancel
        </ForagerButton>
        <ForagerButton
          lessVerticalPadding={true}
          reversedColors={true}
          onClick={UpdatePassword}
        >
          Change Password
        </ForagerButton>
      </div>
    </div>
  );
}

export default ChangePasswordModal;
