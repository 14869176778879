import React from "react";
import { Icon } from "@iconify/react";

function Range(props) {
  function RemoveRange() {
    if (props.rangeNumber > 1) {
      props.ChangeRangeNumber(props.rangeNumber - 1);
    } else {
      props.ChangeRangeNumber(1);
    }
  }

  function AddRange() {
    if (props.rangeNumber < 7) {
      props.ChangeRangeNumber(props.rangeNumber + 1);
    } else {
      props.ChangeRangeNumber(7);
    }
  }

  return (
    <div className="rangeContainer">
      <div className="rangePartContainer">
        <div className="rangeLabel">Range</div>
      </div>
      <div className="rangePartContainer">
        <div className="rangeButton" onClick={RemoveRange}>
          <Icon icon="vaadin:minus-circle" className="rangeMinus" />
        </div>
        <input
          type="range"
          min="1"
          max="7"
          className="rangeSlider"
          id="Range"
          onChange={(e) => {
            props.ChangeRangeNumber(e.target.value);
          }}
          value={props.rangeNumber}
        />
        <div type="button" className="rangeButton" onClick={AddRange}>
          <Icon icon="vaadin:plus-circle" className="rangePlus" />
        </div>
      </div>
    </div>
  );
}

export default Range;
