import React, { useState, useEffect } from "react";
import axios from "axios";
import * as Constants from "../../../../../Constants";
import JobFilesItem from "./JobFilesItem";
import { useSiteContext } from "../../../../../Context";
import { Icon } from "@iconify/react";
import ForagerButton from "../../../../../Generic/ForagerButton";
import PDFViewer from "../../../../../Generic/PDFViewer";
import Modal from "../../../../../Generic/Modal";

const JobFilesModal = (props) => {
  const { loggedInUser, AddAlert } = useSiteContext();
  const [file, SetFile] = useState(null);
  const [filePreview, SetFilePreview] = useState(null);
  const [validPreviewImage, SetValidPreviewImage] = useState(true);
  const [hoveredOverFile, SetHoveredOverFile] = useState(null);
  const [isClicked, SetIsClicked] = useState(false);
  const [uploading, SetUploading] = useState(false);
  const [nameBeingDeleted, SetNameBeingDeleted] = useState(null);
  const [PDFViewerModalOpen, SetPDFViewerModalOpen] = useState(false);
  const [PDFFileToView, SetPDFFileToView] = useState(null);

  function ChangeFile(e) {
    SetFile(e.target.files[0]);
  }
  function ChangeFilePreview(file) {
    if (!isClicked) {
      SetFilePreview(file);
    }
    SetValidPreviewImage(true);
    SetHoveredOverFile(file);
  }

  function ChangeIsClicked(e) {
    if (!isClicked || hoveredOverFile === filePreview) {
      SetIsClicked(!isClicked);
    } else if (isClicked && hoveredOverFile !== filePreview) {
      SetFilePreview(hoveredOverFile);
    }
  }

  function IsClicked(file) {
    if (isClicked) {
      if (file.saSURI === filePreview) {
        return true;
      }
    }
    return false;
  }

  function OpenInNewTab() {
    if (!filePreview) return;
    window.open(filePreview, "_blank");
  }

  function OpenInPDFViewer(file) {
    SetPDFFileToView(file);
    SetPDFViewerModalOpen(true);
  }

  function DeleteFile(file) {
    if (!window.confirm("Are you sure you want to delete " + file.name + "?")) {
      return;
    }
    SetNameBeingDeleted(file.name);
    var formData = new FormData();
    formData.append("filename", file.name);
    formData.append("id", file.id);
    formData.append("Type", props.type);
    axios({
      method: "delete",
      url: Constants.API_URL + "DeleteFile" + Constants.API_KEY,
      headers: {
        "Content-Type": "multipart/form-data",
        userid: loggedInUser.id,
      },
      data: formData,
    })
      .then((response) => {
        props.GetJobFiles(props.job.id, props.type);
        AddAlert("notification", file.name + " deleted successfully.");
        SetNameBeingDeleted(null);
      })
      .catch((error) => {
        AddAlert("error", "Error deleting " + file.name);
        SetNameBeingDeleted(null);
        console.log(error);
      });
  }

  function UploadFiles() {
    var formData = new FormData();
    formData.append("file", file);
    formData.append("id", props.job.id);
    formData.append("Type", props.type);
    SetUploading(true);
    axios({
      method: "post",
      url: Constants.API_URL + "UploadFile" + Constants.API_KEY,
      headers: {
        "Content-Type": "multipart/form-data",
        userid: loggedInUser.id,
      },
      data: formData,
    })
      .then((response) => {
        props.GetJobFiles();
        AddAlert("notification", file.name + " uploaded successfully.");
        SetFile(null);
        SetUploading(false);
      })
      .catch((error) => {
        AddAlert("error", "Error uploading " + file.name);
        SetFile(null);
        SetUploading(false);
        console.log(error);
      });
  }

  useEffect(() => {
    props.GetJobFiles();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="JobFilesModal">
      {PDFViewerModalOpen ? (
        <Modal
          CloseModal={() => SetPDFViewerModalOpen(false)}
          modificationClass="pdfViewer"
        >
          <PDFViewer file={PDFFileToView} startPage={1} />
        </Modal>
      ) : null}
      <div className="ganttItemModalTitle" type="name">
        Files
      </div>
      <div className="JobFilesActions">
        <label className="JobFilesChooseFileLabel">
          <ForagerButton
            lessVerticalPadding={true}
            onClick={() => {}}
            disabled={uploading}
          >
            {file != null ? file.name : "Choose File"}
          </ForagerButton>
          <input
            className="JobFilesChooseFileInput"
            type="file"
            id="file"
            onChange={ChangeFile}
          />
        </label>
        <ForagerButton
          lessVerticalPadding={true}
          onClick={file == null || uploading ? null : UploadFiles}
          disabled={file == null || uploading}
        >
          {uploading ? "Uploading..." : "Upload"}
        </ForagerButton>
      </div>
      <div className="JobBodySection">
        <div className="JobImagePreviewSection">
          {filePreview ? (
            filePreview.includes(".pdf") ? (
              <PDFViewer file={filePreview} isPreview={true} />
            ) : validPreviewImage ? (
              <img
                src={filePreview}
                onClick={OpenInNewTab}
                onError={(e) => {
                  e.target.onerror = null;
                  SetValidPreviewImage(false);
                }}
                alt="Selected File"
              />
            ) : (
              <Icon
                icon="mdi:file-find"
                className="JobFileImagePreviewNotFoundIcon"
                onClick={OpenInNewTab}
              />
            )
          ) : (
            <Icon
              icon="mdi:file-find"
              className="JobFileImagePreviewNotFoundIcon"
              onClick={OpenInNewTab}
            />
          )}
        </div>
        <div className="JobItemSection">
          {props.jobFiles != null ? (
            props.jobFiles.map((f) => (
              <div
                className={
                  (IsClicked(f) ? "JobFilesItem clicked" : "JobFilesItem") +
                  (f.name === nameBeingDeleted ? " deleting" : "")
                }
              >
                <JobFilesItem
                  key={f.saSURI}
                  file={f}
                  isClicked={IsClicked(f)}
                  deleteMethod={DeleteFile}
                  ChangeFilePreview={ChangeFilePreview}
                  ChangeIsClicked={ChangeIsClicked}
                  currentlyDeleting={nameBeingDeleted === f.name}
                  OpenInPDFViewer={OpenInPDFViewer}
                />
              </div>
            ))
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default JobFilesModal;
