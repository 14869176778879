import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar/Sidebar";
import Filter from "./Filter/Filter";
import Gantt from "./Gantt/Gantt";
import IQ from "./IQ/IQ";
import * as Utilities from "../Utilities";
import { useNavigate } from "react-router-dom";
import Modal from "../Generic/Modal";
import TimeClockActions from "./TimeClockActions";
import TimeClockModal from "./Sidebar/Menu/SidebarButtons/SidebarModals/TimeClock/TimeClockModal";
import HQLogoSVG from "../Generic/HQLogoSVG";
import ErrorBoundary from "../Generic/ErrorBoundary";
import Cookies from "universal-cookie";
import IQLogoSVG from "../Generic/IQLogoSVG";
import { useSiteContext } from "../Context";
import IQContext from "../IQContext";

function MainScreen(props) {
  const cookies = new Cookies();
  const {
    AddAlert,
    loggedInUser,
    currentTimeEntry,
    AddData,
    UpdateData,
    GetTimeEntries,
    statuses,
    fieldCustomizations,
    FeatureCheck,
  } = useSiteContext();
  const [startDate, SetStartDate] = useState("");
  const [endDate, SetEndDate] = useState("");
  const [content, SetContent] = useState("");
  const [search, SetSearch] = useState("");
  const [filteredOutStatuses, SetFilteredOutStatuses] = useState([]);
  const [filteredUsers, SetFilteredUsers] = useState([]);
  const [filteredJobs, SetFilteredJobs] = useState([]);
  const [filteredTasks, SetFilteredTasks] = useState([]);
  const [filteredClients, SetFilteredClients] = useState([]);
  const [filteredSortName, SetFilteredSortName] = useState(
    cookies.get("scheduleItemSort") ?? "Name"
  );
  const [filteredSortValue, SetFilteredSortValue] = useState(
    cookies.get("scheduleItemSortDirection") != null
      ? cookies.get("scheduleItemSortDirection") === "true"
      : true
  );
  const [jobsForFilter, SetJobsForFilter] = useState([]);
  const [clientsForFilter, SetClientsForFilter] = useState([]);
  const [usersForFilter, SetUsersForFilter] = useState([]);
  const [tasksForFilter, SetTasksForFilter] = useState([]);
  const [timeClockModal, SetTimeClockModal] = useState(false);
  const [itemsBefore, SetItemsBefore] = useState(0);
  const [IQOpen, SetIQOpen] = useState(GetDefaultIQOpenValue());
  const navigate = useNavigate();

  function ToggleTimeClockModal() {
    SetTimeClockModal(!timeClockModal);
    SetIQOpen(false);
  }

  function GetDefaultIQOpenValue() {
    let defaultIQOpen = window.location.hostname.includes("iq");
    if (FeatureCheck("IQ") && !FeatureCheck("HQ")) {
      cookies.set("iqOpen", true, {
        path: "/",
        expires: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 365),
      });
      defaultIQOpen = true;
    }
    if (cookies.get("iqOpen") != null) {
      defaultIQOpen = cookies.get("iqOpen") === "true";
    }
    if (defaultIQOpen) {
      document.title = "ForagerIQ";
    }
    return defaultIQOpen;
  }

  function ToggleIQOpen(value) {
    if (value != null && value?.type == null) {
      document.title = value ? "ForagerIQ" : "ForagerHQ";
      cookies.set("iqOpen", value, {
        path: "/",
        expires: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 365),
      });
      SetIQOpen(value);
    } else {
      cookies.set("iqOpen", !IQOpen, {
        path: "/",
        expires: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 365),
      });
      document.title = !IQOpen ? "ForagerIQ" : "ForagerHQ";
      SetIQOpen(!IQOpen);
    }
  }

  function ChangeItemsBefore(newItemsBefore) {
    SetItemsBefore(newItemsBefore);
  }

  function GenerateInitialDates() {
    let newStartDate = new Date();
    let newEndDate = new Date();
    newStartDate.setDate(newStartDate.getDate() - 7);
    newEndDate.setDate(newEndDate.getDate() + 90);
    SetStartDate(Utilities.FormatDate(newStartDate));
    SetEndDate(Utilities.FormatDate(newEndDate));
  }

  function CalculateTimeSpan() {
    return Utilities.GetDayTimespan(startDate, endDate);
  }

  function ChangeStartDate(value) {
    SetStartDate(value);
  }

  function ChangeEndDate(value) {
    SetEndDate(value);
  }

  function ChangeSearch(event) {
    SetSearch(event.target.value);
  }

  function ChangeFilteredUsers(newFilteredUsers) {
    SetFilteredUsers(newFilteredUsers);
  }

  function ChangeFilteredJobs(newFilteredJobs) {
    SetFilteredJobs(newFilteredJobs);
  }

  function ChangeFilteredTasks(newFilteredTasks) {
    SetFilteredTasks(newFilteredTasks);
  }

  function ChangeFilteredClients(newFilteredClients) {
    SetFilteredClients(newFilteredClients);
  }

  function ChangeFilteredSortName(newFilteredSortName) {
    SetFilteredSortName(newFilteredSortName);
    cookies.set("scheduleItemSort", newFilteredSortName, {
      path: "/",
      expires: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 365),
    });
  }

  function ChangeFilteredSortValue(newFilteredSortValue) {
    SetFilteredSortValue(newFilteredSortValue);
    cookies.set("scheduleItemSortDirection", newFilteredSortValue, {
      path: "/",
      expires: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 365),
    });
  }

  function ChangeJobsForFilter(newJobsForFilter) {
    SetJobsForFilter(newJobsForFilter);
  }

  function ChangeTasksForFilter(newTasksForFilter) {
    SetTasksForFilter(newTasksForFilter);
  }

  function ChangeClientsForFilter(newClientsForFilter) {
    SetClientsForFilter(newClientsForFilter);
  }

  function ChangeUsersForFilter(newUsersForFilter) {
    SetUsersForFilter(newUsersForFilter);
  }

  function ResetFilteredStatuses() {
    return statuses
      .filter((status) => !status.countedAsActive)
      .map((status) => status.id);
  }

  async function ClockOut() {
    let endTime = new Date();
    let formData = new FormData();
    endTime.setSeconds(0, 0);
    formData.append("id", currentTimeEntry.id);
    formData.append("endTime", endTime.toISOString());
    let response = await UpdateData(
      "TimeEntry",
      formData,
      GetTimeEntries,
      null
    );
    if (!response.includes("Success")) {
      AddAlert("error", "There was an error clocking out. Please try again.");
    }
    GetTimeEntries();
  }

  useEffect(() => {
    let newFilteredOutStatuses = ResetFilteredStatuses();
    SetFilteredOutStatuses(newFilteredOutStatuses);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statuses]);

  useEffect(() => {
    GenerateInitialDates();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.path[1] !== "") {
      SetContent(props.path[1]);
    } else {
      navigate("/" + fieldCustomizations.general.job.pluralName);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.path]);

  return (
    <IQContext>
      <div className="mainScreenContainer">
        <Sidebar
          ImpersonateUser={props.ImpersonateUser}
          impersonating={props.impersonating}
          Logout={props.Logout}
          jobsForFilter={jobsForFilter}
          clientsForFilter={clientsForFilter}
          usersForFilter={usersForFilter}
          tasksForFilter={tasksForFilter}
          timeClockModal={timeClockModal}
          iqOpen={IQOpen}
          ToggleTimeClockModal={ToggleTimeClockModal}
          ToggleIQOpen={ToggleIQOpen}
        />
        <div className="mainScreenContentHeader">
          <div className="mainScreenContentHeaderTitle">
            {/* <Icon icon="mdi:bookmark-outline" /> */}
            {props.impersonating ? (
              <>
                <div className="impersonationIndicator">
                  {"As Customer: " + loggedInUser.customer.name}
                </div>
                <div className="impersonationIndicator">
                  {fieldCustomizations.general.user.name +
                    ": " +
                    loggedInUser.firstName +
                    " " +
                    loggedInUser.lastName}
                </div>
              </>
            ) : null}
          </div>
          <TimeClockActions
            ClockOutFunction={ClockOut}
            showText={true}
            showTimer={true}
            ToggleTimeClockModal={ToggleTimeClockModal}
          />

          <div className="mainScreenContentHeaderIconContainer">
            {FeatureCheck("IQ") && FeatureCheck("HQ") ? (
              <div
                className="mainScreenIcon"
                onClick={ToggleIQOpen}
                data-tip={"Switch to " + (IQOpen ? "HQ" : "IQ")}
              >
                {IQOpen ? (
                  <HQLogoSVG full={false} />
                ) : (
                  <IQLogoSVG full={false} />
                )}
              </div>
            ) : (
              <div></div>
            )}
            <div className="mainLogo">
              {IQOpen ? <IQLogoSVG full={true} /> : <HQLogoSVG full={true} />}
            </div>
          </div>
        </div>
        <ErrorBoundary
          loggedInUser={loggedInUser}
          AddData={AddData}
          className="mainScreenContentBody errorScreen"
        >
          {IQOpen ? (
            <IQ />
          ) : (
            <>
              <div className="iqSideBar"></div>
              <div className="mainGanttScreen">
                <div className="mainScreenContentFilterRow">
                  <Filter
                    ChangeStartDate={ChangeStartDate}
                    ChangeEndDate={ChangeEndDate}
                    ChangeSearch={ChangeSearch}
                    SetStatusFilter={SetFilteredOutStatuses}
                    ChangeFilteredUsers={ChangeFilteredUsers}
                    ChangeFilteredJobs={ChangeFilteredJobs}
                    ChangeFilteredTasks={ChangeFilteredTasks}
                    ChangeFilteredClients={ChangeFilteredClients}
                    ChangeFilteredSortName={ChangeFilteredSortName}
                    ChangeFilteredSortValue={ChangeFilteredSortValue}
                    startDate={startDate}
                    endDate={endDate}
                    search={search}
                    statusFilter={filteredOutStatuses}
                    filteredUsers={filteredUsers}
                    filteredJobs={filteredJobs}
                    filteredTasks={filteredTasks}
                    filteredClients={filteredClients}
                    filteredSortName={filteredSortName}
                    filteredSortValue={filteredSortValue}
                    jobsForFilter={jobsForFilter}
                    clientsForFilter={clientsForFilter}
                    usersForFilter={usersForFilter}
                    tasksForFilter={tasksForFilter}
                    path={props.path}
                    itemsBefore={itemsBefore}
                  />
                </div>

                <div className="mainScreenContentBody">
                  <Gantt
                    startDate={startDate}
                    endDate={endDate}
                    content={content}
                    statusFilter={filteredOutStatuses}
                    filteredUsers={filteredUsers}
                    filteredJobs={filteredJobs}
                    filteredTasks={filteredTasks}
                    filteredClients={filteredClients}
                    filteredSortName={filteredSortName}
                    filteredSortValue={filteredSortValue}
                    jobsForFilter={jobsForFilter}
                    clientsForFilter={clientsForFilter}
                    usersForFilter={usersForFilter}
                    ChangeFilteredUsers={ChangeFilteredUsers}
                    ChangeFilteredJobs={ChangeFilteredJobs}
                    ChangeFilteredTasks={ChangeFilteredTasks}
                    ChangeFilteredClients={ChangeFilteredClients}
                    ChangeJobsForFilter={ChangeJobsForFilter}
                    ChangeClientsForFilter={ChangeClientsForFilter}
                    ChangeUsersForFilter={ChangeUsersForFilter}
                    ChangeTasksForFilter={ChangeTasksForFilter}
                    ChangeFilteredSortName={ChangeFilteredSortName}
                    ChangeFilteredSortValue={ChangeFilteredSortValue}
                    search={search}
                    timeSpan={CalculateTimeSpan()}
                    path={props.path}
                    ChangeItemsBefore={ChangeItemsBefore}
                  />
                </div>
              </div>
            </>
          )}
        </ErrorBoundary>
        {timeClockModal ? (
          <Modal
            CloseModal={() => SetTimeClockModal(false)}
            modificationClass={"noPaddingAlternate fromTop"}
          >
            <TimeClockModal
              CloseModal={() => SetTimeClockModal(false)}
              jobsForFilter={jobsForFilter}
              clientsForFilter={clientsForFilter}
              usersForFilter={usersForFilter}
              tasksForFilter={tasksForFilter}
              ToggleIQOpen={ToggleIQOpen}
            />
          </Modal>
        ) : null}
      </div>
    </IQContext>
  );
}

export default MainScreen;
