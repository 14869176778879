import React, { useEffect, useState, useRef } from "react";
import { Icon } from "@iconify/react";
import { useSiteContext } from "../../../../../Context";
import DropDownMenu from "../../../../../Generic/DropDownMenu";
import Modal from "../../../../../Generic/Modal";
import MultiSelectorModal from "../../../../../Generic/MultiSelectorField";
import HtmlEditor from "../../../../../Generic/HtmlEditor";
import parse from "html-react-parser";
import JobFilesModal from "./JobFilesModal";
import { useNavigate } from "react-router-dom";
import TimeClockActions from "../../../../TimeClockActions";
import ForagerButton from "../../../../../Generic/ForagerButton";
import ForagerTable from "../../../../../Generic/ForagerTable";
import ForagerTableColumn from "../../../../../Classes/ForagerTableColumn";
import GanttItemModal from "./GanttItemModal";
import ForagerInputModal from "../../../../../Generic/ForagerInputModal";
import * as Utilities from "../../../../../Utilities";
import AutosizedTextArea from "../../../../../Generic/AutosizedTextArea";
import Cookies from "universal-cookie";
import axios from "axios";
import ActivityContent from "./ActivityContent";
import * as Constants from "../../../../../Constants";

function GanttItemInformation(props) {
  const {
    loggedInUser,
    currentTimeEntry,
    GetData,
    AddData,
    UpdateData,
    GetTimeEntries,
    AddAlert,
    fieldCustomizations,
    PermissionCheck,
  } = useSiteContext();
  const cookies = new Cookies();
  const editorRef = useRef(null);
  const [fileModal, SetFileModal] = useState(false);
  const [showInactiveTasks, SetShowInactiveTasks] = useState(false);
  const [showDescription, SetShowDescription] = useState(
    cookies.get("expandDescription") != null
      ? cookies.get("expandDescription") === "true"
      : true
  );
  const [showTasks, SetShowTasks] = useState(
    cookies.get("expandtasks") != null
      ? cookies.get("expandtasks") === "true"
      : true
  );
  const [showActivity, SetShowActivity] = useState(
    cookies.get("expandActivity") != null
      ? cookies.get("expandActivity") === "true"
      : false
  );
  const [addTaskModal, SetAddTaskModal] = useState(false);
  const [addTaskModalEditsPending, SetAddTaskModalEditsPending] =
    useState(false);
  const [
    currentTimeEntryMatchesCurrentItem,
    SetCurrentTimeEntryMatchesCurrentItem,
  ] = useState(false);
  const [showNewCommentEntry, SetShowNewCommentEntry] = useState(false);
  const [commentSaving, SetCommentSaving] = useState(false);
  const [showChangeLog, SetShowChangeLog] = useState(false);
  const [currentActivities, SetCurrentActivities] = useState([]);
  const [currentComments, SetCurrentComments] = useState(null);
  const [currentChangeLog, SetCurrentChangeLog] = useState([]);
  const [timeclockButtonDisabled, SetTimeclockButtonDisabled] = useState(false);
  const [canCloseCommentBox, SetCanCloseCommentBox] = useState(true);
  const [jobFiles, SetJobFiles] = useState([]);

  const html = ChangeImgSourceInHtmlString(props.description);

  const navigate = useNavigate();

  function ShowAddTaskModal(e) {
    e.stopPropagation();

    SetAddTaskModal(true);
  }

  function HideAddTaskModal(override = false) {
    let allowedToClose = true;
    if (addTaskModalEditsPending && !override) {
      allowedToClose = window.confirm(
        "Are you sure you want to cancel? Your info will not be saved."
      );
    }
    if (allowedToClose) {
      SetAddTaskModal(!addTaskModal);
      SetAddTaskModalEditsPending(false);
    }
  }

  function ChangeAddTaskModalEditsPending(value) {
    SetAddTaskModalEditsPending(value);
  }

  function ToggleNewCommentForm() {
    let allowedToClose = true;
    if (!canCloseCommentBox) {
      allowedToClose = window.confirm(
        "You have unsaved changes. Are you sure you want to close?"
      );
    }
    if (allowedToClose) {
      SetShowNewCommentEntry(!showNewCommentEntry);
      SetCanCloseCommentBox(true);
    }
  }

  async function SaveNewComment(newComment) {
    if (newComment === "" || newComment == null) {
      AddAlert("error", "Please enter a comment.");
      return;
    }
    SetCommentSaving(true);
    let formData = new FormData();
    formData.append("userId", loggedInUser.id);
    formData.append("scheduleItemId", props.item.id);
    formData.append("timestamp", "now");
    formData.append("content", newComment);
    let response = await AddData("Comment", formData);
    if (response != null && !isNaN(response)) {
      SetShowNewCommentEntry(false);
      SetCanCloseCommentBox(true);
      RefreshComments();
      let alertMessage = "Comment saved successfully.";
      AddAlert("notification", alertMessage);
      SetShowActivity(true);
    } else {
      AddAlert(
        "error",
        "There was an error saving your comment. Please try again."
      );
    }
    SetCommentSaving(false);
  }

  function ToggleSections(section) {
    switch (section) {
      case "Description":
        SetShowDescription(!showDescription);
        cookies.set("expandDescription", !showDescription, {
          path: "/",
          expires: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 365),
        });
        break;
      case "Tasks":
        SetShowTasks(!showTasks);
        cookies.set("expandtasks", !showTasks, {
          path: "/",
          expires: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 365),
        });
        break;
      case "Activity":
        SetShowActivity(!showActivity);
        cookies.set("expandActivity", !showActivity, {
          path: "/",
          expires: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 365),
        });
        break;
      default:
        break;
    }
  }

  function FileModal() {
    SetFileModal(!fileModal);
  }

  function ToggleShowInactiveTasks() {
    SetShowInactiveTasks(!showInactiveTasks);
  }

  function ToggleShowChangeLog() {
    SetShowChangeLog(!showChangeLog);
  }

  function CancelEdit() {
    if (props.editsPending && !props.add) {
      if (
        window.confirm(
          "Are you sure you want to cancel? All unsaved changes will be lost."
        )
      ) {
        props.ChangeEditMode();
        props.ResetDisplay();
        props.SetEditsPending(false);
      }
    } else {
      props.ChangeEditMode();
      props.ResetDisplay();
    }
  }

  function GetStatusValueDisplay(statusParameter) {
    switch (statusParameter.type) {
      case "Text":
      case "Textarea":
      case "Number":
        if (statusParameter.value instanceof Function) {
          return statusParameter.value();
        }
        return statusParameter.value;
      case "Date":
        return statusParameter.displayInsteadOfValue;
      case "Array":
        return statusParameter.value[0] != null
          ? statusParameter.arrayAssignable
              .filter((item) => statusParameter.value.includes(item.id))
              .map((item, index) => {
                let returnValue = item.name;
                if (index !== props.item.assignedUsers.length - 1) {
                  returnValue += ", ";
                }
                return returnValue;
              })
          : "None";
      case "Dropdown":
        return statusParameter.displayInsteadOfValue;
      case "Checkbox":
        return statusParameter.value instanceof Function
          ? statusParameter.value()
          : statusParameter.value
          ? "Yes"
          : "No";
      default:
        return "";
    }
  }

  async function ClockIn() {
    SetTimeclockButtonDisabled(true);
    let currentTime = new Date();
    // currentTime.setSeconds(0, 0);
    let addFormData = new FormData();
    addFormData.append("scheduleItemId", props.item.id);
    addFormData.append("startTime", currentTime.toISOString());
    addFormData.append("userId", loggedInUser.id);
    let newTimeEntryId = await AddData("TimeEntry", addFormData);
    if (newTimeEntryId != null) {
      await GetTimeEntries();
    }
    SetTimeclockButtonDisabled(false);
  }

  async function ClockOut() {
    let currentTime = new Date();
    if (currentTimeEntry != null) {
      SetTimeclockButtonDisabled(true);
      let updateFormData = new FormData();
      currentTime.setSeconds(0, 0);
      updateFormData.append("id", currentTimeEntry.id);
      updateFormData.append("endTime", currentTime.toISOString());
      let response = await UpdateData(
        "TimeEntry",
        updateFormData,
        GetTimeEntries,
        null
      );
      if (!response.includes("Success")) {
        AddAlert("error", "There was an error clocking out. Please try again.");
      }

      await GetTimeEntries();
      SetTimeclockButtonDisabled(false);
    }
  }

  function SwitchClocked() {
    ClockOut();
    setTimeout(ClockIn, 200);
  }

  function GetDateStringForInput(date) {
    if (date === "" || date.includes("-")) {
      return date;
    }
    let dateStringFormat = date.replace(",", "").replace(" ", "").split("/");
    let dateString = dateStringFormat[2] + "-";
    dateString +=
      dateStringFormat[0].length === 1
        ? "0" + dateStringFormat[0]
        : dateStringFormat[0];
    dateString += "-";
    dateString +=
      dateStringFormat[1].length === 1
        ? "0" + dateStringFormat[1]
        : dateStringFormat[1];
    return dateString;
  }

  function GenerateActivity() {
    let activity = [];
    if (props.item.comments != null) {
      let commentsToAdd = props.item.comments;
      if (currentComments != null) {
        commentsToAdd = currentComments;
      }
      commentsToAdd.forEach((comment) => {
        activity.push({
          id: activity.length + 1,
          commentId: comment.id,
          edited: comment.edited,
          type: "Comment",
          timestamp: comment.timestamp,
          user: comment.user.name,
          content: comment.content,
          isLoggedInUser: comment.userId === loggedInUser.id,
        });
      });
    }
    if (showChangeLog) {
      currentChangeLog.forEach((change) => {
        let newChangeLog = ParseChangeLogDetails(change.message);
        activity.push({
          id: activity.length + 1,
          type: change.auditType,
          timestamp: change.timestamp,
          user: change.user.name,
          content: GetChangeLogLabels(newChangeLog),
          changeDetails: newChangeLog,
          isLoggedInUser: change.userId === loggedInUser.id,
        });
      });
    }

    SetCurrentActivities(activity);
  }

  function ParseChangeLogDetails(changeLogMessage) {
    let arrayStart = changeLogMessage.indexOf("[");
    if (arrayStart === -1) {
      return null;
    }
    let isolatedArray = changeLogMessage.substring(
      arrayStart,
      changeLogMessage.length
    );
    let parsedArray = JSON.parse(isolatedArray);
    return parsedArray;
  }

  function GetChangeLogLabels(changeLog) {
    let changeLogLabels = changeLog.map((change) => {
      return Utilities.CapitalizeFirstLetter(change.Key);
    });
    return changeLogLabels.join(", ");
  }

  async function RefreshComments() {
    let commentParams = new URLSearchParams();
    commentParams.append("scheduleItemId", props.item.id);

    let newComments = await GetData("Comment", commentParams);
    if (newComments != null && newComments instanceof Array) {
      SetCurrentComments(newComments);
    }

    RefreshChangeLog();
  }

  async function RefreshChangeLog() {
    if (props.item != null && PermissionCheck("auditLog", "view")) {
      let changeLogParams = new URLSearchParams();
      changeLogParams.append("customer", loggedInUser.customer.id);
      changeLogParams.append("customerId", loggedInUser.customer.id);
      changeLogParams.append("recordType", "ScheduleItem");
      changeLogParams.append("affectedId", props.item.id);

      let newChangeHistory = await GetData("AuditLog", changeLogParams);
      if (newChangeHistory != null && newChangeHistory instanceof Array) {
        SetCurrentChangeLog(newChangeHistory);
      }
    }
  }

  function GoToJob() {
    if (props.item != null && props.item.job != null) {
      navigate(
        "/" +
          fieldCustomizations.general.job.pluralName +
          "/" +
          props.item.job.id
      );
    }
  }

  function ChangeImgSourceInHtmlString(html) {
    if (html == null || html === "") {
      return html;
    }
    let htmlString = html;
    //regex to find src of img tag that contains "foragerhqstorage", and "?"
    let regex = /src="([^"]*foragerhqstorage[^"]*\?[^"]*)"/g;
    let match = regex[Symbol.matchAll](htmlString);
    let next = match.next();
    let done = next.done;
    while (!done) {
      let src = next.value[1];
      src = src.substring(0, src.indexOf("?"));
      htmlString = htmlString.replace(next.value[1], src);
      next = match.next();
      done = next.done;
    }
    return htmlString;
  }

  function GetJobFiles() {
    if (props.item == null || props.item.id == null) {
      return;
    }
    axios
      .get(Constants.API_URL + "GetFiles" + Constants.API_KEY, {
        params: {
          id: props.item.id,
          type: "job",
        },
      })
      .then((res) => {
        SetJobFiles(res.data);
      });
  }

  var taskChartColumns = [
    new ForagerTableColumn({
      header: "Start Date",
      ValueFunction: (item) => {
        return item.startDate.substring(0, 10);
      },
      sortable: true,
      sortType: "date",
      hideOnMobile: true,
    }),
    new ForagerTableColumn({
      header: "End Date",
      ValueFunction: (item) => {
        return item.endDate.substring(0, 10);
      },
      sortable: true,
      sortType: "date",
      hideOnMobile: true,
    }),
    new ForagerTableColumn({
      header: "Status",
      propertyPathArray: ["status", "name"],
      sortable: true,
      hideOnMobile: true,
    }),
    new ForagerTableColumn({
      header: "Title",
      property: "name",
      sortable: true,
      stretch: true,
    }),
    new ForagerTableColumn({
      header: "Time",
      ValueFunction: (item) => {
        return item.totalTimeRounded15 + "/" + item.budgetedTime;
      },
      sortable: true,
      sortType: "number",
      sortPath: ["totalTimeRounded15"],
      hideOnMobile: true,
    }),
    new ForagerTableColumn({
      header: "Open",
      ValueFunction: (item) => {
        return props.editMode ? (
          <Icon
            icon="fluent-mdl2:open-in-new-tab"
            className="withPointer"
            onClick={() => {
              let link =
                "/" +
                fieldCustomizations.general.task.pluralName +
                "/" +
                item.id;
              window.open(link, "_blank");
            }}
          />
        ) : (
          <Icon
            icon="material-symbols:arrow-forward-rounded"
            className="withPointer"
            onClick={() => {
              let link =
                "/" +
                fieldCustomizations.general.task.pluralName +
                "/" +
                item.id;
              navigate(link);
            }}
          />
        );
      },
      width: 5,
      fontSize: 1.5,
    }),
  ];

  function GetActivityDetailValue(item) {
    return <ActivityContent item={item} RefreshComments={RefreshComments} />;
  }

  var activityChartColumns = [
    new ForagerTableColumn({
      header: "Date",
      ValueFunction: (item) => {
        return new Date(item.timestamp).toLocaleDateString();
      },
      TooltipFunction: (item) => {
        return new Date(item.timestamp).toLocaleTimeString();
      },
      sortable: true,
      sortType: "date",
      isDefaultSort: true,
    }),
    new ForagerTableColumn({
      header: "User",
      property: "user",
      sortable: true,
    }),
    new ForagerTableColumn({
      header: "Type",
      property: "type",
      sortable: true,
    }),
    new ForagerTableColumn({
      header: "Details",
      ValueFunction: GetActivityDetailValue,
      stretch: true,
      textAlignment: "left",
    }),
  ];

  var activityChartSubtableColumns = [
    new ForagerTableColumn({
      header: "Field",
      property: "Key",
      sortable: true,
    }),
    new ForagerTableColumn({
      header: "Changed To",
      property: "Value",
      stretch: true,
      textAlignment: "left",
    }),
  ];

  useEffect(() => {
    SetCurrentTimeEntryMatchesCurrentItem(
      currentTimeEntry?.scheduleItem?.id === props?.item?.id
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTimeEntry]);

  useEffect(() => {
    if (currentComments != null) {
      GenerateActivity();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentComments, showChangeLog, currentChangeLog]);

  useEffect(() => {
    if (props.item != null && props.item.comments != null) {
      SetCurrentComments(props.item.comments);
    }
    RefreshChangeLog();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.item]);

  useEffect(() => {
    GetJobFiles();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="ganttItemInformationContainer">
      <div className="ganttItemInformationBody">
        <div className="ganttItemInformationDescriptionContainer Job">
          <div className="ganttItemInformationTitle">
            <div className="relativeAnchor">
              {props.add ? (
                <Icon icon="mdi:required" className="requiredIndicator" />
              ) : null}
            </div>
            {props.editMode ? (
              <input
                className={"ganttItemStatusValueEdit title"}
                type="text"
                value={props.name}
                onChange={props.ChangeName}
                placeholder="Name"
                spellCheck="true"
                maxLength="30"
              />
            ) : (
              props.name
            )}
          </div>
          <div className="ganttItemTimeEntryButtons">
            {!props.add ? (
              <TimeClockActions
                ClockInFunction={currentTimeEntry == null ? ClockIn : null}
                SwitchFunction={
                  currentTimeEntry != null &&
                  !currentTimeEntryMatchesCurrentItem
                    ? SwitchClocked
                    : null
                }
                ClockOutFunction={ClockOut}
                showText={true}
                buttonDisabled={timeclockButtonDisabled}
              />
            ) : (
              <div className="ganttItemInformationAddTitle">
                {props.addTitle}
              </div>
            )}
          </div>
          <div className="ganttItemStatusContainer">
            <div className="ganttItemStatusList">
              {props.itemStatusParameters.map((statusParameter, index) => (
                <div className="ganttItemStatus" key={index}>
                  <div className="ganttItemInformationLabel">
                    {statusParameter.required && props.add ? (
                      <div className="required">
                        <Icon
                          icon="mdi:required"
                          className="requiredIndicator"
                        />
                      </div>
                    ) : null}
                    {statusParameter.label}
                  </div>
                  {!props.editMode || !statusParameter.editable ? (
                    <div
                      className={
                        "ganttItemStatusValue " + statusParameter.fieldName
                      }
                      onClick={
                        statusParameter.fieldName === "job" ? GoToJob : null
                      }
                    >
                      {GetStatusValueDisplay(statusParameter)}
                      {statusParameter.fieldName === "job" ? (
                        <div className="gotoJobArrowContainer">
                          <Icon
                            icon="material-symbols:arrow-forward-rounded"
                            className="gotoJobArrow"
                          />
                        </div>
                      ) : null}
                    </div>
                  ) : statusParameter.type === "Array" ? (
                    <div className="ganttItemStatusValueEdit dropdown">
                      <MultiSelectorModal
                        currentlyAssigned={
                          statusParameter.value instanceof Function
                            ? statusParameter.value()
                            : statusParameter.value
                        }
                        assignableItems={statusParameter.arrayAssignable}
                        item={props.item}
                        inversion={false}
                        SetAssignmentFunction={statusParameter.ChangeFunction}
                      />
                    </div>
                  ) : statusParameter.type === "Dropdown" ? (
                    <>
                      <div className="ganttItemStatusDropDownContainer">
                        <DropDownMenu
                          className="ganttItemStatusDropDown"
                          value={
                            statusParameter.value instanceof Function
                              ? statusParameter.value()
                              : statusParameter.value
                          }
                          onChange={statusParameter.ChangeFunction}
                          options={statusParameter.arrayAssignable}
                          showBlank={statusParameter.showBlankInDropdown}
                          sortBy={statusParameter.sortBy}
                          currentlySelectedFullOption={
                            statusParameter.showCurrentInDropdown
                              ? {
                                  id:
                                    statusParameter.value instanceof Function
                                      ? statusParameter.value()
                                      : statusParameter.value,
                                  name: statusParameter.displayInsteadOfValue,
                                }
                              : null
                          }
                        />
                      </div>
                      {statusParameter.fieldName === "Job" ? (
                        <Icon
                          icon="material-symbols:arrow-forward-rounded"
                          className="gotoTaskArrow"
                        />
                      ) : null}
                    </>
                  ) : statusParameter.type === "Date" ? (
                    <input
                      type="date"
                      className="ganttItemStatusValueEdit date"
                      onChange={statusParameter.ChangeFunction}
                      value={GetDateStringForInput(
                        statusParameter.value instanceof Function
                          ? statusParameter.value()
                          : statusParameter.value
                      )}
                      min={statusParameter.minDate}
                      max={statusParameter.maxDate}
                    />
                  ) : statusParameter.type === "Text" ? (
                    <input
                      className="ganttItemStatusValueEdit"
                      type={statusParameter.type.toLowerCase()}
                      value={
                        statusParameter.value instanceof Function
                          ? statusParameter.value()
                          : statusParameter.value
                      }
                      onChange={statusParameter.ChangeFunction}
                      placeholder={statusParameter.label}
                    />
                  ) : statusParameter.type === "Textarea" ? (
                    <AutosizedTextArea
                      className="ganttItemStatusValueEdit textarea"
                      value={
                        statusParameter.value instanceof Function
                          ? statusParameter.value()
                          : statusParameter.value
                      }
                      onChange={statusParameter.ChangeFunction}
                      placeholder={statusParameter.label}
                    />
                  ) : statusParameter.type === "Checkbox" ? (
                    <input
                      className="ganttItemStatusValueEdit checkbox"
                      type={statusParameter.type.toLowerCase()}
                      checked={
                        statusParameter.value instanceof Function
                          ? statusParameter.value()
                          : statusParameter.value
                      }
                      onChange={statusParameter.ChangeFunction}
                    />
                  ) : statusParameter.type === "Number" ? (
                    <input
                      className="ganttItemStatusValueEdit"
                      type={statusParameter.type.toLowerCase()}
                      value={
                        statusParameter.value instanceof Function
                          ? statusParameter.value()
                          : statusParameter.value
                      }
                      onChange={statusParameter.ChangeFunction}
                      placeholder={statusParameter.label}
                    />
                  ) : null}
                </div>
              ))}
            </div>
            <div className="ganttItemInformationActionsContainer">
              {props.add ? (
                <div></div>
              ) : (
                <>
                  <ForagerButton
                    fullWidth={true}
                    onClick={FileModal}
                    className="ganttItemViewPhotosButton"
                  >
                    <div className="ganttItemFilesNumber">
                      {jobFiles.length}
                    </div>
                    Photos/Files
                  </ForagerButton>
                  {props.editMode ? (
                    <AutosizedTextArea
                      className="ganttItemStatusValueEdit textarea updateComment"
                      value={props.updateComment}
                      onChange={props.ChangeUpdateComment}
                      placeholder={"Enter Change Comment..."}
                    />
                  ) : (
                    <div></div>
                  )}
                </>
              )}
              <div className="ganttItemInformationSaveClose">
                {props.editsPending || props.editMode ? (
                  <>
                    <ForagerButton
                      fullWidth={true}
                      exit={true}
                      onClick={CancelEdit}
                    >
                      Cancel
                    </ForagerButton>
                    <ForagerButton
                      fullWidth={true}
                      disabled={!(props.editsPending || props.currentlySaving)}
                      onClick={!props.currentlySaving ? props.UpdateItem : null}
                    >
                      {!props.currentlySaving
                        ? props.add
                          ? "Add"
                          : "Save Changes"
                        : "Saving..."}
                    </ForagerButton>
                  </>
                ) : (
                  <>
                    <ForagerButton
                      fullWidth={true}
                      exit={true}
                      onClick={props.CloseModal}
                    >
                      Close
                    </ForagerButton>
                    <ForagerButton
                      fullWidth={true}
                      onClick={props.ChangeEditMode}
                    >
                      Edit
                    </ForagerButton>
                  </>
                )}
              </div>
            </div>
          </div>
          <div
            className={
              props.add
                ? "ganttItemInformationDetailContainer add"
                : "ganttItemInformationDetailContainer"
            }
          >
            <div className="ganttItemInformationLabel detail">
              <div
                className="minimizeLabel"
                onClick={() => ToggleSections("Description")}
              >
                <Icon
                  icon={
                    props.add
                      ? "mdi:required"
                      : showDescription
                      ? "ic:round-expand-less"
                      : "ic:round-expand-more"
                  }
                  className={
                    props.add ? "minimizeIcon requiredDetail" : "minimizeIcon "
                  }
                />
                Description
              </div>
            </div>
            <div
              className={
                props.add
                  ? showDescription
                    ? "ganttItemDetailValueContainer description add"
                    : "ganttItemDetailValueContainer description add valuesHidden"
                  : showDescription
                  ? "ganttItemDetailValueContainer description"
                  : "ganttItemDetailValueContainer description valuesHidden"
              }
            >
              {!props.editMode ? (
                showDescription ? (
                  <div className="ganttItemDetailValue description">
                    {parse(html) !== "" ? (
                      parse(html)
                    ) : (
                      <div className="noDescription">No Description</div>
                    )}
                  </div>
                ) : null
              ) : (
                <HtmlEditor
                  editorRef={editorRef}
                  value={html}
                  onChange={props.ChangeDescription}
                  SetEditsPending={props.SetEditsPending}
                  disablePlugins={props.add ? ["autoresize"] : null}
                />
              )}
            </div>
            {!props.add && props.hasTasks ? (
              <>
                {" "}
                <div className="ganttItemInformationLabel detail">
                  <div
                    className="minimizeLabel"
                    onClick={() => ToggleSections("Tasks")}
                  >
                    <Icon
                      icon={
                        showTasks
                          ? "ic:round-expand-less"
                          : "ic:round-expand-more"
                      }
                      className="minimizeIcon"
                    />
                    {fieldCustomizations.general.task.pluralName}
                    <Icon
                      icon="vaadin:plus-circle"
                      className="rangePlus"
                      onClick={ShowAddTaskModal}
                    />
                  </div>
                  <div
                    className="taskShowInactiveLabel"
                    onClick={ToggleShowInactiveTasks}
                  >
                    {"Show Inactive " +
                      fieldCustomizations.general.task.name +
                      "s"}
                    <input
                      id="taskShowInactiveCheckbox"
                      type="checkbox"
                      checked={showInactiveTasks}
                      onChange={ToggleShowInactiveTasks}
                    />
                  </div>
                </div>
                {props.item.tasks != null ? (
                  <div
                    className={
                      showTasks
                        ? "ganttItemDetailValueContainer"
                        : "ganttItemDetailValueContainer valuesHidden"
                    }
                  >
                    <ForagerTable
                      className="ganttItemDetailTaskTable"
                      columns={taskChartColumns}
                      items={
                        showInactiveTasks
                          ? props.item.tasks
                          : props.item.tasks.filter(
                              (task) => task.status.countedAsActive
                            )
                      }
                    />
                  </div>
                ) : null}
              </>
            ) : null}
            {!props.add ? (
              <>
                <div className="ganttItemInformationLabel detail">
                  <div
                    className="minimizeLabel"
                    onClick={() => ToggleSections("Activity")}
                  >
                    <Icon
                      icon={
                        showActivity
                          ? "ic:round-expand-less"
                          : "ic:round-expand-more"
                      }
                      className="minimizeIcon"
                    />
                    Activity
                  </div>
                  <div className="AddCommentSection">
                    <div
                      className="taskShowInactiveLabel"
                      onClick={ToggleShowChangeLog}
                    >
                      Show Change Log
                      <input
                        id="taskShowInactiveCheckbox"
                        type="checkbox"
                        checked={showChangeLog}
                        onChange={ToggleShowChangeLog}
                      />
                    </div>
                    <div>
                      <ForagerButton
                        smallText={true}
                        lessVerticalPadding={true}
                        onClick={ToggleNewCommentForm}
                      >
                        New Comment
                      </ForagerButton>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    showActivity
                      ? "ganttItemDetailValueContainer activity"
                      : "ganttItemDetailValueContainer activity valuesHidden"
                  }
                >
                  {!props.add &&
                  props.item != null &&
                  props.item.comments != null ? (
                    <ForagerTable
                      columns={activityChartColumns}
                      items={currentActivities}
                      subTableColumns={
                        showChangeLog ? activityChartSubtableColumns : null
                      }
                      subTableItemsProperty={
                        showChangeLog ? "changeDetails" : null
                      }
                    />
                  ) : null}
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>
      {fileModal ? (
        <Modal CloseModal={FileModal}>
          <JobFilesModal
            CloseModal={FileModal}
            job={props.item}
            jobFiles={jobFiles}
            type="job"
            GetJobFiles={GetJobFiles}
          />
        </Modal>
      ) : null}
      {addTaskModal ? (
        <Modal
          CloseModal={HideAddTaskModal}
          modificationClass="noPadding changeOnSmall"
        >
          <GanttItemModal
            item={null}
            CloseModal={HideAddTaskModal}
            RefreshJob={props.RefreshJob}
            RefreshItems={props.RefreshItems}
            addGroup={fieldCustomizations.general.task.pluralName}
            type="Task"
            users={props.users}
            clients={props.clients}
            add={true}
            jobs={props.jobs}
            SetEditsPending={ChangeAddTaskModalEditsPending}
            editsPending={addTaskModalEditsPending}
            attachToJob={props.item}
          />
        </Modal>
      ) : null}
      {showNewCommentEntry ? (
        <Modal CloseModal={ToggleNewCommentForm}>
          <ForagerInputModal
            title="Post Comment"
            placeholder="Enter Comment..."
            onCancel={ToggleNewCommentForm}
            onSubmit={SaveNewComment}
            type="textarea"
            saving={commentSaving}
            minHeight={80}
            SetCanClose={SetCanCloseCommentBox}
          />
        </Modal>
      ) : null}
    </div>
  );
}

export default GanttItemInformation;
