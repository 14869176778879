import React from "react";

function GPTLogoSVG(props) {
  return (
    <>
      {props.model === "3.5" ? (
        <svg
          id="IQChatModelLogo"
          className="logoSVG"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 50 50"
        >
          <path
            className="gptLogoMainColor"
            d="M6.45,29.78l3.86-.46c.16,1.09.45,1.85.86,2.27.42.42.97.63,1.66.63.92,0,1.68-.31,2.28-.93s.9-1.41.9-2.37c0-.84-.24-1.51-.73-2-.49-.49-1.14-.74-1.96-.74-.21,0-.42.02-.65.06l.7-3.35c.14.02.28.03.41.03,1.08,0,1.89-.27,2.45-.82.56-.55.84-1.29.84-2.22,0-.66-.19-1.19-.57-1.57-.38-.38-.88-.58-1.49-.58-.58,0-1.1.21-1.56.64-.46.43-.82,1.15-1.09,2.18l-3.68-.73c.47-1.52,1.12-2.68,1.95-3.49,1.26-1.22,2.81-1.82,4.66-1.82s3.32.53,4.31,1.58,1.47,2.31,1.47,3.78c0,1.07-.31,2.03-.94,2.89-.63.86-1.49,1.49-2.58,1.89.87.46,1.51,1.05,1.92,1.78.41.73.62,1.49.62,2.29,0,1.52-.54,2.91-1.61,4.18-1.47,1.74-3.36,2.61-5.68,2.61-1.74,0-3.17-.5-4.3-1.49-1.13-1-1.81-2.41-2.06-4.23ZM22.05,31.26h3.98l-.81,3.89h-3.98l.81-3.89ZM28.78,29.26l3.91-.35c0,.19-.01.33-.01.42,0,.95.21,1.67.64,2.15s.98.72,1.66.72c.95,0,1.82-.46,2.6-1.37s1.17-2.17,1.17-3.78c0-.95-.22-1.67-.66-2.16-.44-.49-1.02-.73-1.75-.73-.52,0-1.03.12-1.52.36-.49.24-.97.61-1.42,1.11l-3.31-.25,3.13-10.51h10.34l-.76,3.68h-6.83l-.94,3.17c.39-.18.79-.31,1.18-.4.39-.09.78-.13,1.16-.13,1.57,0,2.86.53,3.87,1.59,1.01,1.06,1.52,2.47,1.52,4.25,0,1.42-.35,2.82-1.06,4.2s-1.69,2.44-2.94,3.18-2.57,1.11-3.94,1.11c-1.75,0-3.19-.57-4.31-1.7s-1.7-2.65-1.72-4.55ZM47.5,42.5H2.5V7.5h45v35ZM44.69,10.42H5.31v29.17h39.38V10.42Z"
          />
        </svg>
      ) : (
        <svg
          id="IQChatModelLogo"
          className={"logoSVG"}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 50 50"
        >
          <path
            className="gptLogoMainColor"
            d="M15.37,31.22H7.1l.72-3.44,11.36-13.28h3.55l-2.76,13.22h2.55l-.73,3.5h-2.55l-.9,4.28h-3.87l.9-4.28ZM16.1,27.72l1.36-6.55-5.54,6.55h4.18ZM36.09,18.01h6.07l.73-3.51h-16.54l-.73,3.51h6.16l-3.67,17.49h4.33l3.65-17.49ZM47.5,42.5H2.5V7.5h45v35ZM44.69,10.42H5.31v29.17h39.38V10.42Z"
          />
        </svg>
      )}
    </>
  );
}

export default GPTLogoSVG;
