import React, { useState } from "react";
import Modal from "../../../../../../Generic/Modal";
import AddEditTimeModal from "./AddEditTimeModal";
import { useNavigate } from "react-router-dom";
import { useSiteContext } from "../../../../../../Context";

function TimeEntry(props) {
  const [editTime, SetEditTime] = useState(false);

  const { fieldCustomizations } = useSiteContext();

  const navigate = useNavigate();

  function EditTime() {
    SetEditTime(!editTime);
  }

  function ProcessTimeEntryClick() {
    if (props.timeEntry.client != null && props.timeEntry.clientId !== 0) {
      props.AddEditTime(props.timeEntry);
    }
    if (
      props.timeEntry.scheduleItem != null &&
      props.timeEntry.scheduleItemId !== 0
    ) {
      props.ToggleIQOpen(false);
      navigate(
        "/" +
          (props.timeEntry.scheduleItem.scheduleItemTypeId === 1
            ? fieldCustomizations.general.job.pluralName
            : fieldCustomizations.general.task.pluralName) +
          "/" +
          props.timeEntry.scheduleItemId
      );
      props.CloseModal();
    }
  }

  let tooltip =
    props.timeEntry.client == null || props.timeEntry.client.id === 0
      ? props.timeEntry.notes
      : null;

  return props.timeEntry.dayDivider ? (
    <div className="timeEntryContainer dayDivider">
      <div className="timeEntryItem dayDividerDate">
        {new Date(props.timeEntry.date).toLocaleDateString()}
      </div>
      <div className="timeEntryItem dayDividerLabel">Day Total:</div>
      <div className="timeEntryItem dayDividerTotal">
        {props.timeEntry.totalForDay}
      </div>
      <div className="timeEntryItem"></div>
    </div>
  ) : (
    <div className="timeEntryContainer">
      <div
        className="timeEntryItem name"
        onClick={ProcessTimeEntryClick}
        data-tip={tooltip}
        data-multiline={true}
      >
        {props.timeEntry.client != null && props.timeEntry.clientId !== 0
          ? "Ticket: " + props.timeEntry.client?.name
          : props.timeEntry.scheduleItem != null &&
            props.timeEntry.scheduleItemId !== 0 &&
            props.timeEntry.scheduleItem.job != null
          ? props.timeEntry.scheduleItem.job.name
          : props.timeEntry.scheduleItem.name}
      </div>
      <div
        className="timeEntryItem name"
        onClick={ProcessTimeEntryClick}
        data-tip={tooltip}
        data-multiline={true}
      >
        {props.timeEntry.client != null && props.timeEntry.clientId !== 0
          ? props.timeEntry.notes
          : props.timeEntry.scheduleItem != null &&
            props.timeEntry.scheduleItemId !== 0
          ? props.timeEntry.scheduleItem?.name
          : "(None)"}
      </div>
      <div
        className="timeEntryItem time"
        data-tip={props.timeEntry.startTime.substring(5, 10).replace("-", "/")}
        data-multiline={true}
      >
        {/* <div>
          {props.timeEntry.startTime.substring(5, 10).replace("-", "/")}
        </div> */}
        <div>{props.timeEntry.startTime.substring(11, 16)}</div>
      </div>
      <div
        className="timeEntryItem time"
        data-tip={props.timeEntry.startTime.substring(5, 10).replace("-", "/")}
        data-multiline={true}
      >
        {props.timeEntry.endTime != null ? (
          <>
            {/* <div>
              {props.timeEntry.endTime.substring(5, 10).replace("-", "/")}
            </div> */}
            <div>{props.timeEntry.endTime.substring(11, 16)}</div>
          </>
        ) : null}
      </div>
      <div className="timeEntryItem" data-tip={tooltip} data-multiline={true}>
        {props.timeEntry.lengthInHoursRounded15}
      </div>
      <div className="timeEntryItem" data-tip="">
        {props.timeEntry.endTime != null ? (
          <div className="timeEntryEditButton" onClick={EditTime}>
            Edit
          </div>
        ) : null}
      </div>
      {editTime && (
        <Modal
          title="Edit Time"
          CloseModal={EditTime}
          modificationClass="noPaddingAlternate"
        >
          <AddEditTimeModal
            timeEntry={props.timeEntry}
            CloseModal={EditTime}
            mode="Edit"
            jobsForFilter={props.jobsForFilter}
            usersForFilter={props.usersForFilter}
            tasksForFilter={props.tasksForFilter}
            RefreshTimeEntries={props.RefreshTimeEntries}
          />
        </Modal>
      )}
    </div>
  );
}

export default TimeEntry;
