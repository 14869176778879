import React, { useState, useEffect } from "react";
import Day from "./Calendar/Day";
import ChartItem from "./ChartItems/ChartItem";
import Modal from "../../../Generic/Modal";
import GanttItemModal from "./ChartItems/Job/GanttItemModal";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import { useSiteContext } from "../../../Context";
import HQLogoSVG from "../../../Generic/HQLogoSVG";
import ForagerButton from "../../../Generic/ForagerButton";

function GanttChart(props) {
  const [days, setDays] = useState([]);
  const [addModal, SetAddModal] = useState(false);
  const [item, SetItem] = useState(null);
  const [filteredItems, SetFilteredItems] = useState(props.items);
  const [editsPending, SetEditsPending] = useState(false);
  const navigate = useNavigate();

  const {
    loggedInUser,
    GetData,
    clients,
    users,
    RefreshClients,
    RefreshUsers,
    RefreshStatuses,
    fieldCustomizations,
  } = useSiteContext();

  function ChangeEditsPending(value) {
    SetEditsPending(value);
  }

  function OpenModal(force = false) {
    let allowedToClose = true;
    if (!force && editsPending) {
      allowedToClose = window.confirm(
        "Are you sure you want to close without saving?"
      );
    }
    if (allowedToClose) {
      SetAddModal(!addModal);
      SetEditsPending(false);
    }
  }

  function OpenItemModal() {
    if (item != null) {
      let allowedToClose = true;
      if (editsPending) {
        allowedToClose = window.confirm(
          "You have unsaved changes. Are you sure you want to close?"
        );
      }
      if (allowedToClose) {
        SetItem(null);
        SetEditsPending(false);
        navigate("/" + props.path[1]);
      }
    }
  }

  async function GetGanttItemData() {
    let itemId = GetItemId();
    if (itemId != null) {
      let newGanttItemData = await GetData(
        GetApiType(),
        {
          customer: loggedInUser.customer.id,
          id: itemId,
          recursionLevel: 2,
          fullDetails: true,
        },
        "main"
      );
      if (newGanttItemData instanceof Array) {
        SetItem(newGanttItemData[0]);
      }
    }
  }

  function GetCornerButtonText() {
    let displayText = "Add New ";
    switch (props.path[1]) {
      case fieldCustomizations.general.job.pluralName:
        displayText += fieldCustomizations.general.job.name;
        break;
      case fieldCustomizations.general.task.pluralName:
        displayText += fieldCustomizations.general.task.name;
        break;
      default:
        break;
    }
    return displayText;
  }

  function GetApiType() {
    switch (props.path[1]) {
      case fieldCustomizations.general.job.pluralName:
        return "Job";
      case "Equipment":
        return "Equipment";
      case "Teams":
        return "Team";
      case fieldCustomizations.general.task.pluralName:
        return "Task";
      default:
        break;
    }
  }

  function GetItemId() {
    if (props.path[2] != null) {
      return props.path[2];
    }
    return null;
  }

  function CustomSort(a, b, isString) {
    let aProcessed = a;
    let bProcessed = b;
    let inverted = 1;
    let value = 0;
    if (props.filteredSortValue) {
      inverted = -1;
    }
    if (isString) {
      if (aProcessed == null) return 1;
      if (bProcessed == null) return -1;
      let whitelist = new RegExp(/[^a-zA-Z0-9 ]/g);
      aProcessed = a.toLowerCase().replace(whitelist, "");
      bProcessed = b.toLowerCase().replace(whitelist, "");
    }
    if (aProcessed < bProcessed) value = 1;
    if (aProcessed > bProcessed) value = -1;
    return value * inverted;
  }

  function OrderFilteredItems(filteredItems) {
    const sortType = props.filteredSortName;
    filteredItems.sort((a, b) => {
      return CustomSort(a.name, b.name, true);
    });
    switch (sortType) {
      case "Start Date":
        filteredItems.sort((a, b) => {
          return CustomSort(
            Date.parse(a.startDate),
            Date.parse(b.startDate),
            false
          );
        });
        break;
      case "End Date":
        filteredItems.sort((a, b) => {
          return CustomSort(
            Date.parse(a.endDate),
            Date.parse(b.endDate),
            false
          );
        });
        break;
      case "Client":
        filteredItems.sort((a, b) => {
          return CustomSort(a.client.name, b.client.name, true);
        });
        break;
      case "Job":
        filteredItems.sort((a, b) => {
          if (a.job == null || b.job == null) {
            return CustomSort(a.name, b.name, true);
          }
          return CustomSort(a.job.name, b.job.name, true);
        });
        break;
      case "Priority":
        filteredItems.sort((a, b) => {
          if (a.priority == null || b.priority == null) {
            return CustomSort(a.name, b.name, true);
          }
          return CustomSort(a.priority, b.priority, false);
        });
        break;
      case "Status":
        filteredItems.sort((a, b) => {
          if (a.status == null || b.status == null) {
            return CustomSort(a.name, b.name, true);
          }
          return CustomSort(a.status.name, b.status.name, true);
        });
        break;
      default:
        break;
    }
    filteredItems = InsertSortingDividers(filteredItems);
    SetFilteredItems(filteredItems);
  }

  function InsertSortingDividers(items) {
    let sortedItems = [];
    let lastSortValue = "";
    items.forEach((item) => {
      let sortValue = "";
      let dividerLink = null;
      switch (props.filteredSortName) {
        case "Start Date":
          sortValue = new Date(item.startDate).toLocaleDateString("default", {
            month: "short",
            year: "numeric",
          });
          break;
        case "End Date":
          sortValue = new Date(item.endDate).toLocaleDateString("default", {
            month: "short",
            year: "numeric",
          });
          break;
        case "Client":
          sortValue = item.client.name;
          break;
        case "Job":
          if (item.job != null) {
            sortValue = item.job.name;
            dividerLink =
              "/" +
              fieldCustomizations.general.job.pluralName +
              "/" +
              item.job.id;
          }
          break;
        case "Status":
          if (item.status != null) {
            sortValue = item.status.name;
          }
          break;
        case "Priority":
          if (item.priority == null || item.priority === 0) {
            sortValue = "Priority: None";
          } else {
            sortValue = `Priority: ${item.priority}`;
          }
          break;
        default:
          break;
      }
      if (sortValue !== lastSortValue) {
        sortedItems.push({
          divider: true,
          dividerLink: dividerLink,
          name: sortValue == null ? "No " + props.filteredSortName : sortValue,
          sortedBy: props.filteredSortName,
        });
        lastSortValue = sortValue;
      }
      sortedItems.push(item);
    });
    return sortedItems;
  }

  function FilterItems() {
    var newFilteredItems = props.items.slice();
    newFilteredItems = newFilteredItems.filter(
      (item) =>
        item.name.toLowerCase().includes(props.search.toLowerCase()) ||
        item.startDate
          .substring(0, 10)
          .toLowerCase()
          .includes(props.search.toLowerCase()) ||
        item.endDate
          .substring(0, 10)
          .toLowerCase()
          .includes(props.search.toLowerCase())
    );
    if (props.filteredUsers.length > 0) {
      newFilteredItems = newFilteredItems.filter((item) => {
        var found = false;
        item.assignedUsers.forEach((assignedUser) => {
          if (props.filteredUsers.includes(assignedUser.id)) {
            found = true;
          }
        });
        return found;
      });
    }
    if (props.filteredJobs.length > 0) {
      newFilteredItems = newFilteredItems.filter((item) => {
        if (
          item.job != null &&
          props.path[1] !== fieldCustomizations.general.job.pluralName &&
          item.job.id != null
        ) {
          return props.filteredJobs.includes(item.job.id);
        }
        if (props.path[1] === fieldCustomizations.general.job.pluralName) {
          return props.filteredJobs.includes(item.id);
        }
        return false;
      });
    }
    if (props.filteredClients.length > 0) {
      newFilteredItems = newFilteredItems.filter((item) =>
        props.filteredClients.includes(item.client.id)
      );
    }
    let itemsActiveBefore = newFilteredItems.filter(
      (filteredItem) =>
        new Date(filteredItem.endDate) < new Date(props.startDate)
    );
    if (itemsActiveBefore.length > 0) {
      newFilteredItems = newFilteredItems.filter(
        (filteredItem) =>
          new Date(filteredItem.endDate) >= new Date(props.startDate)
      );
      props.ChangeItemsBefore(itemsActiveBefore.length);
    } else {
      props.ChangeItemsBefore(0);
    }
    OrderFilteredItems(newFilteredItems);
  }

  function GenerateDays() {
    if (isNaN(props.timeSpan)) return;
    let days = [];
    let startDate = new Date(props.startDate);
    startDate.setTime(
      startDate.getTime() + new Date().getTimezoneOffset() * 60 * 1000
    );
    for (let i = 0; i < props.timeSpan; i++) {
      let date = new Date(startDate);
      date.setDate(date.getDate() + i);
      days.push({
        day: date.toLocaleDateString("default", {
          month: "numeric",
          day: "numeric",
        }),
        date: date,
        isToday:
          date.toISOString().substring(0, 10) ===
          new Date().toISOString().substring(0, 10)
            ? true
            : false,
      });
    }
    setDays(days);
  }

  function GetSpacers() {
    let spacers = [];
    for (let i = 0; i < 60; i++) {
      spacers.push(
        <ChartItem
          key={i}
          days={days}
          startDate={props.startDate}
          endDate={props.endDate}
          path={props.path}
          search={props.search}
          timeSpan={props.timeSpan}
          item={null}
          even={(i + 1) % 2 === 0}
          rangeNumber={props.rangeNumber}
          spacer={true}
        />
      );
    }
    return spacers;
  }

  useEffect(() => {
    GenerateDays();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.timeSpan]);

  useEffect(() => {
    SetItem(null);
    SetEditsPending(false);
    if (GetItemId() != null && GetApiType() != null) {
      GetGanttItemData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.path]);

  useEffect(() => {
    FilterItems();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.items,
    props.search,
    props.statusFilter,
    props.filteredUsers,
    props.filteredJobs,
    props.filteredClients,
    props.filteredSortName,
    props.filteredSortValue,
  ]);

  useEffect(() => {
    RefreshUsers();
    RefreshClients();
    RefreshStatuses();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="ganttChartContainer">
        <div className="ganttChartMainItemContainer">
          <div className="chartItemContainer header">
            <div className="chartItemLabelContainer odd header">
              <ForagerButton noVerticalPadding={true} onClick={OpenModal}>
                <Icon
                  className="ganttChartAddIcon"
                  icon="fluent:add-12-filled"
                />
                {GetCornerButtonText()}
              </ForagerButton>
            </div>
            <div className="chartItemDaysContainer">
              {days.map((day, index) => (
                <Day
                  key={index}
                  date={day.day}
                  rangeNumber={props.rangeNumber}
                  isHeader={true}
                  isToday={day.isToday}
                  trueDate={day.date}
                />
              ))}
            </div>
          </div>
          {filteredItems.map((item, index) =>
            item.divider ? (
              <div className="chartItemContainer divider" key={index}>
                <div
                  className="chartItemLabelContainer divider clickable"
                  onClick={
                    item.dividerLink != null
                      ? () => navigate(item.dividerLink)
                      : null
                  }
                >
                  {/* <div className="dividerLabel">{item.sortedBy}</div> */}
                  <div className="chartItemLabel divider">{item.name}</div>
                </div>
                <div className="dividerSpacer"> </div>
              </div>
            ) : (
              <ChartItem
                days={days}
                startDate={props.startDate}
                endDate={props.endDate}
                path={props.path}
                type={GetApiType()}
                timeSpan={props.timeSpan}
                item={item}
                even={index % 2 === 0}
                key={index}
                rangeNumber={props.rangeNumber}
                RefreshItems={props.RefreshItems}
              />
            )
          )}
        </div>
        <div className="ganttChartMainItemContainer spacers">
          {GetSpacers().map((item, index) => item)}
        </div>
      </div>
      <div id="ganttLoadingScreen" className="ganttLoadingScreen">
        <div className="loadingScreenIcon spinning" alt="Loading Animation">
          <HQLogoSVG full={false} />
        </div>
        <div className="loadingScreenText">Loading ...</div>
      </div>
      {addModal ? (
        <Modal
          CloseModal={OpenModal}
          modificationClass="noPadding changeOnSmall"
        >
          <GanttItemModal
            item={null}
            CloseModal={OpenModal}
            RefreshJob={GetGanttItemData}
            RefreshItems={props.RefreshItems}
            path={props.path}
            addGroup={props.path[1]}
            type={GetApiType()}
            users={users}
            clients={clients}
            add={true}
            jobs={props.jobsForFilter}
            SetEditsPending={SetEditsPending}
            editsPending={editsPending}
          />
        </Modal>
      ) : null}
      {item != null ? (
        <Modal
          CloseModal={OpenItemModal}
          modificationClass="noPadding changeOnSmall"
        >
          <GanttItemModal
            item={item}
            CloseModal={OpenItemModal}
            RefreshJob={GetGanttItemData}
            RefreshItems={props.RefreshItems}
            path={props.path}
            type={GetApiType()}
            jobs={props.jobsForFilter}
            users={users}
            clients={clients}
            add={false}
            SetEditsPending={ChangeEditsPending}
            editsPending={editsPending}
          />
        </Modal>
      ) : null}
    </>
  );
}

export default GanttChart;
