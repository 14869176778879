import React from "react";

function ForagerButton(props) {
  const buttonClass = CalculateButtonClass();

  function CalculateButtonClass() {
    let newClass = "foragerButton";
    if (props.fullWidth === true) {
      newClass += " fullWidth";
    }
    if (props.smallText === true) {
      newClass += " smallText";
    }
    if (props.tinyText === true) {
      newClass += " tinyText";
    }
    if (props.lessVerticalPadding === true) {
      newClass += " lessVerticalPadding";
    }
    if (props.noVerticalPadding === true) {
      newClass += " noVerticalPadding";
    }
    if (props.lessHorizontalPadding === true) {
      newClass += " lessHorizontalPadding";
    }
    if (props.noHorizontalPadding === true) {
      newClass += " noHorizontalPadding";
    }
    if (props.lessRadius === true) {
      newClass += " lessRadius";
    }
    if (props.reversedColors) {
      newClass += " reversedColors";
    }
    if (props.noGradient) {
      newClass += " noGradient";
    }
    if (props.noBackground) {
      newClass += " noBackground";
    }
    if (props.disabled) {
      newClass += " disabled";
    }
    if (props.exit) {
      newClass += " exit";
    }
    if (props.className) {
      newClass += " " + props.className;
    }
    if (props.hoverColor) {
      newClass += " hoverColor " + props.hoverColor;
    }
    if (props.eliptical) {
      newClass += " eliptical";
    }
    return newClass;
  }

  return (
    <div
      className={buttonClass}
      onClick={!props.disabled ? props.onClick : () => {}}
      data-tip={props.dataTip}
      data-tip-delay-show={props.dataTipDelayShow}
    >
      {props.children}
    </div>
  );
}

export default ForagerButton;
