import React, { useState, useEffect } from "react";
import { useSiteContext } from "../../../../Context";
import ForagerButton from "../../../../Generic/ForagerButton";
import DropDownMenu from "../../../../Generic/DropDownMenu";

function ImpersonateModal(props) {
  const [customers, SetCustomers] = useState([]);
  const [selectedCustomer, SetSelectedCustomer] = useState();
  const [users, SetUsers] = useState([]);
  const [selectedUser, SetSelectedUser] = useState();
  const { GetData, fieldCustomizations } = useSiteContext();

  function ChangeCustomer(value) {
    SetSelectedCustomer(value);
  }

  function ChangeUser(value) {
    SetSelectedUser(value);
  }

  async function GetCustomers() {
    let newCustomers = await GetData("Customer", {});
    if (newCustomers instanceof Array) {
      SetCustomers(newCustomers);
    }
  }

  async function GetUsers() {
    let newUsers = await GetData("User", { customer: selectedCustomer });
    if (newUsers instanceof Array) {
      SetUsers(newUsers);
    }
  }

  function Impersonate() {
    props.ImpersonateUser(selectedUser);
    props.CloseModal();
  }

  useEffect(() => {
    GetCustomers();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedCustomer != null && selectedCustomer !== "") {
      GetUsers();
    } else {
      SetUsers([]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCustomer]);

  return (
    <div className="adminModalContainer">
      <div className="adminModalTitleBar">
        <div className="adminModalTitle">
          {"Impersonate " + fieldCustomizations.general.user.name}
        </div>
      </div>
      <div className="adminModalBodyContainer impersonateSelection">
        <div className="ganttItemInformationLabel">Customer</div>
        <div className="adminModalDropDownContainer">
          <DropDownMenu
            options={customers}
            value={selectedCustomer}
            showBlank={true}
            onChange={ChangeCustomer}
          />
        </div>
        <div className="ganttItemInformationLabel">
          {fieldCustomizations.general.user.name}
        </div>
        <div className="adminModalDropDownContainer">
          <DropDownMenu
            options={users}
            value={selectedUser}
            showBlank={true}
            onChange={ChangeUser}
          />
        </div>
        <div className="impersonateWarning">Notice</div>
        <div className="impersonateWarning">
          This will log you into the selected user's account.
        </div>
        <div className="impersonateWarning">
          You will only be able to assign rights to these users that you have on
          your own account.
        </div>
        <div className="impersonateWarning">
          To stop impersonating, select "End Impersonate" from the menu.
        </div>
      </div>
      <div className="adminModalTitleBar">
        <ForagerButton
          reversedColors={true}
          noVerticalPadding={true}
          onClick={Impersonate}
        >
          Impersonate
        </ForagerButton>
      </div>
    </div>
  );
}

export default ImpersonateModal;
