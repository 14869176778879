import { React, useState, useEffect } from "react";
import Modal from "../../Generic/Modal";
import MultiSelectorModal from "../../Generic/MultiSelectorField";
import { useSiteContext } from "../../Context";
import * as Utilities from "../../Utilities";

function Status(props) {
  const [activeStatuses, SetActiveStatuses] = useState([]);
  const [statusSelector, SetStatusSelector] = useState(false);
  const [tempNewStatusFilter, SetTempNewStatusFilter] = useState([]);
  const { statuses } = useSiteContext();

  function StatusSelector() {
    if (statusSelector) {
      ProcessChanges();
    }
    SetStatusSelector(!statusSelector);
  }

  function ChangeTempNewStatusFilter(newStatusFilter) {
    let reversedFilter = statuses
      .filter((status) => !newStatusFilter.includes(status.id))
      .map((status) => status.id);
    SetTempNewStatusFilter(reversedFilter);
  }

  function FilterActiveStatuses() {
    let filterdStatuses = statuses.filter(
      (status) => !tempNewStatusFilter.includes(status.id)
    );
    filterdStatuses.sort((a, b) =>
      Utilities.CustomSort(a.listOrder, b.listOrder, false, true)
    );
    SetActiveStatuses(filterdStatuses);
  }

  function CheckIfDefaultFilter() {
    let defaultFilter = statuses
      .filter((status) => !status.countedAsActive)
      .map((status) => status.id);
    return Utilities.CheckIfArraysAreEqual(defaultFilter, props.statusFilter);
  }

  function ProcessChanges() {
    if (
      !Utilities.CheckIfArraysAreEqual(props.statusFilter, tempNewStatusFilter)
    ) {
      props.SetStatusFilter(tempNewStatusFilter);
    }
  }

  useEffect(() => {
    if (statuses.length !== 0) {
      SetTempNewStatusFilter(props.statusFilter);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statuses, props.statusFilter]);

  useEffect(() => {
    FilterActiveStatuses();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tempNewStatusFilter]);

  return (
    <>
      <div
        className={
          "filterItemContainer" + (props.expanded ? "" : " hiddenOnSmall")
        }
        onClick={StatusSelector}
      >
        <div className="filterItemTitle">Status</div>
        <div className="filterItemContent">
          {activeStatuses[0] != null
            ? !CheckIfDefaultFilter()
              ? activeStatuses.length !== statuses.length
                ? activeStatuses.map((status, index) => {
                    let returnValue = status.name;
                    if (index !== activeStatuses.length - 1) {
                      returnValue += ", ";
                    }
                    return returnValue;
                  })
                : "All"
              : "All Active"
            : "None"}
        </div>
      </div>
      {statusSelector ? (
        <Modal
          modificationClass="noPaddingAlternate"
          CloseModal={StatusSelector}
        >
          <div className="adminModalTitleBar">
            <div className="modalTitle">Select Statuses</div>
          </div>
          <MultiSelectorModal
            assignableItems={statuses}
            currentlyAssigned={activeStatuses.map((status) => status.id)}
            SetAssignmentFunction={ChangeTempNewStatusFilter}
            title="Select Status"
            updateDb={false}
            // inverted={true}
            CloseModal={StatusSelector}
            orderBy="listOrder"
            alwaysShow={true}
          />
        </Modal>
      ) : null}
    </>
  );
}

export default Status;
