import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import ForagerTable from "./ForagerTable";

function ForagerTableItem(props) {
  const [expanded, SetExpanded] = useState(false);

  function ExpandItem() {
    let newExpanded = !expanded;
    SetExpanded(newExpanded);
    if (props.isHeader) {
      props.ExpandAll(newExpanded);
    }
    if (!newExpanded && !props.isHeader) {
      props.ExpandAll(false, true);
    }
  }

  useEffect(() => {
    let newExpanded = expanded;
    switch (props.forceExpand) {
      case 1:
        newExpanded = true;
        break;
      case -1:
        newExpanded = false;
        break;
      case 2:
        if (props.isHeader) {
          newExpanded = true;
        }
        break;
      case -2:
        if (props.isHeader) {
          newExpanded = false;
        }
        break;
      default:
        break;
    }
    SetExpanded(newExpanded);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.forceExpand]);

  return (
    <div
      className={
        "foragerTableItem" +
        (props.isHeader ? " header" : props.isTotalRow ? " totalRow" : "") +
        (props.highlighted ? " highlighted" : "")
      }
      style={{
        gridTemplateColumns:
          (props.subTableColumns ? "5rem " : "") + props.columnWidths,
      }}
      onClick={
        props.onClickRow != null ? () => props.onClickRow(props.item) : null
      }
    >
      {props.subTableColumns != null ? (
        (props.subTableItems != null && props.subTableItems.length > 0) ||
        props.isHeader ? (
          <div className="foragerTableItemCell">
            <Icon
              className="expandIcon"
              icon={expanded ? "ic:round-expand-more" : "ic:round-expand-less"}
              onClick={ExpandItem}
              data-tip={
                (expanded ? "Collapse" : "Expand") +
                (props.isHeader ? " All" : "")
              }
            />
          </div>
        ) : (
          <div className="foragerTableItemCell"></div>
        )
      ) : null}
      {props.columns.map((column, index) => {
        return (
          <div
            key={index}
            style={{
              textAlign: column.GetAlignment(props.isHeader),
              justifyContent: column.GetAlignment(props.isHeader),
              fontSize: (props.isHeader ? 1 : column.fontSize) + "rem",
            }}
            onClick={() => {
              if (props.isHeader) {
                if (column.HeaderOnClick != null) {
                  column.HeaderOnClick();
                }
                if (column.sortable) {
                  props.ChangeSort(column);
                }
                return;
              }
              if (column.CellOnClick != null) {
                column.CellOnClick(props.item);
              }
              if (column.ValueOnClick != null) {
                column.ValueOnClick(column.GetValue(props.item));
              }
            }}
            className={
              "foragerTableItemCell" +
              (props.isHeader && column.sortable ? " withPointer" : "") +
              (column.hideOnMobile ? " hideOnMobile" : "") +
              (column.cellClass && !props.isHeader
                ? " " + column.cellClass
                : "")
            }
            data-tip={column.GetToolTip(props.item)}
          >
            {props.isHeader ? (
              <>
                <span>{column.header}</span>
                {column.header === props.currentSortColumn?.header ? (
                  <Icon
                    className="sortByArrow"
                    icon={
                      (props.currentSortDescending &&
                        column.sortType !== "string") ||
                      (!props.currentSortDescending &&
                        column.sortType === "string")
                        ? "akar-icons:arrow-down"
                        : "akar-icons:arrow-up"
                    }
                  />
                ) : null}
              </>
            ) : (
              column.GetValue(props.item, false, column.truncateLongTextLength)
            )}
          </div>
        );
      })}
      {props.subTableColumns != null &&
      props.subTableItems != null &&
      props.subTableItems.length > 0 &&
      expanded ? (
        <ForagerTable
          columns={props.subTableColumns}
          items={props.subTableItems}
          isSubTable={true}
          onClickRow={props.onClickSubRow}
        />
      ) : null}
    </div>
  );
}

export default ForagerTableItem;
